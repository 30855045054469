import { default as ObjectID } from 'bson-objectid';
import { Dispatch } from 'redux';
import { IMoveEvent, MoveEvent } from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import * as Action from './actions';
import moment from 'moment';

export function SaveMoveEvent(moveEvent: IMoveEvent, maxTries = 3) {
	const state = StoreContainer.getState();
	const prevEntity = state.moveEvents.entities.find(move => move.stemAnimalId === moveEvent.stemAnimalId);
	let isOverwritingSameDate = false;
	// If date is the same, update instead of create
	if (
		prevEntity &&
		prevEntity.moveDate &&
		moveEvent.moveDate &&
		prevEntity.moveDate.withoutTime().getTime() === moveEvent.moveDate.withoutTime().getTime()
	) {
		isOverwritingSameDate = true;
		moveEvent.moveDate = moment(prevEntity.moveDate).add(1,'m').toDate();
	}

	if (!moveEvent.id) {
		if (!moveEvent.createdOn) {
			moveEvent.createdOn = new Date();
		}
		moveEvent.id = new ObjectID().toHexString();
	}

	return AsyncOperationBuilder2(
		Action.saveMoveEvent,
		client => client.moveEvent_Upsert(MoveEvent.fromJS(moveEvent), isOverwritingSameDate),
		MoveEvent.fromJS(moveEvent),
		prevEntity,
		undefined,
		undefined,
		maxTries,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.moveEvents.lastSyncDate;

	if (state.moveEvents.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.moveEvent_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.moveEvents.updates;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.moveEvents.saveSyncInProgress) {
			return Promise.resolve();
		}
		updates.forEach(update => {
			promises.push(SaveMoveEvent(update)(dispatch));
		});

		return Promise.all(promises);
	};
}

export function GetDeparturedMoveEvents() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;

	return AsyncOperationBuilder2(
		Action.getDeparturedMoveEvents,
		client => client.moveEvent_GetDeparturedMoveEvents(siteId),
		{ siteId },
	);
}

export function RemoveMoveEvent(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeMoveEvent.started(id));
	};
}
