// tslint:disable-next-line: interface-name
export interface ISowCardTableMating {
	litter: number | string | undefined;
	matingDate: string | undefined;
	boar: string | undefined;
	k: string | undefined;
	pregnant: string | undefined;
}

// tslint:disable-next-line: interface-name
export interface ISowCardTableFarrowing {
	pregnancyDays: number | undefined;
	farrowingDate: string | undefined;
	numAlive: number | undefined;
	numDead: number | undefined;
	abortion: boolean | undefined;
	nursingDays: number | undefined;
}

// tslint:disable-next-line: interface-name
export interface ISowCardTableWeaning {
	weaningDate: string | undefined;
	pcs: number | undefined;
	weight: number | undefined;
	wasteDays: number | undefined;
	pigSow: number | undefined;
	isNursingSow: boolean | undefined;
}

// tslint:disable-next-line: interface-name
export interface ISowCardComplete extends ISowCardTableMating, ISowCardTableFarrowing, ISowCardTableWeaning {
	hasFarrowing: boolean | undefined; //Determine Litter has farrowing
	weaningId: string | undefined; //Id used to edit
	farrowingId: string | undefined; //Id used to edit
	matedId: string | undefined; //Id used to edit
	scannedId: string | undefined; //Id used to edit
	isEditable: boolean;
	totalDeadPiglets: number | undefined;
}

export class SowCardComplete implements ISowCardComplete {
	public totalDeadPiglets: number | undefined;
	public scannedId: string | undefined;
	public isEditable: boolean;
	public matedId: string | undefined;
	public farrowingId: string | undefined;
	public weaningId: string | undefined;
	public isNursingSow: boolean | undefined;
	public litter: number | string | undefined;
	public matingDate: string | undefined;
	public boar: string | undefined;
	public k: string | undefined;
	public pregnant: string | undefined;
	public pregnancyDays: number | undefined;
	public farrowingDate: string | undefined;
	public numAlive: number | undefined;
	public numDead: number | undefined;
	public abortion: boolean | undefined;
	public move: number | undefined;
	public nursingDays: number | undefined;
	public weaningDate: string | undefined;
	public pcs: number | undefined;
	public weight: number | undefined;
	public wasteDays: number | undefined;
	public pigSow: number | undefined;
	public hasFarrowing: boolean | undefined;

	//Is necessary to get the objects keys
	constructor() {
		this.pigSow = undefined;
		this.matingDate = undefined;
		this.boar = undefined;
		this.pregnant = undefined;
		this.k = undefined;
		this.pregnancyDays = undefined;
		this.farrowingDate = undefined;
		this.numAlive = undefined;
		this.numDead = undefined;
		this.move = undefined;
		this.nursingDays = undefined;
		this.weaningDate = undefined;
		this.pcs = undefined;
		this.weight = undefined;
		this.wasteDays = undefined;
		this.pigSow = undefined;
		this.pigSow = undefined;
		this.hasFarrowing = true;
		this.isEditable = false;
		this.totalDeadPiglets = undefined;
	}
}

// tslint:disable-next-line: max-classes-per-file
export class SowCardTreatmentModel {
	public litter: number | undefined;
	public treatmentDate: string | undefined;
	public numberOfPiglets: number | undefined;
	public diagnoseName: string | undefined;
	public executedDate: Date | undefined;
}

export type SowCardKeyElement = {
	[key in keyof ISowCardComplete]?: JSX.Element | string | number | boolean | undefined
};
