import {
	FeedingAdjustment,
	FeedingAdjustmentBase,
	FeedingAmountType,
	FeedingUsage,
	IDistriwinFeedConsumptionDto,
	IFeeding,
	IFeedingAdjustment,
	IFeedingCategory,
	IFeedingSubject,
	IFeedingUsage,
	IUserProfile,
} from 'shared/api/api';
import { calculateDays, calculateDaysAbs, ConvertLocationTypeToAnimalType } from 'shared/helpers/general-helpers';
import { FeedingAdjustmentTableModel } from './feeding-table-model';
import { Building, Pen, Section } from 'shared/state/ducks/locations';
import { StoreContainer } from 'shared/state/store-container';

export function GetFeedingCategory(feedingCategories: IFeedingCategory[], categoryId: string) {
	return feedingCategories.find(subject => subject.id === categoryId);
}

export function GetFeedingSubject(feedingSubjects: IFeedingSubject[], feedingSubjectId: string) {
	return feedingSubjects.find(subject => subject.id === feedingSubjectId);
}

export function GetFeedingName(
	item: IFeedingSubject | IFeedingCategory | undefined,
	userProfile: IUserProfile | undefined,
) {
	if (userProfile && item && item.name && userProfile.language) {
		return item.name[userProfile.language] ? item.name[userProfile.language] : item.name['da'];
	} else {
		return '';
	}
}

export class FeedingUsageTableModel extends FeedingUsage {
	public energy1PerKg?: number;
	public energy2PerKg?: number;
	public pricePer100Kg?: number;
	public fosforGPerKg?: number;
}

export function calculateFeedingUsage(
	adjustments: FeedingAdjustmentBase[] | undefined,
	amount: number,
	alreadyUsedAmount: number,
	adjustmentToEdit: IFeedingUsage,
) {
	/// this function returns feeding usage, it takes into account how many kg that used in total, how much of a adjustment we use

	let feedingUsage = {
		...adjustmentToEdit,
		energy1PerKg: 0,
		energy2PerKg: 0,
		pricePer100Kg: 0,
		fosforGPerKg: 0,
	} as FeedingUsageTableModel;

	let totalAdjustmentUsage = 0;
	let kgOfTheTotalAmountToCalculateUsed = 0;
	let kgToCalculateUsageWith = 0;
	adjustments!
		.filter(adjustment => adjustment.type === FeedingAmountType.FeedingAdjustment && !adjustment.isDeleted)
		.forEach(adjustment => {
			if (
				totalAdjustmentUsage < alreadyUsedAmount &&
				totalAdjustmentUsage + adjustment.amount! > alreadyUsedAmount &&
				kgOfTheTotalAmountToCalculateUsed < amount!
			) {
				({ feedingUsage, kgOfTheTotalAmountToCalculateUsed, kgToCalculateUsageWith } =
					calculateUsageFromAdjustment(
						adjustment as FeedingAdjustment,
						feedingUsage,
						totalAdjustmentUsage,
						alreadyUsedAmount,
						kgOfTheTotalAmountToCalculateUsed,
						amount,
						kgOfTheTotalAmountToCalculateUsed,
					));
			} else if (
				totalAdjustmentUsage + adjustment.amount! > alreadyUsedAmount &&
				kgOfTheTotalAmountToCalculateUsed < amount!
			) {
				({ feedingUsage, kgOfTheTotalAmountToCalculateUsed, kgToCalculateUsageWith } =
					calculateUsageWhenAdjustmentAmountIsBiggerThanAlreadyUsed(
						amount,
						adjustment as FeedingAdjustment,
						feedingUsage,
						kgOfTheTotalAmountToCalculateUsed,
						kgToCalculateUsageWith,
					));
			}
			totalAdjustmentUsage = totalAdjustmentUsage + adjustment.amount!;
		});
	feedingUsage = SetFeedingUsageDecimals(feedingUsage);
	return feedingUsage;
}

function calculateUsageWhenAdjustmentAmountIsBiggerThanAlreadyUsed(
	usageInFeedingUsage: number,
	adjustment: IFeedingAdjustment,
	feedingUsage: FeedingUsageTableModel,
	kgOfTheTotalAmountToCalculateUsed: number,
	kgToCalculateUsageWith: number,
) {
	kgToCalculateUsageWith = adjustment.amount!;
	if (usageInFeedingUsage! - kgOfTheTotalAmountToCalculateUsed >= adjustment.amount!) {
		kgOfTheTotalAmountToCalculateUsed = kgOfTheTotalAmountToCalculateUsed + adjustment.amount!;
	} else {
		kgToCalculateUsageWith = usageInFeedingUsage! - kgOfTheTotalAmountToCalculateUsed;
		kgOfTheTotalAmountToCalculateUsed = kgOfTheTotalAmountToCalculateUsed + usageInFeedingUsage!;
	}
	feedingUsage = CalculateAdjustmentDataForKg(feedingUsage, adjustment as FeedingAdjustment, kgToCalculateUsageWith);
	return { feedingUsage, kgOfTheTotalAmountToCalculateUsed, kgToCalculateUsageWith };
}

function calculateUsageFromAdjustment(
	adjustment: IFeedingAdjustment,
	feedingUsage: FeedingUsageTableModel,
	totalAdjustmentUsage: number,
	alreadyUsedAmount: number,
	kgOfTheTotalAmountToCalculateUsed: number,
	totalAmountToCalculate: number,
	kgToCalculateUsageWith: number,
) {
	if (totalAdjustmentUsage + adjustment.amount! > alreadyUsedAmount - kgOfTheTotalAmountToCalculateUsed) {
		kgToCalculateUsageWith =
			totalAdjustmentUsage + adjustment.amount! - (alreadyUsedAmount - kgOfTheTotalAmountToCalculateUsed);
		if (kgToCalculateUsageWith > adjustment.amount!) {
			kgToCalculateUsageWith = adjustment.amount!;
		} else if (kgToCalculateUsageWith > totalAmountToCalculate) {
			kgToCalculateUsageWith = totalAmountToCalculate;
		}
	}
	if (feedingUsage.amount! < kgToCalculateUsageWith && kgToCalculateUsageWith !== totalAmountToCalculate) {
		kgToCalculateUsageWith = feedingUsage.amount!;
	}

	kgOfTheTotalAmountToCalculateUsed = kgOfTheTotalAmountToCalculateUsed + kgToCalculateUsageWith;

	feedingUsage = CalculateAdjustmentDataForKg(feedingUsage, adjustment as FeedingAdjustment, kgToCalculateUsageWith);
	return { feedingUsage, kgOfTheTotalAmountToCalculateUsed, kgToCalculateUsageWith };
}

function SetFeedingUsageDecimals(feedingUsage: FeedingUsageTableModel) {
	feedingUsage.energy1PerKg = feedingUsage.energy1PerKg && parseFloat(feedingUsage.energy1PerKg!.toFixed(2));
	feedingUsage.energy2PerKg = feedingUsage.energy2PerKg && parseFloat(feedingUsage.energy2PerKg!.toFixed(2));
	feedingUsage.fosforGPerKg = feedingUsage.fosforGPerKg && parseFloat(feedingUsage.fosforGPerKg!.toFixed(2));
	feedingUsage.pricePer100Kg = feedingUsage.pricePer100Kg && parseFloat(feedingUsage.pricePer100Kg!!.toFixed(2));
	return feedingUsage;
}

function CalculateAdjustmentDataForKg(
	feedingUsage: FeedingUsageTableModel,
	adjustment: FeedingAdjustment,
	kgsToUse: number,
) {
	feedingUsage.energy1PerKg = feedingUsage.energy1PerKg! + adjustment.energy1PerKg! * kgsToUse;
	feedingUsage.energy2PerKg = feedingUsage.energy2PerKg! + adjustment.energy2PerKg! * kgsToUse;
	feedingUsage.fosforGPerKg = feedingUsage.fosforGPerKg! + adjustment.fosforGPerKg! * kgsToUse;
	feedingUsage.pricePer100Kg = feedingUsage.pricePer100Kg! + adjustment.pricePer100Kg! * kgsToUse;
	return feedingUsage;
}

class PointOfChange {
	public date: Date | undefined;
	public energy1Kg: number | undefined;
	public energy2Kg: number | undefined;
	public pricePer100kg: number | undefined;
	public amount: number | undefined;
}

export function getFeedingUsagesByFeeding(
	feeding: IFeeding,
	feedingSubjects: IFeedingSubject[],
	feedingCategories: IFeedingCategory[],
	userProfile: IUserProfile,
	locations: {
		[key: string]: Building | Section | Pen;
	},
) {
	const feedingSubject = feedingSubjects.find(subject => subject.id === feeding.feedingSubjectId);
	let categoryName = '';
	let subjectName = '';
	if (feedingSubject) {
		subjectName = feedingSubject.name![userProfile.language!];
		const feedingCategory = feedingCategories.find(subject => subject.id === feedingSubject!.feedingCategoryId);
		if (feedingCategory) {
			categoryName = feedingCategory.name![userProfile.language!];
		}
	}

	return feedUsageCalc(feeding, categoryName, subjectName, locations);
}

export function GetPointOfChangeFeeding(feeding: IFeeding) {
	const adjustmentsOnly = feeding.adjustments!.filter(
		a => a.type === FeedingAmountType.FeedingAdjustment && !a.isDeleted,
	) as FeedingAdjustment[];
	const usagesOnly = feeding.adjustments!.filter(
		a => a.type === FeedingAmountType.FeedingUsage && !a.isDeleted,
	) as FeedingUsage[];

	let pointOfChangeArray: PointOfChange[] = [];
	let prevPointOfChange: PointOfChange;
	adjustmentsOnly.forEach(adjust => {
		let pointOfChange = new PointOfChange();

		// Find all usages, which impacted the numbers, from prevPointOfChange to current
		let usagesBeforeAdjust = usagesOnly.filter(
			usage =>
				usage.type === FeedingAmountType.FeedingUsage &&
				usage.periodFrom! < adjust.date! &&
				prevPointOfChange &&
				usage.periodTo! > prevPointOfChange.date!,
		);
		let totalUsageInPeriod = 0;

		// Calculate total usage for a given FeedingUsage in a period
		if (usagesBeforeAdjust.length > 0) {
			usagesBeforeAdjust.forEach(usage => {
				const dateToSubtract = usage.periodTo! < adjust.date! ? usage.periodTo! : adjust.date!;

				const fromDateToSubtract =
					prevPointOfChange && prevPointOfChange.date! > usage.periodFrom!
						? prevPointOfChange.date!
						: usage.periodFrom!;

				const days = calculateDays(fromDateToSubtract, dateToSubtract);
				const periodDays = calculateDays(usage.periodFrom!, usage.periodTo);
				totalUsageInPeriod += (days / periodDays) * usage.amount!;
			});
		}

		// Set correct numbers for point of change
		if (prevPointOfChange && prevPointOfChange.amount! > (totalUsageInPeriod ? totalUsageInPeriod : 0)) {
			const prevAmountLeft = prevPointOfChange.amount! - (totalUsageInPeriod ? totalUsageInPeriod : 0);
			const prevEnergy1KgLeft = prevAmountLeft * prevPointOfChange.energy1Kg!;
			const prevEnergy2KgLeft = prevAmountLeft * prevPointOfChange.energy2Kg!;
			const pricePer100KgLeft = prevAmountLeft * prevPointOfChange.pricePer100kg!;
			pointOfChange.date = adjust.date;
			pointOfChange.amount = prevAmountLeft + adjust.amount!;
			pointOfChange.energy1Kg =
				(prevEnergy1KgLeft + adjust.amount! * adjust.energy1PerKg!) / (prevAmountLeft + adjust.amount!);
			pointOfChange.energy2Kg =
				(prevEnergy2KgLeft + adjust.amount! * adjust.energy2PerKg!) / (prevAmountLeft + adjust.amount!);
			pointOfChange.pricePer100kg =
				(pricePer100KgLeft + adjust.amount! * adjust.pricePer100Kg!) / (prevAmountLeft + adjust.amount!);
		} else {
			pointOfChange.date = adjust.date;
			pointOfChange.energy1Kg = adjust.energy1PerKg;
			pointOfChange.energy2Kg = adjust.energy2PerKg;
			pointOfChange.pricePer100kg = adjust.pricePer100Kg;
			pointOfChange.amount = adjust.amount;
		}
		let indexToReplace = pointOfChangeArray.findIndex(
			a => a.date && adjust.date && a.date.withoutTime().toString() === adjust.date.withoutTime().toString(),
		);
		if (indexToReplace === -1) {
			pointOfChangeArray.push(pointOfChange);
		} else {
			pointOfChangeArray[indexToReplace] = pointOfChange;
		}

		prevPointOfChange = pointOfChange;
	});

	return pointOfChangeArray;
}

export function feedUsageCalc(
	feeding: IFeeding,
	categoryName: string,
	subjectName: string,
	locations: {
		[key: string]: Building | Section | Pen;
	},
) {
	let feedingUsageTableModels: FeedingAdjustmentTableModel[] = [];
	const pointOfChangeArray = GetPointOfChangeFeeding(feeding);
	const usagesOnly = feeding.adjustments!.filter(
		a => a.type === FeedingAmountType.FeedingUsage && !a.isDeleted,
	) as FeedingUsage[];

	// Goes through usages to set data
	usagesOnly.forEach(usage => {
		feedingUsageTableModels.push(
			calculateSingleUsage(
				pointOfChangeArray,
				usage,
				feeding,
				locations,
				categoryName,
				subjectName,
			) as unknown as FeedingAdjustmentTableModel,
		);
	});

	return feedingUsageTableModels;
}

export function calculateSingleUsage(
	pointOfChangeArray: PointOfChange[],
	usageToCalc: IFeedingUsage,
	feeding: IFeeding,
	locations: {
		[key: string]: Building | Section | Pen;
	},
	categoryName?: string,
	subjectName?: string,
) {
	const recipies = StoreContainer.getState().recipe.recipes;
	let dataToCombine: FeedingAdjustmentTableModel[] = [];

	// We don't need every Point of change, only the first right before and those in between
	let relevantPointOfChanges: PointOfChange[] = [];
	let first = pointOfChangeArray
		.slice()
		.reverse()
		.find(p => p.date!.withoutTime()! <= usageToCalc.periodFrom!.withoutTime());
	if (first) {
		relevantPointOfChanges.push(first);
	}
	let inBetweenPointOfChange = pointOfChangeArray.filter(
		poo =>
			poo.date!.withoutTime() > usageToCalc.periodFrom!.withoutTime() &&
			poo.date!.withoutTime() < usageToCalc.periodTo!.withoutTime(),
	);
	relevantPointOfChanges = relevantPointOfChanges.concat(inBetweenPointOfChange);
	let periodDays = Math.abs(
		calculateDays(usageToCalc.periodFrom!.withoutTime(), usageToCalc.periodTo!.withoutTime()),
	);
	periodDays = periodDays ? periodDays : 1;

	for (let i = 0; i < relevantPointOfChanges.length; i++) {
		let totalAmount = 0;
		let totalEnergy1 = 0;
		let totalEnergy2 = 0;
		let totalPricePer100Kg = 0;
		let days = 0;
		// Calculate days needed for the relevant point of changes
		if (i !== relevantPointOfChanges.length - 1) {
			if (i !== 0) {
				days = Math.abs(
					calculateDaysAbs(
						relevantPointOfChanges[i + 1].date!.withoutTime(),
						relevantPointOfChanges[i].date!.withoutTime(),
					),
				);
			} else {
				const nextRe = relevantPointOfChanges[i + 1];
				const dateToUse = nextRe && nextRe.date! < usageToCalc.periodTo! ? nextRe.date! : usageToCalc.periodTo!;
				days = Math.abs(calculateDaysAbs(dateToUse!.withoutTime(), usageToCalc.periodFrom!.withoutTime()));
			}
		} else {
			const dateToUse =
				usageToCalc.periodFrom! > relevantPointOfChanges[i].date!
					? usageToCalc.periodFrom
					: relevantPointOfChanges[i].date;
			days = Math.abs(calculateDays(dateToUse!.withoutTime(), usageToCalc.periodTo!.withoutTime()));
		}
		days = days ? days : 1;
		totalAmount += (days / periodDays) * usageToCalc.amount!;
		totalEnergy1 += (days / periodDays) * usageToCalc.amount! * relevantPointOfChanges[i].energy1Kg!;
		totalEnergy2 += (days / periodDays) * usageToCalc.amount! * relevantPointOfChanges[i].energy2Kg!;
		totalPricePer100Kg += (days / periodDays) * usageToCalc.amount! * relevantPointOfChanges[i].pricePer100kg!;

		dataToCombine.push({
			energy1PerKg: totalEnergy1,
			energy2PerKg: totalEnergy2,
			pricePer100Kg: totalPricePer100Kg,
			amount: totalAmount,
			periodFrom: usageToCalc.periodFrom,
			periodTo: usageToCalc.periodTo,
			buildingId: usageToCalc.buildingId,
			sectionId: usageToCalc.sectionId,
			buildningName:
				usageToCalc.buildingId && locations[usageToCalc.buildingId] && locations[usageToCalc.buildingId].name,
			buildingOrder:
				usageToCalc.buildingId && locations[usageToCalc.buildingId]
					? locations[usageToCalc.buildingId].order
					: undefined,
			sectionName:
				usageToCalc.sectionId && locations[usageToCalc.sectionId] && locations[usageToCalc.sectionId].name,
			sectionOrder: usageToCalc.sectionId
				? locations[usageToCalc.sectionId] && locations[usageToCalc.sectionId].order
				: undefined,
		} as FeedingAdjustmentTableModel);
	}

	let combinedTotalAmount = 0;
	let combinedTotalEnergy1 = 0;
	let combinedTotalEnergy2 = 0;
	let combinedTotalPricePer100Kg = 0;

	dataToCombine.forEach(data => {
		combinedTotalAmount += data.amount!;
		combinedTotalEnergy1 += data.energy1PerKg!;
		combinedTotalEnergy2 += data.energy2PerKg!;
		combinedTotalPricePer100Kg += data.pricePer100Kg!;
	});

	return SetFeedingUsageDecimals({
		...usageToCalc,
		energy1PerKg: combinedTotalAmount ? combinedTotalEnergy1 / combinedTotalAmount : 0,
		energy2PerKg: combinedTotalAmount ? combinedTotalEnergy2 / combinedTotalAmount : 0,
		pricePer100Kg: combinedTotalAmount ? combinedTotalPricePer100Kg / combinedTotalAmount : 0,
		amount: combinedTotalAmount,
		periodFrom: usageToCalc.periodFrom,
		periodTo: usageToCalc.periodTo,

		FeedingSubjectId: feeding.feedingSubjectId,
		AdjustmentId: usageToCalc.id,
		feedingId: feeding.id,
		FeedingCategory: categoryName,
		FeedingSubject: subjectName,
		recipe: recipies.find(r => r.id === usageToCalc.recipeId)?.name,
	} as any);
}

export const sortFoodCategories = (categories: IFeedingCategory[]): IFeedingCategory[] => {
	return [...categories].sort((a, b) => {
		return foodCategorySortOrder.findIndex(e => e === a.id) - foodCategorySortOrder.findIndex(e => e === b.id);
	});
};

export const generateFeedingAdjustmentTableModelByConsumptions = (
	consumptionDtos: IDistriwinFeedConsumptionDto[],
	language: string | undefined,
	locations: {
		[key: string]: Building | Section | Pen;
	},
) => {
	let feedingAdjustments: FeedingAdjustmentTableModel[] = [];
	if (!consumptionDtos || consumptionDtos.length === 0) {
		return feedingAdjustments;
	}
	let grouped = consumptionDtos.groupByMultipleProps(item => [item.periodFrom, item.sectionId, item.feedingId]);
	grouped.forEach(group => {
		let firstItem = group[0];
		let tableItem = {
			periodFrom: firstItem.periodFrom,
			periodTo: firstItem.periodTo,
			sectionId: firstItem.sectionId,
			animalType: ConvertLocationTypeToAnimalType(firstItem.locationType),
			productionType: firstItem.animalType,
			buildingId: firstItem.buildingId,
			FeedingSubject: firstItem.feedingSubjectName && firstItem.feedingSubjectName[language ? language : 'en'],
			FeedingCategory: firstItem.feedingCategoryName && firstItem.feedingCategoryName[language ? language : 'en'],
			amount: 0,
			energy1PerKg: 0,
			energy2PerKg: 0,
			isDistriwinData: true,
			buildningName:
				firstItem.buildingId && locations[firstItem.buildingId] && locations[firstItem.buildingId].name,
			buildingOrder:
				firstItem.buildingId && locations[firstItem.buildingId]
					? locations[firstItem.buildingId].order
					: undefined,
			sectionName: firstItem.sectionId && locations[firstItem.sectionId] && locations[firstItem.sectionId].name,
			sectionOrder: firstItem.sectionId
				? locations[firstItem.sectionId] && locations[firstItem.sectionId].order
				: undefined,
		} as FeedingAdjustmentTableModel;

		group.forEach(consumption => {
			if (tableItem.amount !== undefined && consumption.quantity) {
				tableItem.amount += consumption.quantity;
			}
			if (tableItem.energy1PerKg !== undefined && consumption.totalEnergy1) {
				tableItem.energy1PerKg += consumption.totalEnergy1;
			}

			if (tableItem.energy2PerKg !== undefined && consumption.totalEnergy2) {
				tableItem.energy2PerKg += consumption.totalEnergy2;
			}
		});
		tableItem.energy1PerKg =
			tableItem.energy1PerKg && tableItem.amount ? tableItem.energy1PerKg / tableItem.amount : 0;
		tableItem.energy2PerKg =
			tableItem.energy2PerKg && tableItem.amount ? tableItem.energy2PerKg / tableItem.amount : 0;
		feedingAdjustments.push(tableItem);
	});
	return feedingAdjustments;
};

export const generatePenFeedingConsumptions = (
	consumptionDtos: IDistriwinFeedConsumptionDto[],
	language: string | undefined,
) => {
	return consumptionDtos.map(consumption => {
		return {
			periodFrom: consumption.periodFrom,
			periodTo: consumption.periodTo,
			animalType: ConvertLocationTypeToAnimalType(consumption.locationType),
			productionType: consumption.animalType,
			FeedingSubject:
				consumption.feedingSubjectName && consumption.feedingSubjectName[language ? language : 'en'],
			FeedingCategory:
				consumption.feedingCategoryName && consumption.feedingCategoryName[language ? language : 'en'],
			amount: consumption.quantity,
			energy1PerKg:
				consumption.totalEnergy1 && consumption.quantity ? consumption.totalEnergy1 / consumption.quantity : 0,
			energy2PerKg:
				consumption.totalEnergy2 && consumption.quantity ? consumption.totalEnergy2 / consumption.quantity : 0,
			isDistriwinData: true,
			penId: consumption.penId,
			recipe: consumption.recipeName,
			penName: consumption.penName,
		} as FeedingAdjustmentTableModel;
	});
};

const foodCategorySortOrder = [
	'5d43fd84c1da444e29978b80',
	'5d43fd9ec1da444e29978b81',
	'5d43fda3c1da444e29978b82',
	'5d43fda8c1da444e29978b83',
	'5d43fdadc1da444e29978b84',
	'5d43fdb2c1da444e29978b85',
	'5d43fdb7c1da444e29978b86',
	'5d43fdbbc1da444e29978b87',
	'5d43fdc0c1da444e29978b88',
	'5d43f34ec1da444e29978b73',
	'5d43f3ccc1da444e29978b7f',
	'5d43f3c7c1da444e29978b7e',
	'5d43f3c2c1da444e29978b7d',
	'5d43f3bdc1da444e29978b7c',
	'5d43f3b7c1da444e29978b7b',
	'5d43f3b2c1da444e29978b7a',
	'5d43f3adc1da444e29978b79',
	'5d43f3a9c1da444e29978b77',
	'5d43f3a4c1da444e29978b76',
	'5d528754186cf325b03f7f1b',
	'5e70d37ef987e5015b4cca8a',
];
