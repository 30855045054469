import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Equipment, PregnancyState } from 'shared/api/api';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetDrugs, Sync } from 'shared/state/ducks/drug/operations';
import { DrugUpdate } from 'shared/state/ducks/drug/types';
import * as pregnancyOperation from 'shared/state/ducks/pregnancy-events/operations';
import { GetDeparturedPregnancies } from 'shared/state/ducks/pregnancy-events/operations';
import * as stemAnimalOperation from 'shared/state/ducks/stem-animals/operations';
import { GetDeparturedAnimals } from 'shared/state/ducks/stem-animals/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import FarrowingEventListTable from 'web/view/components/event-lists/pregnancy-event-lists/farrowings-event-list-table/farrowings-event-list-table';
import MatingEventListTable from 'web/view/components/event-lists/pregnancy-event-lists/matings-event-list-table/matings-event-list-table';
import ScanningEventListTable from 'web/view/components/event-lists/pregnancy-event-lists/scannings-event-list-table/scannings-event-list-table';
import WeaningEventListTable from 'web/view/components/event-lists/pregnancy-event-lists/weaning-event-list-table/weaning-event-list-table';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { Heading } from 'web/view/components/utils/heading';
import './sow-event-lists.scss';
import MoveEventListTable from 'web/view/components/event-lists/move-event-list/move-event-list-table';
import DeadPigletsEventTable from 'web/view/components/event-lists/dead-piglets-event-list/dead-piglets-event-table';
import ResendEsfListTable from 'web/view/components/event-lists/resend-esf-list/resend-esf-list-table';
import PoolYoungFemaleEventList from 'web/view/components/event-lists/pool-young-female-list/pool-young-female-event-list';
import { setCorrectTimeStamp } from 'shared/helpers/date-helpers';
import moment from 'moment';
import { GetSyncData as ProfileGetSyncData } from 'shared/state/ducks/profile/operations';
import { ResendEsfCloudToMessagesOverview } from 'web/view/components/event-lists/resend-esf-list/resend-esf-cloud-to-messages-overview';
interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		drugTypes: state.drugType.entities,
		drugs: state.drugs.drugs.filter(d => !d.isDeleted),
		hasEsf: state.processEquipments.entities.find(e => e.equipment === Equipment.ESF),
		fetchC2DPending: state.esfCloudToDeviceMessages.pending,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDrugs: (siteId: string, startDate: Date, endDate: Date) => GetDrugs(siteId, startDate, endDate)(dispatch),
	Sync: (drugs: DrugUpdate[]) => Sync(drugs)(dispatch),
	getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
	getDeparturedPregnancies: () => GetDeparturedPregnancies()(dispatch),
	pregnancyEventGetSyncData: () => pregnancyOperation.GetSyncData()(dispatch),
	stemAnimalGetSyncData: () => stemAnimalOperation.GetSyncData()(dispatch),
	getProfiles: () => ProfileGetSyncData()(dispatch),
});

interface State {
	fromDate: Date;
	toDate: Date;
	selectedOption?: Option;
	rightHeaderItem: JSX.Element;
	pregnancyStateOptions: Option[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class SowEventLists extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		let tempFromDate = new Date();
		tempFromDate.setDate(tempFromDate.getDate() - 7);
		let tempToDate = new Date();
		let startDate = setCorrectTimeStamp(moment(tempFromDate).toDate(), false, false);
		let endDate = setCorrectTimeStamp(moment(tempToDate).toDate(), false, true);
		let pregnancyStateOptions = [
			{ label: localized('mating'), value: PregnancyState.Mated },
			{ label: localized('scanning'), value: PregnancyState.Scanned },
			{ label: localized('farrowing'), value: PregnancyState.Farrowing },
			{ label: localized('Weaning'), value: PregnancyState.Averted },
			{ label: localized('moveEvents'), value: 'moveList' },

			{ label: localized('deadPigletsEvent'), value: 'deadPigletsEventList' },
			{ label: localized('salePoolYoungFemale'), value: 'salePoolYoungFemale' },
		];
		if (this.props.hasEsf) {
			pregnancyStateOptions.push({ label: localized('resendEsfList'), value: 'resendEsfList' });
		}
		this.state = {
			fromDate: startDate ? startDate : tempFromDate,
			toDate: endDate ? endDate : tempToDate,
			selectedOption: {
				label: ' ',
				value: ' ',
			},
			rightHeaderItem: <ViewWeb />,
			pregnancyStateOptions,
		};
		this.props.getProfiles();
		this.props.getDeparturedAnimals();
		this.props.getDeparturedPregnancies();
		this.props.stemAnimalGetSyncData();
		this.props.pregnancyEventGetSyncData();
	}

	public render() {
		return (
			<PageContainer className="sow-pregnancy-event-lists">
				<Heading text={localized('pregnancyEventLists')} />
				<ViewWeb className="outer-div-container">
					<ViewWeb className="header-container">
						<ViewWeb className="dateView-container-styles">
							<SkioldFetch
								fromDate={this.state.fromDate}
								fetchData={this.applyDates}
								showSpinner={this.props.fetchC2DPending}
							/>
						</ViewWeb>

						<ViewWeb className="list-dropdown-container">
							<SkioldDropdown
								onValueChanged={this.selectedOptionChanged}
								selectedValue={this.state.selectedOption}
								items={this.state.pregnancyStateOptions}
								containerClassName="sow-list-dropdown"
							/>
						</ViewWeb>
						<ViewWeb className="right-header-container">{this.state.rightHeaderItem}</ViewWeb>
					</ViewWeb>

					<ViewWeb className="table-container sow-pregnancy-event-list">
						{this.state.selectedOption && this.state.selectedOption.value === PregnancyState.Mated && (
							<MatingEventListTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
						{this.state.selectedOption && this.state.selectedOption.value === PregnancyState.Scanned && (
							<ScanningEventListTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
						{this.state.selectedOption && this.state.selectedOption.value === 'moveList' && (
							<MoveEventListTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
						{this.state.selectedOption &&
							this.props.hasEsf &&
							this.state.selectedOption.value === 'resendEsfList' && (
								<ResendEsfCloudToMessagesOverview
									dateFrom={this.state.fromDate}
									dateTo={this.state.toDate}
									onSummaryChanged={this.summaryChanged}
								/>
							)}
						{this.state.selectedOption && this.state.selectedOption.value === 'deadPigletsEventList' && (
							<DeadPigletsEventTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}

						{this.state.selectedOption && this.state.selectedOption.value === PregnancyState.Farrowing && (
							<FarrowingEventListTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
						{this.state.selectedOption && this.state.selectedOption.value === PregnancyState.Averted && (
							<WeaningEventListTable
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
						{this.state.selectedOption && this.state.selectedOption.value === 'salePoolYoungFemale' && (
							<PoolYoungFemaleEventList
								dateFrom={this.state.fromDate}
								dateTo={this.state.toDate}
								onSummaryChanged={this.summaryChanged}
							/>
						)}
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	private applyDates = (fromDate: Date, toDate: Date) => {
		this.setState({ toDate: toDate, fromDate: fromDate });
	};
	private summaryChanged = (element: JSX.Element) => {
		this.setState({ rightHeaderItem: element });
	};

	private selectedOptionChanged = (option?: Option) => {
		this.setState({
			selectedOption: option,
		});
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SowEventLists);
