import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRecipeDto } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import { deleteRecipe } from 'shared/state/ducks/recipe/operation';
import { selectIsSkioldDigitalRecipes } from 'shared/state/ducks/recipe/selectors';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { ViewWeb } from '../../utils/web-view';
import { CreateRecipe } from './create-recipe';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
interface PropsFromParent {}

export const RecipeTable: FC<PropsFromParent> = React.memo(({}) => {
	// Init
	const dispatch = useDispatch();
	const [createRecipeModalOpen, setCreateRecipeModalOpen] = React.useState<boolean>(false);
	const [editRecipe, setEditRecipe] = React.useState<IRecipeDto | undefined>();
	const recipes = useSelector(selectIsSkioldDigitalRecipes);
	const columnExtensions = useMemo(
		() => [
			{
				columnName: 'edit',
				width: 50,
			},
			{
				columnName: 'name',
				width: 150,
			},
			{
				columnName: 'production',
				width: 150,
			},

			{
				columnName: 'delete',
				width: 50,
			},
		],
		[],
	);

	const closeCreateRecipeModal = () => {
		setEditRecipe(undefined);
		setCreateRecipeModalOpen(false);
	};

	const showAnimalType = (recipe: IRecipeDto) => {
		return recipe.animalType ? localized(recipe.animalType) : '';
	};

	const getEdit = useCallback((recipe: IRecipeDto) => {
		// open modal with recipe
		const openModal = () => {
			setEditRecipe(recipe);
			setCreateRecipeModalOpen(true);
		};

		return (
			<ViewWeb className="buttons-gap">
				<SkioldTouchableOpacity onPress={openModal}>
					<SkioldImage width="25" height="25" imageData={PenIcon} />
				</SkioldTouchableOpacity>
			</ViewWeb>
		);
	}, []);

	const getDelete = useCallback((item: IRecipeDto) => {
		const deleteRecipeFunc = () => {
			dispatch(deleteRecipe(item));
		};

		return (
			<ViewWeb className="buttons-gap">
				<SkioldTouchableOpacity onPress={deleteRecipeFunc}>
					<SkioldImage width="25" height="25" imageData={DeleteIcon} />
				</SkioldTouchableOpacity>
			</ViewWeb>
		);
	}, []);

	const columns = useMemo(
		() => [
			{
				name: 'edit',
				title: ' ',
				getCellValue: getEdit,
			},
			{
				name: 'name',
				title: localized('RecipeName'),
			},
			{
				name: 'production',
				title: localized('production'),
				getCellValue: showAnimalType,
			},

			{
				name: 'delete',
				title: ' ',
				getCellValue: getDelete,
			},
		],
		[],
	);
	return (
		<>
			<SkioldTableGrid
				tableKey={'skioldRecipeTable'}
				columns={columns}
				ColumnExtensions={columnExtensions}
				data={recipes}
				ignoreSetCount={true}
				className="recipe-table-container"
			/>
			<SkioldModal overflowNone={true} padding="0" isOpen={createRecipeModalOpen} close={closeCreateRecipeModal}>
				<CreateRecipe recipe={editRecipe} closeModal={closeCreateRecipeModal} />
			</SkioldModal>
		</>
	);
});
