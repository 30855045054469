import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimalType, Equipment, IBuilding, IGrowthPigsDashboardDto, IPen, ISection } from 'shared/api/api';
import { setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import { getLocationStringBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { useGpeDashboard } from 'web/view/pages/dashboard-overview-growth-pigs/dashboard-overview-growth-pigs';
import { SkioldTableGroupGrid } from '../skiold-components/skiold-table/skiold-table-grouped-grid/Skiold-table-grouped-grid';
import { SowListConstants } from '../stem-animal/animal-lists/table-constants';
import { SkioldImage } from '../utils/svg/skiold-image';
import Feedlog from 'shared/assets/src-assets/png/FoderLog_DarkGrey.png';
import { SharedAppState } from 'shared/state/store.shared';
interface PropsFromParent {
	productionType: AnimalType;
}

export const DashboardOverviewGrowthPigsTable: FC<PropsFromParent> = React.memo((props: PropsFromParent) => {
	const hasDistriwin = useSelector((state: SharedAppState) =>
		state.processEquipments.entities.find(e => e.equipment === Equipment.Distriwin),
	);
	const dataToUse = useSelector((state: WebAppState) => state.dashboardGrowthPigsOverview.data);
	const dashboardSetting = useSelector((state: WebAppState) =>
		state.dashboardSettings.entity.gpeDashboardSettingItems?.find(i => i.animalType === props.productionType),
	);
	const locationMemoized = useSelector((state: WebAppState) =>
		memoizeHashmapLocation(state.locations.buildings, state.locations.sections, state.locations.pens),
	);
	const [tableData, setTableData] = useState<DashboardOverviewGrowthPigsTableData[]>([]);
	const { setPenId, setSectionId, openDeadModal, openTreatedModal, openEntranceDepartureModal, openGraphModal } =
		useGpeDashboard();

	const getEntryWeightCell = (item: DashboardOverviewGrowthPigsTableData) =>
		item.entryWeight ? item.entryWeight : undefined;

	const getfeedCurveWeightCell = (item: DashboardOverviewGrowthPigsTableData) =>
		item.feedCurveWeight ? item.feedCurveWeight : undefined;

	const getDailyGainCell = (item: DashboardOverviewGrowthPigsTableData) =>
		item.dailyGain ? item.dailyGain : undefined;

	const getDaysCell = (item: DashboardOverviewGrowthPigsTableData) => (item.days ? item.days : undefined);

	// Opens details on selected Pen
	const getDeadLastSevenDaysCell = (item: DashboardOverviewGrowthPigsTableData) => {
		const handlePenRowClick = () => {
			setPenId(item.penId);
			setSectionId(item.sectionId);
			openDeadModal();
		};

		return item.deadLastSevenDays ? (
			<div className="dashboard-table-clickable" onClick={handlePenRowClick}>
				<ViewWeb className="folder-arrow arrow-folded" />
				{item.deadLastSevenDays ? item.deadLastSevenDays : undefined}
			</div>
		) : (
			<ViewWeb />
		);
	};

	// Opens details on selected Pen
	const getFeedPerPigCurveCell = (item: DashboardOverviewGrowthPigsTableData) =>
		item.feedPerPigCurve ? item.feedPerPigCurve : undefined;

	// Opens details on selected Pen
	const getOpenGraphCell = (item: DashboardOverviewGrowthPigsTableData) => {
		const handlePenRowClick = () => {
			setPenId(item.penId);
			setSectionId(item.sectionId);
			openGraphModal();
		};
		return item.hasProcessEquipmentData ? (
			<div className="dashboard-table-clickable" onClick={handlePenRowClick}>
				<SkioldImage
					width={SowListConstants.iconSVGWidth}
					height={SowListConstants.iconSVGWidth}
					imageData={Feedlog}
				/>
			</div>
		) : null;
	};

	const getFeedPrPigToday = (item: DashboardOverviewGrowthPigsTableData) => item.feedPerPigToday;

	const getFeedPrPigTotal = (item: DashboardOverviewGrowthPigsTableData) => item.feedPerPigTotal;

	const getFeedPrPigWeight = (item: DashboardOverviewGrowthPigsTableData) => item.feedPerPigWeight;

	// Opens details on selected Pen
	const getPigCountCell = (item: DashboardOverviewGrowthPigsTableData) => {
		const handlePenRowClick = () => {
			setPenId(item.penId);
			setSectionId(item.sectionId);
			openEntranceDepartureModal();
		};

		return item.numOfPig ? (
			<div className="dashboard-table-clickable" onClick={handlePenRowClick}>
				<ViewWeb className="folder-arrow arrow-folded" />
				{item.numOfPig ? item.numOfPig : undefined}
			</div>
		) : (
			<ViewWeb />
		);
	};

	// Opens details on selected Pen
	const getTreatedLastSevenDaysCell = (item: DashboardOverviewGrowthPigsTableData) => {
		const handlePenRowClick = () => {
			setPenId(item.penId);
			setSectionId(item.sectionId);
			openTreatedModal();
		};

		return item.treatedLastSevenDays ? (
			<div className="dashboard-table-clickable" onClick={handlePenRowClick}>
				<ViewWeb className="folder-arrow arrow-folded" />
				{item.treatedLastSevenDays ? item.treatedLastSevenDays : undefined}
			</div>
		) : (
			<ViewWeb />
		);
	};

	// Open details when summary Cell clicked - Shows Section data
	const openEntranceDepartureBySection = useCallback(
		(rowKey: string, data: DashboardOverviewGrowthPigsTableData[]) => {
			let section = data.find(e => e.buildSection === rowKey);
			if (section) {
				setPenId(undefined);
				setSectionId(section.sectionId);
				openEntranceDepartureModal();
			}
		},
		[openEntranceDepartureModal, setPenId, setSectionId],
	);

	// Open details when summary Cell clicked - Shows Section data
	const openTreatedLastSevenDaysBySection = useCallback(
		(rowKey: string, data: DashboardOverviewGrowthPigsTableData[]) => {
			let section = data.find(e => e.buildSection === rowKey);
			if (section) {
				setPenId(undefined);
				setSectionId(section.sectionId);
				openTreatedModal();
			}
		},
		[openTreatedModal, setPenId, setSectionId],
	);

	// Open details when summary Cell clicked - Shows Section data
	const openDeadLastSevenDaysBySection = useCallback(
		(rowKey: string, data: DashboardOverviewGrowthPigsTableData[]) => {
			let section = data.find(e => e.buildSection === rowKey);
			if (section) {
				setPenId(undefined);
				setSectionId(section.sectionId);
				openDeadModal();
			}
		},
		[openDeadModal, setPenId, setSectionId],
	);

	const openFeedCurveGraph = useCallback(
		(rowKey: string, data: DashboardOverviewGrowthPigsTableData[]) => {
			let section = data.find(e => e.buildSection === rowKey);
			if (section) {
				setPenId(undefined);
				setSectionId(section.sectionId);
				openGraphModal();
			}
		},
		[setPenId, setSectionId, openGraphModal],
	);

	useEffect(() => {
		if (dataToUse) {
			const data: DashboardOverviewGrowthPigsTableData[] = [];
			dataToUse.forEach(item => {
				const itemToAdd = { ...item } as DashboardOverviewGrowthPigsTableData;
				if (itemToAdd.penId) {
					const penToUse = locationMemoized[itemToAdd.penId] as IPen;
					if (penToUse && penToUse.name && penToUse.sectionId && penToUse.buildingId) {
						itemToAdd.pen = penToUse.name;
						const sectionToUse = locationMemoized[penToUse.sectionId] as ISection;
						const buildingToUse = locationMemoized[penToUse.buildingId] as IBuilding;
						itemToAdd.orderPen = penToUse.order ? penToUse.order : 0;
						if (sectionToUse) {
							itemToAdd.orderSection = sectionToUse.order ? sectionToUse.order : 0;
						}

						if (buildingToUse) {
							itemToAdd.orderBuilding = buildingToUse.order ? buildingToUse.order : 0;
						}
					}
				}
				if (itemToAdd.sectionId) {
					const buildSection = getLocationStringBySectionId(itemToAdd.sectionId, locationMemoized);
					itemToAdd.buildSection = buildSection ? buildSection : ' ';
				}
				itemToAdd.entryWeight = (
					itemToAdd.entryWeight ? setOneDecimalsAsNumber(itemToAdd.entryWeight) : undefined
				)!;
				itemToAdd.feedCurveWeight = (
					itemToAdd.feedCurveWeight ? setOneDecimalsAsNumber(itemToAdd.feedCurveWeight) : undefined
				)!;
				itemToAdd.dateString = itemToAdd.expectedDeliveryDate
					? moment(itemToAdd.expectedDeliveryDate).week().toString()
					: '';

				data.push(itemToAdd);
			});
			setTableData(
				data.sort((a, b) => {
					if (a.orderBuilding > b.orderBuilding) {
						return 1;
					} else if (a.orderBuilding < b.orderBuilding) {
						return -1;
					}
					if (a.orderSection > b.orderSection) {
						return 1;
					} else if (a.orderSection < b.orderSection) {
						return -1;
					}
					if (a.orderPen > b.orderPen) {
						return 1;
					} else if (a.orderPen < b.orderPen) {
						return -1;
					}
					return 0;
				}),
			);
		}
	}, [dataToUse, locationMemoized, props.productionType]);

	const groupedHeaders = () => [
		{ key: localized('Weight'), groupColumnNames: ['entryWeight', 'feedCurveWeight'] },
		{ key: localized('feedPerPig'), groupColumnNames: ['feedPerPigCurve', 'feedPerPigToday'] },
		{
			key: localizedInterpolation('totalDayX', dashboardSetting?.subGoal ?? 28),
			groupColumnNames: ['feedPerPigTotal', 'feedPerPigWeight'],
		},
		{ key: localized('lastSevenDays'), groupColumnNames: ['deadLastSevenDays', 'treatedLastSevenDays'] },
	];

	const entryWeightSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		let totalPigsReal = 0;

		items.forEach(item => {
			totalPigsReal += item.numOfPig ? item.numOfPig : 0;
			if (item.numOfPig !== undefined && item.numOfPig > 0 && item.entryWeight !== undefined) {
				totalWeight += item.entryWeight * item.numOfPig;
				totalPigs += item.numOfPig;
			}
		});
		return totalPigsReal > 0 && totalWeight && totalPigs ? totalWeight / totalPigs : undefined;
	};

	const feedCurveWeightSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		let totalPigsReal = 0;
		items.forEach(item => {
			totalPigsReal += item.numOfPig ? item.numOfPig : 0;
			if (item.numOfPig !== undefined && item.numOfPig > 0 && item.feedCurveWeight !== undefined) {
				totalWeight += item.feedCurveWeight * item.numOfPig;
				totalPigs += item.numOfPig;
			}
		});
		return totalPigsReal > 0 && totalWeight && totalPigs > 0 ? totalWeight / totalPigs : undefined;
	};

	const daysSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		let totalPigsReal = 0;
		items.forEach(item => {
			totalPigsReal += item.numOfPig ? item.numOfPig : 0;
			if (item.numOfPig !== undefined && item.numOfPig > 0 && item.days !== undefined) {
				totalWeight += item.days * item.numOfPig;
				totalPigs += item.numOfPig;
			}
		});
		return totalPigsReal > 0 && totalWeight && totalPigs > 0 ? Math.round(totalWeight / totalPigs) : undefined;
	};

	const dailyGainSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalWeight = 0;
		let totalPigs = 0;
		let totalPigsReal = 0;
		items.forEach(item => {
			totalPigsReal += item.numOfPig ? item.numOfPig : 0;
			if (item.numOfPig !== undefined && item.numOfPig > 0 && item.dailyGain !== undefined) {
				totalWeight += item.dailyGain * item.numOfPig;
				totalPigs += item.numOfPig;
			}
		});
		return totalPigsReal > 0 && totalWeight && totalPigs > 0 ? Math.round(totalWeight / totalPigs) : undefined;
	};

	const feedPerPigCurveSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		const curves = items.filter(i => i.feedPerPigCurve).map(i => i.feedPerPigCurve ?? 0);
		const avg = curves.reduce((a, b) => a + b, 0);
		return avg / curves.length ?? undefined;
	};

	const feedPerPigTodaySummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		const curves = items.filter(i => i.feedPerPigToday).map(i => i.feedPerPigToday ?? 0);
		const avg = curves.reduce((a, b) => a + b, 0);
		return avg / curves.length ?? undefined;
	};

	const feedPerPigTotalSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		const curves = items.filter(i => i.feedPerPigTotal).map(i => i.feedPerPigTotal ?? 0);
		const avg = curves.reduce((a, b) => a + b, 0);
		return avg / curves.length ?? undefined;
	};

	const feedPerPigWeightSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		const curves = items.filter(i => i.feedPerPigWeight).map(i => i.feedPerPigWeight ?? 0);
		const avg = curves.reduce((a, b) => a + b, 0);
		return avg / curves.length ?? undefined;
	};

	const numOfPigSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalPigs = 0;
		items.forEach(item => {
			totalPigs += item.numOfPig ? item.numOfPig : 0;
		});

		return totalPigs ? totalPigs : undefined;
	};

	const deadLastSevenDaysSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalPigs = 0;
		items.forEach(item => {
			totalPigs += item.deadLastSevenDays ? item.deadLastSevenDays : 0;
		});

		return totalPigs ? totalPigs : undefined;
	};

	const treatedLastSevenDaysSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		if (items && items.length > 0) {
			return items[0].treatedLastSevenDaysOnSection;
		}
		return undefined;
	};

	const openGraphSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		// determines if icon show show
		return !!items.find(i => i.hasProcessEquipmentData);
	};

	const expectedDeliveryDateSummary = (items: DashboardOverviewGrowthPigsTableData[]) => {
		let totalDaysToDeparture = 0;
		let totalPigs = 0;
		let totalPenDays = 0;
		let totalPigsReal = 0;
		items.forEach(item => {
			totalPigsReal += item.numOfPig ? item.numOfPig : 0;
			if (
				item.numOfPig !== undefined &&
				item.numOfPig > 0 &&
				item.avgDaysToDeparture !== undefined &&
				item.days !== undefined
			) {
				totalPenDays += item.days * item.numOfPig;
				totalDaysToDeparture += item.avgDaysToDeparture * item.numOfPig;
				totalPigs += item.numOfPig;
			}
		});

		if (totalPigsReal > 0 && totalPigs) {
			const avgPenDays = totalPenDays && totalPigs ? Math.round(totalPenDays / totalPigs) : undefined;
			const avgDaysToDeparture =
				totalDaysToDeparture && totalPigs ? Math.round(totalDaysToDeparture / totalPigs) : undefined;

			const avgEntranceDate = moment(new Date()).subtract(avgPenDays, 'days');
			return avgEntranceDate.add(avgDaysToDeparture, 'days').week();
		}
		return undefined;
	};

	const summaryItems = useMemo(() => {
		const summaryItems = [
			{ columnName: 'numOfPig', type: 'numOfPig' },
			{ columnName: 'entryWeight', type: 'entryWeight' },
			{ columnName: 'feedCurveWeight', type: 'feedCurveWeight' },
			{ columnName: 'days', type: 'days' },
			{ columnName: 'dailyGain', type: 'dailyGain' },
		];
		if (hasDistriwin) {
			summaryItems.push({ columnName: 'feedPerPigCurve', type: 'feedPerPigCurve' });
			summaryItems.push({ columnName: 'feedPerPigToday', type: 'feedPerPigToday' });
			summaryItems.push({ columnName: 'feedPerPigTotal', type: 'feedPerPigTotal' });
			summaryItems.push({ columnName: 'feedPerPigWeight', type: 'feedPerPigWeight' });
		}
		summaryItems.push({ columnName: 'deadLastSevenDays', type: 'deadLastSevenDays' });
		summaryItems.push({ columnName: 'treatedLastSevenDays', type: 'treatedLastSevenDays' });
		summaryItems.push({ columnName: 'dateString', type: 'dateString' });
		if (hasDistriwin) {
			summaryItems.splice(0, 0, { columnName: 'openGraph', type: 'openGraph' });
		}
		return summaryItems;
	}, [hasDistriwin]);

	const getBuildingSectionCell = (item: DashboardOverviewGrowthPigsTableData) => item.buildSection;

	const columnData = useMemo(() => {
		const defaultpredictedWeightAtSale = props.productionType === AnimalType.Finisher ? 115 : 30;
		const columns = [
			{
				columnName: 'sectionId',
				name: 'sectionId',
				width: 140,
				title: localized('Location'),
				getCellValue: getBuildingSectionCell,
			},
			{ columnName: 'pen', name: 'pen', width: 140, title: localized('pen') },
			{
				columnName: 'numOfPig',
				name: 'numOfPig',
				width: 100,
				title: localized('numOfPigs'),
				getCellValue: getPigCountCell,
				summaryMethod: numOfPigSummary,
				onSummaryClicked: openEntranceDepartureBySection,
			},
			{
				columnName: 'entryWeight',
				name: 'entryWeight',
				width: 80,
				title: localized('entryWeight'),
				getCellValue: getEntryWeightCell,
				summaryMethod: entryWeightSummary,
			},
			{
				columnName: 'feedCurveWeight',
				name: 'feedCurveWeight',
				width: 80,
				title: localized('weightToday'),
				getCellValue: getfeedCurveWeightCell,
				summaryMethod: feedCurveWeightSummary,
			},
			{
				columnName: 'days',
				name: 'days',
				width: 80,
				title: localized('days'),
				summaryMethod: daysSummary,
				getCellValue: getDaysCell,
			},
			{
				columnName: 'dailyGain',
				name: 'dailyGain',
				width: 80,
				title: localized('dailyGain'),
				getCellValue: getDailyGainCell,
				summaryMethod: dailyGainSummary,
			},
		] as any[];
		if (hasDistriwin) {
			columns.push({
				columnName: 'feedPerPigCurve',
				name: 'feedPerPigCurve',
				width: 100,
				title: localized('feedPerPigCurve'),
				getCellValue: getFeedPerPigCurveCell,
				summaryMethod: feedPerPigCurveSummary,
			});
			columns.push({
				columnName: 'feedPerPigToday',
				name: 'feedPerPigToday',
				width: 80,
				title: localized('feedPerPigToday'),
				getCellValue: getFeedPrPigToday,
				summaryMethod: feedPerPigTodaySummary,
			});
			columns.push({
				columnName: 'feedPerPigTotal',
				name: 'feedPerPigTotal',
				width: 80,
				title: localized('feedPerPigTotal'),
				getCellValue: getFeedPrPigTotal,
				summaryMethod: feedPerPigTotalSummary,
			});
			columns.push({
				columnName: 'feedPerPigWeight',
				name: 'feedPerPigWeight',
				width: 80,
				title: localized('feedPerPigWeight'),
				getCellValue: getFeedPrPigWeight,
				summaryMethod: feedPerPigWeightSummary,
			});
		}
		columns.push({
			columnName: 'deadLastSevenDays',
			name: 'deadLastSevenDays',
			width: 120,
			title: localized('deadLastSevenDays'),
			getCellValue: getDeadLastSevenDaysCell,
			summaryMethod: deadLastSevenDaysSummary,
			onSummaryClicked: openDeadLastSevenDaysBySection,
		});
		columns.push({
			columnName: 'treatedLastSevenDays',
			name: 'treatedLastSevenDays',
			width: 120,
			title: localized('treatedLastSevenDays'),
			getCellValue: getTreatedLastSevenDaysCell,
			summaryMethod: treatedLastSevenDaysSummary,
			onSummaryClicked: openTreatedLastSevenDaysBySection,
		});
		columns.push({
			columnName: 'dateString',
			name: 'dateString',
			width: 160,
			title: localizedInterpolation(
				'expectedDeliveryDate',
				dashboardSetting?.predictedWeightAtSale ?? defaultpredictedWeightAtSale,
			),
			summaryMethod: expectedDeliveryDateSummary,
		});
		if (hasDistriwin) {
			columns.splice(2, 0, {
				columnName: 'openGraph',
				name: 'openGraph',
				width: 40,
				title: ' ',
				getCellValue: getOpenGraphCell,
				summaryMethod: openGraphSummary,
				onSummaryClicked: openFeedCurveGraph,
				summaryComponent: (
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={Feedlog}
					/>
				),
			});
		}
		return columns;
	}, [dashboardSetting, hasDistriwin, props.productionType]);

	const getRowId = (row: DashboardOverviewGrowthPigsTableData) => row.penId;

	return (
		<SkioldTableGroupGrid
			tableKey={'DashboardOverviewGrowthPigs3'}
			rowsToRender={10}
			summaryItems={summaryItems}
			groupedHeaders={groupedHeaders()}
			groupBy="sectionId"
			columns={columnData}
			data={tableData}
			sortingDisabled={true}
			filteringDisabled={true}
			getRowId={getRowId}
		/>
	);
});

interface DashboardOverviewGrowthPigsTableData extends IGrowthPigsDashboardDto {
	buildSection: string;
	pen: string;
	dateString: string;
	orderPen: number;
	orderSection: number;
	orderBuilding: number;
}
