import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import { CloudToDeviceStatusEnum } from 'shared/api/api';


export class EsfCloudToDeviceMessageRequest {
	public siteId: string | undefined;
	public fromDate: Date | undefined;
	public toDate: Date | undefined;
}

export const GetEsfCloudToDeviceMessages = createAsyncThunk('esfCloudToDeviceMessages/getEsf', (request: EsfCloudToDeviceMessageRequest) => {
	return AsyncOperationHandler(client => client.retryEsf_GetEsfCloudToDeviceMessages(request.siteId, request.fromDate, request.toDate));
});


export const GetEsfCloudToDeviceMessageByIds = createAsyncThunk('esfCloudToDeviceMessages/getEsfCloudToDeviceMessageByIds', (ids: string[]) => {
	return AsyncOperationHandler(client => client.retryEsf_GetEsfCloudToDeviceMessageByIds(ids));
});

export class ResendEsfC2MRequest {
	public id: string | undefined;
	public status: CloudToDeviceStatusEnum | undefined;
}


export const ResendEsfC2M = createAsyncThunk('esfCloudToDeviceMessages/resendEsfC2M', (request: ResendEsfC2MRequest) => {
	return AsyncOperationHandler(client => client.retryEsf_ResendEsfC2M(request.id, request.status));
});