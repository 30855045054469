import ObjectID from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	Adjustment,
	AmountBase,
	AmountType,
	Drug,
	DrugAdjustmentDeleteInformation,
	DrugCreate,
	DrugWithAdjustment,
	IAdjustment,
	IDrug,
	SystemAdjustment,
	Usage,
	Waste,
} from 'shared/api/api';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { StoreContainer } from 'shared/state/store-container';
import { DrugUpdate } from '.';
import * as Action from './actions';
import { showAlert } from 'native/view/components/skiold-components/skiold-alert/skiold-alert';
import { localized } from 'shared/state/i18n/i18n';

export function GetDrugs(siteId: string, startDate: Date, endDate: Date) {
	return AsyncOperationBuilder2(Action.getDrugs, client => client.drug_GetBySiteId(siteId, startDate, endDate), {
		siteId,
	});
}

export function RemoveDrug(id: string, showAlert: (msg: string) => void) {
	return AsyncOperationBuilder2(Action.removeDrug, client => client.drug_Delete(id),
		id, undefined, undefined, undefined, undefined, (errorCode: number, message: string) => {
			showAlert(localized(message))
		}
	);
}

export function GetDrugsForMedicineLog(siteId: string, startDate: Date, endDate: Date) {
	return AsyncOperationBuilder2(
		Action.getDrugsForMedicineLog,
		client => client.drug_GetDrugsWithAdjustmentsBySiteId(siteId, startDate, endDate),
		{ siteId },
	);
}

export function DeleteDrugAdjustment(drug: DrugWithAdjustment) {
	return AsyncOperationBuilder2(
		Action.deleteDrug,
		client =>
			client.drug_DeleteAdjustment({
				drugId: drug.id,
				adjustmentId: drug.adjustmentId,
				date: new Date(),
				siteId: drug.siteId,
			} as DrugAdjustmentDeleteInformation),
		drug,
	);
}

export function saveDrug(drug: IDrug, adjustment: IAdjustment) {
	if (!drug.id) {
		drug.id = new ObjectID().toHexString();
	}

	const drugCreate = {
		drug,
		adjustment: getCorrectAnalysisType(adjustment),
	} as DrugCreate;

	return AsyncOperationBuilder2(Action.saveDrug, client => client.drug_Put(drugCreate), {
		drug,
		adjustment,
	} as DrugUpdate);
}

function getCorrectAnalysisType(amountbase: IAdjustment) {
	switch (amountbase.type) {
		case AmountType.Waste:
			return Waste.fromJS(amountbase);
		case AmountType.Usage:
			return Usage.fromJS(amountbase);
		case AmountType.Adjustment:
			return Adjustment.fromJS(amountbase);
		case AmountType.SystemAdjustment:
			return SystemAdjustment.fromJS(amountbase);
		default:
			return amountbase as AmountBase;
	}
}

export function Sync(drugUpdates: DrugUpdate[]) {
	return async (dispatch: Dispatch<any>) => {
		drugUpdates.forEach(async drugUpdate => {
			if (drugUpdate.drug && drugUpdate.adjustment) {
				await saveDrug(drugUpdate.drug, drugUpdate.adjustment)(dispatch);
			}
		});
	};
}

export function saveSyncData() {
	const store = StoreContainer.getState();
	return Sync(store.drugs.updates);
}

export function ArchiveDrug(drug: IDrug, showAlert: (exceptionMessage: string) => void) {
	return AsyncOperationBuilder2(Action.archiveDrug, client => client.drug_ArchiveDrug(Drug.fromJS(drug)),
		drug,
		undefined, undefined, undefined, undefined,
		(errorCode: number, message: string) => {
			showAlert(localized(message));
		}
	);
}

export function DeArchiveDrug(drug: IDrug) {
	return AsyncOperationBuilder2(Action.deArchiveDrug, client => client.drug_DeArchiveDrug(Drug.fromJS(drug)),
		drug,
	);
}

