import React, { FC } from 'react';
import { AnimalType } from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { SkioldCheckbox } from 'web/view/components/skiold-components/skiold-checkbox/skiold-checkbox';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { useGeneralSetting } from './general-settings-overview';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
interface Props {
	animalType: AnimalType;
}

export const SendDataToDistriwinSetting: FC<Props> = React.memo(({ animalType }) => {
	const { generalSetting, setGeneralSetting } = useGeneralSetting();
	const getFormRows = () => {
		let formRows = new Array<FormRow>();
		formRows.push(getSendDataToDistriwin());
		// formRows.push(getSendEntranceDepartureToDistriwin());
		formRows.push(getNotifyCapacity());
		return formRows;
	};

	const getSendDataToDistriwin = (): FormRow => {
		const isChecked =
			animalType === AnimalType.Finisher
				? generalSetting.sendDataToDistriwinFinisher
				: generalSetting.sendDataToDistriwinWeaner;
		return {
			name: localized('SendDataToDistriwin'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={SetSendDataTrue} isChecked={isChecked} />
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox onClick={SetSendDataFalse} isChecked={!isChecked} />
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getSendEntranceDepartureToDistriwin = (): FormRow => {
		const isChecked =
			animalType === AnimalType.Finisher
				? generalSetting.sendEntranceDepartureDistriwinFinisher
				: generalSetting.sendEntranceDepartureDistriwinWeaner;
		return {
			name: localized('SendEntranceDepartureToDistriwin'),
			component: (
				<ViewWeb className="two-checkbox-container">
					<SkioldCheckbox onClick={SetSendEntranceDepartureTrue} isChecked={isChecked} />
					<TextWeb>{localized('True')}</TextWeb>
					<SkioldCheckbox onClick={SetSendEntranceDepartureFlase} isChecked={!isChecked} />
					<TextWeb>{localized('False')}</TextWeb>
				</ViewWeb>
			),
		};
	};

	const getNotifyCapacity = (): FormRow => {
		const currentValue =
			animalType === AnimalType.Finisher
				? generalSetting.notifyCapacityFinisher
				: generalSetting.notifyCapacityWeaner
				? generalSetting.notifyCapacityWeaner
				: 0;

		const onChangeCapacity = (value: number | undefined) => {
			if (animalType === AnimalType.Finisher) {
				setGeneralSetting({ ...generalSetting, notifyCapacityFinisher: value });
			} else if (animalType === AnimalType.Weaner) {
				setGeneralSetting({ ...generalSetting, notifyCapacityWeaner: value });
			}
		};

		return {
			name: localized('NotifyCapacity'),
			component: <SkioldFormIntegerInput text={currentValue} onChangeNumber={onChangeCapacity} />,
		};
	};

	const SetSendDataTrue = () => {
		if (animalType === AnimalType.Finisher) {
			setGeneralSetting({ ...generalSetting, sendDataToDistriwinFinisher: true });
		} else if (animalType === AnimalType.Weaner) {
			setGeneralSetting({ ...generalSetting, sendDataToDistriwinWeaner: true });
		}
	};

	const SetSendDataFalse = () => {
		if (animalType === AnimalType.Finisher) {
			setGeneralSetting({ ...generalSetting, sendDataToDistriwinFinisher: false });
		} else if (animalType === AnimalType.Weaner) {
			setGeneralSetting({ ...generalSetting, sendDataToDistriwinWeaner: false });
		}
	};

	const SetSendEntranceDepartureTrue = () => {
		if (animalType === AnimalType.Finisher) {
			setGeneralSetting({ ...generalSetting, sendEntranceDepartureDistriwinFinisher: true });
		} else if (animalType === AnimalType.Weaner) {
			setGeneralSetting({ ...generalSetting, sendEntranceDepartureDistriwinWeaner: true });
		}
	};

	const SetSendEntranceDepartureFlase = () => {
		if (animalType === AnimalType.Finisher) {
			setGeneralSetting({ ...generalSetting, sendEntranceDepartureDistriwinFinisher: false });
		} else if (animalType === AnimalType.Weaner) {
			setGeneralSetting({ ...generalSetting, sendEntranceDepartureDistriwinWeaner: false });
		}
	};

	return <SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />;
});
