import { Dispatch } from 'redux';
import { IAverted, IFarrowing, IMated, IScanned, IStemAnimal } from 'shared/api/api';
import { SetSowsCount } from 'shared/state/ducks/stem-animals/operations';
import { WebAppState } from 'web/state/store.web';
import { getDateString } from 'shared/helpers/date-helpers';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';

export const PregnancyEventListMapStateToProps = (state: WebAppState) => {
	return {
		pregnancyEvents: state.pregnancyEvents.entities,
		stemAnimals: state.stemAnimals.entities,
		departuredSows: state.stemAnimals.departuredAnimals as IStemAnimal[],
		departuredPregnancies: state.pregnancyEvents.departuredPregnancies, //for render purposes,
		generalSettings: state.generalSettings.entity,
		userProfiles: state.profile.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
		breedTableItems: state.breedTableItems.entities,
	};
};

export const PregnancyEventListMapDispatchToProps = (dispatch: Dispatch, props: {}) => {
	return {
		setSowsCount: (count: number) => SetSowsCount(count)(dispatch),
	};
};

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: (element: JSX.Element) => void;
}

export interface PregnancyEventListState {
	columns: any[];
	commitAll: boolean;
	loading: boolean;
	columnExte: any[];
	stemAnimalId: string;
	sowCardModal: boolean;
	eventSpecificModal?: boolean;
	filteredEvents?: WeaningEventListItem[];
}

//Mating
export class MatingEventListItem {
	public pregnancyEvent: IMated | undefined;
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public boar: string | undefined;
	public litterNumber: number | undefined;
	public breedRace: string | undefined;
}

export class MatingSummaryItem {
	public sowCount?: string;
}

//Scanning
export class ScanningEventListItem {
	public pregnancyEvent: IScanned | undefined;
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public litterNumber: number | undefined;
	public scanResult: string | undefined;
	public breedRace: string | undefined;
}

export class ScanningSummaryItem {
	public sowCount?: string;
	public nonPregnant: number = 0;
	public pregnant: number = 0;
}

//Farrowing
export class FarrowingEventListItem {
	public pregnancyEvent: IFarrowing | undefined;
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public litterNumber: number | undefined;
	public breedRace: string | undefined;
	public litterBreed: string | undefined;
}

export class FarrowingSummaryItem {
	public sowCount?: string;
	public abortions: number = 0;
	public numAliveTotal: number = 0;
	public numDeadTotal: number = 0;
	public avgNumAlive: number = 0;
	public avgNumDead: number = 0;
	public numMummifiedTotal: number = 0;
	public avgNumAliveMummified: number = 0;
}

//Weaning
export class WeaningSummaryItem {
	public sowCount?: string;
	public weightTotal: number = 0;
	public numAliveTotal: number = 0;
	public nursingSowTotal: number = 0;
	public priceTotal: number = 0;
	public avgNumAlive: number = 0;
	public avgWeight: number = 0;
	public avgPrice: number = 0;
}

export class WeaningEventListItem {
	public pregnancyEvent: IAverted | undefined;
	public animalNumber: string | undefined;
	public stemAnimalId: string | undefined;
	public avgWeight: number | undefined;
	public avgPrice: number | undefined;
	public nursingSow: string | undefined;
	public litterNumber: number | undefined;
	public breedRace: string | undefined;
}

type EventListItem = WeaningEventListItem | MatingEventListItem | FarrowingEventListItem | ScanningEventListItem;

export const getEventListAnimalNumberCell = (d: EventListItem) => d.animalNumber;
export const getEventListLitterCell = (d: EventListItem) => d.litterNumber;

export const getEventListPregnancyDateCell = (d: EventListItem) =>
	d.pregnancyEvent ? getDateString(d.pregnancyEvent.date) : ' ';

export type PregnancyEventListProps = ReturnType<typeof PregnancyEventListMapStateToProps> &
	ReturnType<typeof PregnancyEventListMapDispatchToProps> &
	PropsFromParent;
