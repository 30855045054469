import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { TreatmentDefinitionTableModel } from './treatment-definitions';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { getDrugsText } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { getAnimalKindString } from 'shared/helpers/animal-kinds-helpers';
import { WebAppState } from 'web/state/store.web';
import SkioldTable, { Row } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { getAnimalTypesForTreatments } from 'shared/helpers/treatment-helper/treatment-helper';
import { AnimalType, ITreatmentDefinition } from 'shared/api/api';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { Heading } from 'web/view/components/utils/heading';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { DeArchiveTreatmentDefinition } from 'shared/state/ducks/treatment-definitions/operations';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import sendToArchive from 'shared/assets/src-assets/Icon_Arkiv_Medicin.svg';
interface PropsFromParent {
	closeModal: () => void;
}

export const TreatmentDefinitionArchived: FC<PropsFromParent> = React.memo(({ closeModal }) => {
	const dispatch = useDispatch();

	const treatmentDefinitions = useSelector((state: WebAppState) => state.treatmentDefinitions.entities);
	const categories = useSelector((state: WebAppState) => state.diagnoseCategory.entities);
	const diagnoses = useSelector((state: WebAppState) => state.diagnose.entities);

	const drugTypes = useSelector((state: WebAppState) => state.drugType.entities);
	const drug = useSelector((state: WebAppState) => state.drugs.drugs);
	const language = useSelector((state: WebAppState) => state.profile.active!.language);
	const animalTypes = useSelector((state: WebAppState) =>
		state.navigation.query && state.navigation.query.type
			? (state.navigation.query.type as AnimalType)
			: AnimalType.Sow,
	);

	const getTreatmentDefinitionCell = useCallback((row: Row<TreatmentDefinitionTableModel>) => {
		const activate = async () => {
			if (await ShowConfirmAlert(localized('ConfirmTreatDefDeArchivation'))) {
				DeArchiveTreatmentDefinition(row.original.treatmentDefinition)(dispatch);
			}
		};
		return (
			<SkioldTouchableOpacity className="align-items-center" onPress={activate}>
				<SkioldImage className="align-items-center" width={35} height={35} imageData={sendToArchive} />
			</SkioldTouchableOpacity>
		);
	}, []);

	const columns = useMemo(
		() => [
			{
				id: 'Activate',
				Header: localized('Activate'),
				width: 70,
				sortable: false,
				filterable: false,
				Cell: getTreatmentDefinitionCell,
			},
			{
				id: 'category',
				width: 200,
				Header: localized('category'),
				accessor: (definition: TreatmentDefinitionTableModel) => definition.categoryName,
			},
			{
				id: 'name',
				width: 200,
				Header: localized('diagnoseName'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					return getDiagnoseName(definition.diagnose, language);
				},
			},
			{
				id: 'type',
				width: 200,
				Header: localized('drug'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					if (!definition.diagnose) {
						return '';
					}

					let treatDef = definition.treatmentDefinition;
					return getDrugsText(treatDef, drugTypes);
				},
			},
			{
				id: 'animalkind',
				width: 200,
				Header: localized('Production'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					if (!definition.diagnose) {
						return '';
					}

					let treatDef = definition.treatmentDefinition;
					return getAnimalKindString(treatDef.animalKinds);
				},
			},
		],
		[drugTypes, language],
	);

	const getCategoryNameById = useCallback(
		(categoryId: string) => {
			const cat = categories.find(c => c.id === categoryId);
			if (cat) {
				return cat.name![language!];
			}
			return '';
		},
		[categories, language],
	);

	const archived = useMemo(() => {
		let ArrayOfTreatmentIds = new Array<TreatmentDefinitionTableModel>();
		let definitions = new Array<ITreatmentDefinition>();
		definitions = definitions.concat(
			treatmentDefinitions.filter(
				treatmentDefinition => treatmentDefinition.isArchived && treatmentDefinition.isActive === true,
			),
		);
		categories.forEach(category => {
			definitions.forEach(definition => {
				let defDiagnose = diagnoses.find(diag => diag.id === definition.diagnoseId);
				if (
					defDiagnose &&
					definition.animalKinds &&
					definition.animalKinds.find(
						ak =>
							ak &&
							getAnimalTypesForTreatments(animalTypes).find(at => at.toString() === ak.toString()) !==
								undefined,
					) &&
					defDiagnose.diagnoseCategoryId === category.id!
				) {
					let treatmentIds: TreatmentDefinitionTableModel = {
						categoryId: category.id!,
						treatmentDefinition: definition,
						diagnose: defDiagnose,
						categoryName: getCategoryNameById(category.id!),
					};
					ArrayOfTreatmentIds.push(treatmentIds);
				}
			});
		});
		return ArrayOfTreatmentIds;
	}, [animalTypes, treatmentDefinitions, categories, diagnoses, getCategoryNameById]);

	return (
		<PageContainer className="archived-treatment-definitions">
			<Heading text={localized('Archive')} />
			<SkioldTable columns={columns} data={archived} sortHeaderId={'category'} />
			<ViewWeb className="view-button-container">
				<SkioldButton className="close-button" theme="grey" title={localized('Close')} onPress={closeModal} />
			</ViewWeb>
		</PageContainer>
	);
});
