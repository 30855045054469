import { ITreatmentDefinition, IDrugType, DrugTypeType, AnimalKind } from "shared/api/api";
import { localized, localizedDynamic } from "shared/state/i18n/i18n";
import { StoreContainer } from "shared/state/store-container";


export function getDrugsText(treatmentDefinition: ITreatmentDefinition | undefined, drugTypes: IDrugType[]) {
    if (!treatmentDefinition || !treatmentDefinition.subTreatments || !drugTypes) {
        return '';
    }

    let drugNames = new Array<string>();

    treatmentDefinition.subTreatments.forEach(subTreat => {
        const drugType = drugTypes.find(drug => drug.id === subTreat.drugTypeId);

        if (drugType && drugType.id && drugType.name) {
            const isArchived = IsDrugArchived(drugType.id, treatmentDefinition.animalKinds![0]);
            drugNames.push(isArchived ? `${drugType.name} (${localized("Archive")})` : drugType.name);
        }
    });

    return drugNames.join(', ');
}

export function getDrugTypeTypeOptions() {
    return Object.keys(DrugTypeType).map(k => {
        return {
            label: localizedDynamic(DrugTypeType[k as any]),
            value: DrugTypeType[k as any]
        };
    });
}




export function IsDrugArchived(drugTypeId: string, animalKind: AnimalKind) {
    if (animalKind === undefined) {
        return false;
    }
    const drugs = StoreContainer.getState().drugs.drugs;

    const drug = drugs.find(drug => drug.drugTypeId === drugTypeId && drug.animalKinds?.includes(animalKind));
    return drug?.isArchived;
}