import { createReducer, createSlice } from '@reduxjs/toolkit';
import { deleteRecipe, getRecipes, upsertRecipe } from './operation';
import { RecipeState } from './types';
import { SharedAppState } from 'shared/state/store.shared';
import { IRecipeDto, RecipeDto } from 'shared/api/api';
import { removeValueFromArray, removeValueFromArrayNoUnique, upsertMultipleValuesInArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';

const initialState: RecipeState = {
	recipes: [],
	pending: false,
};

const recipeReducer = createReducer(initialState, builder => {
	builder.addCase(getRecipes.fulfilled, (state, action) => {
		state.recipes = action.payload ?? [];
	});
	builder.addCase(getRecipes.pending, (state, action) => {
	});
	builder.addCase(getRecipes.rejected, (state, action) => {
	});

	builder.addCase(upsertRecipe.fulfilled, (state, action) => {
		state.recipes = upsertValueInArray(state.recipes, action.payload);
		state.pending = false;
	});

	builder.addCase(upsertRecipe.pending, (state, action) => {
		state.pending = true;
	});
	builder.addCase(upsertRecipe.rejected, (state, action) => {
		state.pending = false;
	});

	builder.addCase(deleteRecipe.fulfilled, (state, action) => {
		if (action.payload && action.payload.id) {
			state.recipes = removeValueFromArray(state.recipes, action.payload.id);
			state.pending = false;
		}

	});

	builder.addCase(deleteRecipe.pending, (state, action) => {
		state.pending = true;
	});
	builder.addCase(deleteRecipe.rejected, (state, action) => {
		state.pending = false;
	});

	builder.addCase('persist/REHYDRATE', (state, action) => {
		let a = ((action as any) as { payload: SharedAppState; key: string }) as any;

		if (a.key === 'root') {
			let entities = new Array<IRecipeDto>();

			if (a.payload && a.payload.recipe.recipes) {
				entities = a.payload.recipe.recipes.map((dt: IRecipeDto) => {
					let ndt = RecipeDto.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}
			state.recipes = entities;
		}
	});
});

export default recipeReducer;
