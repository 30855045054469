import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { AnimalType } from 'shared/api/api';
import { useDispatch, useSelector } from 'react-redux';
import { getRecipes } from 'shared/state/ducks/recipe/operation';
import { WebAppState } from 'web/state/store.web';
import { Option } from 'react-dropdown';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';

interface PropsFromParent {
	animalType?: AnimalType;
	penId?: string;
	onSelect?: (recipeNumber: number) => void;
	form?: boolean;
}
export const DistriwinRecipePicker: FC<PropsFromParent> = React.memo(({ onSelect, animalType, penId, form }) => {
	const [selected, setSelected] = useState<Option | undefined>(undefined);
	const { siteId } = useSelector((state: WebAppState) => state.profile.active!);
	const { recipes } = useSelector((state: WebAppState) => state.recipe);
	const utp = useSelector((state: WebAppState) => state.unitToPenData.data.find(utp => utp.penId === penId));
	const valveGroup = useSelector((state: WebAppState) =>
		state.adjustFeed.valveGroups.find(vg => vg.id === utp?.unitGroupId),
	);
	const dispatch = useDispatch();
	const recipeOptions = useMemo(() => {
		return recipes.map(r => ({ value: r.recipeNumber.toString(), label: r.name } as Option));
	}, [recipes]);

	useEffect(() => {
		if (siteId) {
			dispatch(getRecipes(siteId));
		}
	}, [siteId]);

	// Set recipe on init
	useEffect(() => {
		if (valveGroup && recipeOptions) {
			const item = recipeOptions.find(r => r.value === valveGroup.recipeNumber!.toString());
			if (item) {
				setSelected(item);
				if (onSelect) {
					onSelect(+item.value);
				}
			}
		}
	}, [valveGroup, recipeOptions]);

	const onSelectLocal = useCallback(
		(newValue: Option, itemFromParent?: any) => {
			setSelected(newValue);
			if (onSelect) {
				onSelect(+newValue.value);
			}
		},
		[setSelected],
	);
	return form ? (
		<SkioldFormDropdown
			items={recipeOptions}
			theme={'dark'}
			containerClassName="grade-dropdown"
			selectedValue={selected}
			onValueChanged={onSelectLocal}
		/>
	) : (
		<SkioldDropdown
			items={recipeOptions}
			theme={'light'}
			containerClassName="basic-dropdown"
			selectedValue={selected}
			onValueChanged={onSelectLocal}
		/>
	);
});
