import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Gender, IFarrowing, IMated, IPregnancyEvent, IStemAnimal, PregnancyState } from 'shared/api/api';
import sokort_icon from 'shared/assets/src-assets/png/sokort_icon.png';
import { exactStartsWithMethodGrid } from 'shared/helpers/general-helpers';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import {
	findBreedRace,
	hasNucleusFeatureCEFN,
} from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { calculateLitterByPregnancy } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { localized } from 'shared/state/i18n/i18n';
import 'web/view/components/event-lists/pregnancy-event-lists/sow-pregnancy-event-list-styles.scss';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import 'web/view/components/skiold-components/skiold-table/skiold-double-header-table.scss';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { getActiveSows } from 'web/view/components/stem-animal/stem-animal-input/stem-animal-input-helper';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import SowCard from 'web/view/pages/sow-card/sow-card';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import {
	FarrowingEventListItem,
	FarrowingSummaryItem,
	getEventListAnimalNumberCell,
	getEventListLitterCell,
	getEventListPregnancyDateCell,
	PregnancyEventListMapDispatchToProps,
	PregnancyEventListMapStateToProps,
	PregnancyEventListProps,
	PregnancyEventListState,
} from '../sow-pregnancy-event-list-helper';

export class FarrowingEventListTable extends React.PureComponent<PregnancyEventListProps, PregnancyEventListState> {
	public SkioldTableRef: SkioldTableRef | undefined;

	private generateData = memoize((dateFrom, dateTo, departuredSows, pregnancies, departuredPregnancies) =>
		this.genereateListData(),
	);

	private defaultSorting = [{ columnName: 'animalNumber', direction: 'asc' }] as Sorting[];
	private readonly getAbortionCell = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? localized(d.pregnancyEvent.abortion ? 'True' : 'False') : ' ';

	private readonly getNumDeadCell = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.numDead : ' ';

	private readonly getNumAliveCell = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.numAlive : ' ';

	private readonly showSowCard = (d: FarrowingEventListItem) => {
		this.setState({ sowCardModal: true, stemAnimalId: d.stemAnimalId! });
	};
	private readonly getInitCell = (d: FarrowingEventListItem) => {
		if (d.pregnancyEvent) {
			const user = this.props.userProfiles.find(u => u.id === d.pregnancyEvent?.createdBy);
			return user?.initials ?? '';
		}
		return ' ';
	};
	private readonly getFemaleAlive = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.numFemaleAlive : ' ';
	private readonly getMaleAlive = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.numMaleAlive : ' ';
	private readonly getEarNotch = (d: FarrowingEventListItem) => (d.pregnancyEvent ? d.pregnancyEvent.earNotch : ' ');
	private readonly getLitterBreed = (d: FarrowingEventListItem) => d.litterBreed;
	private readonly getLitterWeight = (d: FarrowingEventListItem) =>
		d.pregnancyEvent ? d.pregnancyEvent.litterWeight : ' ';
	private readonly getMumified = (d: FarrowingEventListItem) => (d.pregnancyEvent ? d.pregnancyEvent.mumified : ' ');

	constructor(props: PregnancyEventListProps) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			loading: false,
			commitAll: false,
			stemAnimalId: '',
			sowCardModal: false,
		};
	}

	public genereateListData() {
		let activeSows = getActiveSows();
		let { pregnancyEvents } = this.props;
		let listItems: FarrowingEventListItem[] = [];
		this.insertDataIntoListItems(activeSows, listItems, pregnancyEvents);
		if (this.props.departuredSows && this.props.departuredSows.length > 0) {
			this.insertDataIntoListItems(this.props.departuredSows, listItems, this.props.departuredPregnancies);
		}
		this.generateSummary(listItems);
		return listItems;
	}

	public generateColumnsExtensions() {
		let columnExt = [
			{
				columnName: 'sowCard',
				width: SowListConstants.iconWidth,
				filteringEnabled: false,
				sortingEnabled: false,
				resizingEnabled: false,
			},
			{
				columnName: 'animalNumber',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'breedRace',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'litter',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'date',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'numAlive',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'numDead',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'abortion',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.generalSettings.farrowingShowAliveFemales) {
			columnExt.push({
				columnName: 'farrowingShowAliveFemales',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.generalSettings.farrowingShowAliveMales) {
			columnExt.push({
				columnName: 'farrowingShowAliveMales',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columnExt.push({
				columnName: 'earNotch',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.generalSettings.farrowingShowRaceOnLitter && this.props.nucleusManagement) {
			columnExt.push({
				columnName: 'farrowingShowRaceOnLitter',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (
			this.props.generalSettings.farrowingRegisterLitterWeight &&
			hasNucleusFeatureCEFN(this.props.nucleusManagement)
		) {
			columnExt.push({
				columnName: 'farrowingRegisterLitterWeight',
				width: SowListConstants.animalNrWidth,
			});
		}
		if (this.props.generalSettings.farrowingRegisterMumifiedPigs) {
			columnExt.push({
				columnName: 'farrowingRegisterMumifiedPigs',
				width: SowListConstants.animalNrWidth,
			});
		}

		if (this.props.generalSettings.stemAnimalShowInitials) {
			columnExt.push({
				columnName: 'initial',
				width: SowListConstants.animalNrWidth,
			});
		}
		return columnExt;
	}

	public componentDidMount() {
		if (this.SkioldTableRef) {
			this.props.setSowsCount(this.SkioldTableRef.GetSortedData().length);
		}
	}

	public render() {
		return (
			<div className="alignTableCenterDefault">
				<SkioldTableGrid
					tableKey={'farrowingEventListTable'}
					columns={this.state.columns}
					ColumnExtensions={this.state.columnExte}
					data={this.generateData(
						this.props.dateFrom,
						this.props.dateTo,
						this.props.departuredSows,
						this.props.pregnancyEvents,
						this.props.departuredPregnancies,
					)}
					ref={this.setTableRef}
					onFiltersChanged={this.onFiltersChanged}
					sortHeaderId={this.defaultSorting}
				/>

				<SkioldModal
					shouldCloseOnOverlayClick={true}
					padding="0"
					isOpen={this.state.sowCardModal}
					close={this.closeModal}
					justify-content="flex-end"
					max-width="calc(100% - 220px)"
				>
					<SowCard stemAnimalIdFromParent={this.state.stemAnimalId} close={this.closeModal} />
				</SkioldModal>
			</div>
		);
	}

	private insertDataIntoListItems(
		stemAnimals: IStemAnimal[],
		listItems: FarrowingEventListItem[],
		pregnancyEvents: { [key: string]: IPregnancyEvent[] },
	) {
		stemAnimals.forEach(sow => {
			let sowPregnancyEvents = pregnancyEvents[sow.id!];
			if (sowPregnancyEvents && sowPregnancyEvents.length > 0) {
				let farrowingPregnancyEvents = sowPregnancyEvents.filter(a => a.state === PregnancyState.Farrowing);
				farrowingPregnancyEvents.forEach(pregnancyEvent => {
					if (
						this.props.dateFrom === undefined ||
						this.props.dateTo === undefined ||
						(pregnancyEvent.date! >= this.props.dateFrom && pregnancyEvent.date! <= this.props.dateTo)
					) {
						let item = new FarrowingEventListItem();
						item.stemAnimalId = sow.id;
						item.litterNumber = calculateLitterByPregnancy(sow, pregnancyEvent, sowPregnancyEvents);
						item.animalNumber = sow.animalNumber;
						item.pregnancyEvent = pregnancyEvent as IFarrowing;
						let mating = sowPregnancyEvents.find(
							preg =>
								preg.state === PregnancyState.Mated && preg.pregnancyId === pregnancyEvent.pregnancyId,
						) as IMated;
						let dad = mating
							? this.props.stemAnimals.find(
									boar => boar.gender === Gender.Male && boar.id === mating.boarId,
							  )
							: undefined;
						item.breedRace = sow.race;
						item.litterBreed = !item.pregnancyEvent.litterBreed
							? findBreedRace(this.props.nucleusManagement, this.props.breedTableItems, sow, dad)
							: item.pregnancyEvent.litterBreed;
						listItems.push(item);
					}
				});
			}
		});
	}
	private generateSummary(pregnancyEvents: FarrowingEventListItem[]) {
		let summaryItem: FarrowingSummaryItem = new FarrowingSummaryItem();
		pregnancyEvents.forEach(event => {
			if (event.pregnancyEvent!.abortion) {
				summaryItem.abortions++;
			}
			if (event.pregnancyEvent!.numAlive) {
				summaryItem.numAliveTotal = summaryItem.numAliveTotal + event.pregnancyEvent!.numAlive!;
			}
			if (event.pregnancyEvent!.numDead) {
				summaryItem.numDeadTotal = summaryItem.numDeadTotal + event.pregnancyEvent!.numDead!;
			}
			if (summaryItem.numAliveTotal !== 0) {
				summaryItem.avgNumAlive = summaryItem.numAliveTotal / pregnancyEvents.length;
			}
			if (summaryItem.numDeadTotal) {
				summaryItem.avgNumDead = summaryItem.numDeadTotal / pregnancyEvents.length;
			}
			if (event.pregnancyEvent!.mumified) {
				summaryItem.numMummifiedTotal = summaryItem.numMummifiedTotal + event.pregnancyEvent!.mumified!;
			}
			if (summaryItem.numMummifiedTotal !== 0) {
				summaryItem.avgNumAliveMummified = (summaryItem.numMummifiedTotal / summaryItem.numAliveTotal)*100;
			}
		});
		summaryItem.sowCount = pregnancyEvents.length.toString();
		this.props.onSummaryChanged(
			<div className="marginTopTen flexDirectionRow">
				<div className={'borderRight'}>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('farrowing')}: </div>
						<div className="summaryTextStyle"> {summaryItem.sowCount} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('NumAliveTotal')}: </div>
						<div className="summaryTextStyle"> {summaryItem.numAliveTotal} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('NumDeadTotal')}: </div>
						<div className="summaryTextStyle"> {summaryItem.numDeadTotal} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('NumMummifiedTotal')}: </div>
						<div className="summaryTextStyle"> {summaryItem.numMummifiedTotal} </div>
					</div>
				</div>
				<div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle">&nbsp;</div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('AvgAliveShort')}:</div>
						<div className="summaryTextStyle"> {summaryItem.avgNumAlive.toFixed(1)} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('AvgDeadShort')}: </div>
						<div className="summaryTextStyle"> {summaryItem.avgNumDead.toFixed(1)} </div>
					</div>
					<div className="flexDirectionRowWithWidth">
						<div className="summaryTextStyle"> {localized('AvgNumAliveMummified')}: </div>
						<div className="summaryTextStyle"> {summaryItem.avgNumAliveMummified.toFixed(1)} </div>
					</div>
				</div>
			</div>,
		);
	}

	private onFiltersChanged = (events: FarrowingEventListItem[]) => {
		this.generateSummary(events);
	};

	private closeModal = () => {
		this.setState({ stemAnimalId: '', sowCardModal: false });
	};

	private setTableRef = (m: any) => (m ? (this.SkioldTableRef = m) : {});

	private getStemAnimalNumberColor = (tableItem: FarrowingEventListItem) => {
		return tableItem && tableItem.stemAnimalId ? getWebAnimalColorStyleTable(tableItem.stemAnimalId) : '';
	};

	private generateColumns() {
		const columns: any[] = [
			{
				name: 'sowCard',
				title: ' ',
				headerClassName: 'merged-header',
				sortable: false,
				getCellValue: (d: FarrowingEventListItem) => (
					<SkioldTouchableOpacity itemFromParent={d} onPress={this.showSowCard}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={sokort_icon}
						/>
					</SkioldTouchableOpacity>
				),
			},
			{
				name: 'animalNumber',
				headerClassName: 'merged-header',
				title: localized('animalNumber'),
				className: this.getStemAnimalNumberColor,
				filterFunction: exactStartsWithMethodGrid,
				getCellValue: getEventListAnimalNumberCell,
			},
			{
				name: 'breedRace',
				title: localized('Race'),
			},
			{
				name: 'litter',
				title: localized('LitterNr'),
				getCellValue: getEventListLitterCell,
			},
			{
				name: 'date',
				title: localized('Date'),
				sortFunction: NaturalSortDates,
				getCellValue: getEventListPregnancyDateCell,
			},
			{
				name: 'numAlive',
				title: localized('alive'),
				getCellValue: this.getNumAliveCell,
			},
			{
				name: 'numDead',
				title: localized('dead'),
				getCellValue: this.getNumDeadCell,
			},
			{
				name: 'abortion',
				title: localized('abortion'),
				getCellValue: this.getAbortionCell,
			},
		];
		if (this.props.generalSettings.farrowingShowAliveFemales) {
			columns.push({
				name: 'farrowingShowAliveFemales',
				title: localized('aliveFemale'),
				getCellValue: this.getFemaleAlive,
			});
		}
		if (this.props.generalSettings.farrowingShowAliveMales) {
			columns.push({
				name: 'farrowingShowAliveMales',
				title: localized('aliveMale'),
				getCellValue: this.getMaleAlive,
			});
		}
		if (hasNucleusFeatureCEFN(this.props.nucleusManagement)) {
			columns.push({
				name: 'earNotch',
				title: localized('EarNotch'),
				getCellValue: this.getEarNotch,
			});
		}
		if (this.props.generalSettings.farrowingShowRaceOnLitter && this.props.nucleusManagement) {
			columns.push({
				name: 'farrowingShowRaceOnLitter',
				title: localized('LitterBreed'),
				getCellValue: this.getLitterBreed,
			});
		}
		if (
			this.props.generalSettings.farrowingRegisterLitterWeight &&
			hasNucleusFeatureCEFN(this.props.nucleusManagement)
		) {
			columns.push({
				name: 'farrowingRegisterLitterWeight',
				title: localized('LitterWeight'),
				getCellValue: this.getLitterWeight,
			});
		}
		if (this.props.generalSettings.farrowingRegisterMumifiedPigs) {
			columns.push({
				name: 'farrowingRegisterMumifiedPigs',
				title: localized('Mumified'),
				getCellValue: this.getMumified,
			});
		}
		if (this.props.generalSettings.stemAnimalShowInitials) {
			columns.push({
				name: 'initial',
				title: localized('InitialsShort'),
				getCellValue: this.getInitCell,
			});
		}
		return columns;
	}
}

export default connect(PregnancyEventListMapStateToProps, PregnancyEventListMapDispatchToProps, null, {
	forwardRef: true,
})(FarrowingEventListTable) as any;
