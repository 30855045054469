import { default as licencesReducer } from 'shared/state/ducks/licences';
import { default as adjustFeedReducer } from './ducks/adjust-feed';
import animalReportSetttingReducer from './ducks/animal-report-settings/reducer';
import authenticationReducer from './ducks/authentication';
import authorizationReducer from './ducks/authorizations/reducer';
import countryReducer from './ducks/country/reducer';
import diagnoseCategoriesReducer from './ducks/diagnoseCategories/reducer';
import diagnoseReducer from './ducks/diagnoses';
import drugReducer from './ducks/drug/reducer';
import drugTypeReducer from './ducks/drugTypes/reducer';
import { default as errorReducer } from './ducks/errors';
import esfStatusDataReducer from './ducks/esf-status/reducer';
import feedingCategoriesReducer from './ducks/feeding-categories/reducer';
import feedingSubjectsReducer from './ducks/feeding-subjects/reducer';
import feedingReducer from './ducks/feeding/reducer';
import { default as historyReducer } from './ducks/history';
import { default as locReducer } from './ducks/locations';
import markingsReducer from './ducks/markings';
import matingBatchReportsReducer from './ducks/mating-batch-report';
import matingBatchSettingReducer from './ducks/mating-batch-setting/reducer';
import tableSettingsReducer from './ducks/table-settings/reducer';
import matingBatchReducer from './ducks/mating-batch/reducer';
import moveEventReducer from './ducks/move-events/reducer';
import { default as notificationsReducer } from './ducks/notifications';
import offlineReducer from './ducks/offline';
import pregnancyEventReducer from './ducks/pregnancy-events/reducer';
import processEquipmentDataReducer from './ducks/process-equipment-data/reducer';
import processEquipmentReducer from './ducks/process-equipments/reducer';
import processUnitsReducer from './ducks/process-units/reducer';
import { default as profReducer } from './ducks/profile';
import reasonsReducer from './ducks/reasons/reducer';
import retryAdjustFeedReducer from './ducks/retry-adjust-feed/reducer';
import { default as siteReducer } from './ducks/site/reducer';
import StationReducer from './ducks/station/reducer';
import sowReducer from './ducks/stem-animals/reducer';
import treatmentReducer from './ducks/treatment';
import treatmentDefinitionReducer from './ducks/treatment-definitions/reducer';
import treatmentPlanReducer from './ducks/treatment-plan/reducer';
import UnitToPenDataReducer from './ducks/unit-to-pen/reducer';
import validationSetupReducer from './ducks/validation-setup/reducer';
import { default as widgetReducer } from './ducks/widgets';
import worklistSetttingReducer from './ducks/work-list-settings/reducer';
import workListToTreatmentPlanReducer from './ducks/work-list-to-treatment-plan/reducer';
import esfDailyStatusDataReducer from './ducks/esf-daily-status/reducer';
import generalSettingsReducer from './ducks/general-settings/reducer';
import productionReportSettingReducer from './ducks/production-report-settings';
import deadPigletsEventReducer from './ducks/dead-piglets-event/reducer';
import lardScanningEventReducer from './ducks/lardScanningEvents/reducer';
import distriwinOptionReducer from './ducks/distriwin-option/reducer';
import dashboardSettingsReducer from './ducks/dashboard-settings/reducer';
import resultsPerLitterReducer from './ducks/analysis/results-per-litter/reducer';
import userSitesReducer from './ducks/user-sites/reducer';
import analysisSetttingReducer from './ducks/analysis/settings/reducer';
import resultYoungFemaleAgeReducer from './ducks/analysis/result-young-female-age/reducer';
import analysisPregnancyReducer from './ducks/analysis/analysis-pregnancy/reducer';
import growthPigEventsReducer from './ducks/growth-pig-events/reducer';
import poolYoungFemaleReducer from './ducks/pool-young-female/reducer';
import growthPigTuneProductionReducer from './ducks/growth-pig-tune-production/reducer';
import BalanceWeanedPigsDataReducer from 'shared/state/ducks/balance-weaned-pigs/reducer';
import PenRetentionTimeReducer from 'shared/state/ducks/pen-retention-time/reducer';
import SyncReducer from 'shared/state/ducks/sync/reducer';
import feedComputersReducer from './ducks/feed-computers/reducer';
import nucleusManagementConnectionsReducer from './ducks/nucleus-management-connections/reducer';
import breedTableItemsReducer from './ducks/breed-table-items/reducer';
import nucleusManagementBreedingSettingsReducer from './ducks/nucleus-management-breeding-settings/reducer';
import buildingsReducer from './ducks/buildings/reducer';
import feedUsageReducer from './ducks/feed-usage/reducer';
import { NucleusManagementArchive } from '../../web/view/pages/nucleus-management/settings/archive/nucleus-management-archive';
import nucleusManagementArchiveReducer from './ducks/nucleus-management-archive/reducer';
import skioldFetchReducer from './ducks/skiold-fetch/reducer';
import recipeRedcuer from './ducks/recipe/reducer';
import esfCloudToDeviceMessagesReducer from './ducks/esf-cloud-to-device-messages/reducer';

export function getDefaultPersistConfig(storageEngine: any) {
	return {
		key: 'root',
		whitelist: [
			'animalReportSettings',
			'authentication',
			'licences',
			'balanceWeanedPigsData',
			'countries',
			'diagnose',
			'diagnoseCategory',
			'distriwinOption',
			'drugType',
			'esfDailyStatus',
			'esfStatus',
			'generalSettings',
			'growthPigTuneProduction',
			'locations',
			'markings',
			'matingBatchSetting',
			'matingBatches',
			'modules',
			'notifications',
			'penRetentionTime',
			'poolYoungFemales',
			'processEquipmentData',
			'processEquipments',
			'productionReportSetting',
			'profile',
			'reasons',
			'site',
			'station',
			'tableSettings',
			'treatmentDefinitions',
			'unitToPenData',
			'validationSetup',
			'workListSettings',
			'workListToTreatmentPlans',
			'youngAnimals',
			'nucleusManagementBreedingSettings',
			'breedTableItems',
			'recipe',
		], // put your reducer's key here if it has anything to persist
		storage: storageEngine,
	};
}

export type PersistContainer = { config; reducer: any };

export const pregnancyEvents: PersistContainer = {
	config: {
		key: 'pregnancyEvents',
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: pregnancyEventReducer,
};

export const growthPigEvents: PersistContainer = {
	config: {
		key: 'growthPigEvents',
		whitelist: ['growthPigEvents', 'growthPigEventsUpdates', 'entities'],
	},
	reducer: growthPigEventsReducer,
};

export const stemAnimals: PersistContainer = {
	config: {
		key: 'stemAnimals',
		whitelist: ['entities', 'updates', 'lastSyncDate', 'departuredAnimals', 'entranceEsfFeedingStatus'],
	},
	reducer: sowReducer,
};
export const moveEvents: PersistContainer = {
	config: {
		key: 'moveEvents',
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: moveEventReducer,
};

export const lardScanningEvents: PersistContainer = {
	config: {
		key: 'lardScanningEvents',
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: lardScanningEventReducer,
};

export const treatmentPlans: PersistContainer = {
	config: {
		key: 'treatmentPlans',
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: treatmentPlanReducer,
};
export const treatments: PersistContainer = {
	config: {
		key: 'treatments',
		// Selectively persist keys from treatment
		// Specifically, don't persist all the completed treatments for log
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: treatmentReducer,
};

export const adjustFeed: PersistContainer = {
	config: {
		key: 'adjustfeed',
		// selectively persist keys from adjust feed
		whitelist: ['updates', 'locationStrings', 'valveGroups', 'lastSync', 'lastSyncDate', 'previousEntities'],
	},
	reducer: adjustFeedReducer,
};

export const deadPigletsEvents: PersistContainer = {
	config: {
		key: 'deadPigletsEvents',
		whitelist: ['entities', 'updates', 'lastSyncDate'],
	},
	reducer: deadPigletsEventReducer,
};

export const sharedReducerMap = {
	adjustFeed: adjustFeedReducer,
	analysisPregnancy: analysisPregnancyReducer,
	analysisSettings: analysisSetttingReducer,
	animalReportSettings: animalReportSetttingReducer,
	authentication: authenticationReducer,
	authorization: authorizationReducer,
	balanceWeanedPigsData: BalanceWeanedPigsDataReducer,
	breedTableItems: breedTableItemsReducer,
	buildings: buildingsReducer,
	countries: countryReducer,
	dashboardSettings: dashboardSettingsReducer,
	deadPigletsEvents: deadPigletsEventReducer,
	diagnose: diagnoseReducer,
	diagnoseCategory: diagnoseCategoriesReducer,
	distriwinOption: distriwinOptionReducer,
	drugType: drugTypeReducer,
	drugs: drugReducer,
	esfDailyStatus: esfDailyStatusDataReducer,
	esfStatus: esfStatusDataReducer,
	feedComputers: feedComputersReducer,
	feedUsage: feedUsageReducer,
	feeding: feedingReducer,
	feedingCategory: feedingCategoriesReducer,
	feedingSubject: feedingSubjectsReducer,
	generalSettings: generalSettingsReducer,
	growthPigEvents: growthPigEventsReducer,
	growthPigTuneProduction: growthPigTuneProductionReducer,
	history: historyReducer,
	lardScanningEvents: lardScanningEventReducer,
	licences: licencesReducer,
	locations: locReducer,
	markings: markingsReducer,
	matingBatchReports: matingBatchReportsReducer,
	matingBatchSetting: matingBatchSettingReducer,
	matingBatches: matingBatchReducer,
	moveEvents: moveEventReducer,
	notifications: notificationsReducer,
	nucleusManagementArchive: nucleusManagementArchiveReducer,
	nucleusManagementConnections: nucleusManagementConnectionsReducer,
	nucleusManagementBreedingSettings: nucleusManagementBreedingSettingsReducer,
	offline: offlineReducer,
	penRetentionTime: PenRetentionTimeReducer,
	poolYoungFemales: poolYoungFemaleReducer,
	pregnancyEvents: pregnancyEventReducer,
	processEquipmentData: processEquipmentDataReducer,
	processEquipments: processEquipmentReducer,
	processUnits: processUnitsReducer,
	productionReportSetting: productionReportSettingReducer,
	profile: profReducer,
	reasons: reasonsReducer,
	resultYoungFemaleAge: resultYoungFemaleAgeReducer,
	resultsPerLitter: resultsPerLitterReducer,
	retryAdjustFeed: retryAdjustFeedReducer,
	site: siteReducer,
	station: StationReducer,
	stemAnimals: sowReducer,
	sync: SyncReducer,
	tableSettings: tableSettingsReducer,
	treatmentDefinitions: treatmentDefinitionReducer,
	treatmentPlans: treatmentPlanReducer,
	treatments: treatmentReducer,
	unitToPenData: UnitToPenDataReducer,
	userSites: userSitesReducer,
	validationSetup: validationSetupReducer,
	widgets: widgetReducer,
	workListSettings: worklistSetttingReducer,
	workListToTreatmentPlans: workListToTreatmentPlanReducer,
	skioldFetch: skioldFetchReducer,
	recipe: recipeRedcuer,
	esfCloudToDeviceMessages: esfCloudToDeviceMessagesReducer
};

// Meta reducers are any reducer whose definition is dependent on reducer(s) in `reducerMap`
// This lets us avoid circular type declarations
const metaReducerMap = {
	errors: errorReducer,
};

// AppState is the keys of the reducerMap, with the values returned by each key's respective reducer
export type CoreAppState = { [K in keyof typeof sharedReducerMap]: ReturnType<typeof sharedReducerMap[K]> };

type MetaAppState = { [K in keyof typeof metaReducerMap]: ReturnType<typeof metaReducerMap[K]> };

export type SharedAppState = CoreAppState & MetaAppState;
