import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalKind, Gender, IReasonDto, IStemAnimal, StemAnimal } from 'shared/api/api';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { deepCopy, isEmpty } from 'shared/helpers/general-helpers';
import { getLocationString } from 'shared/helpers/location-helper';
import { MAX_TRANSPONDER_NUMBER } from 'shared/helpers/stemanimal-helper/stemanimal-helper';
import { GetSyncData as MarkingGetSyncData } from 'shared/state/ducks/markings/operations';
import { GetSyncData as ReasonsGetSyncData } from 'shared/state/ducks/reasons/operations';
import { GetSyncData as StemAnimalGetSyncData, SaveSow } from 'shared/state/ducks/stem-animals/operations';
import { UpdateTreatmentPlansForDepartedSow } from 'shared/state/ducks/treatment-plan/operations';
import { localized, localizedDynamic } from 'shared/state/i18n/i18n';
import { DepartureTypes } from 'shared/state/models/departure-types';
import { WebAppState } from 'web/state/store.web';
import { showAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from 'web/view/components/skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from 'web/view/components/skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormTextField } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-form-text';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormIntegerInput } from 'web/view/components/skiold-components/skiold-integer-input/skiold-form-integer-input';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { validateSowAnimalNumberIsNotDepartured } from 'web/web-helpers/pregnancies/web-prengancy-validation-settings';
import { GenderPicker } from '../../gender-picker/gender-picker';
import StemAnimalInput, { StemAnimalInput as StemAnimalInputRef } from '../../stem-animal-input/stem-animal-input';
import { findAnimalById } from '../../stem-animal-input/stem-animal-input-helper';
import './register-sow-departure.scss';
import { getSowState } from 'shared/helpers/pregnancy-helper/sow-state-helper';
import { SowState } from 'shared/state/models/sow-state';
import { DefaultReasonOption } from 'shared/helpers/reason-helper/reason-helper';
import { ResendStemAnimal } from 'shared/helpers/resend-nucleus-helpers';
import { selectNucleusManagementOrAssignIdAccess } from 'shared/state/ducks/site/reducer';
import { hasDanishNucleusFeatureLicence } from 'shared/helpers/nucleus-management-helper/nucleus-management-helper';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';

const mapStateToProps = (state: WebAppState) => {
	return {
		locations: state.locations,
		markings: state.markings.entities,
		reasons: state.reasons.entities,
		siteId: state.profile.active!.siteId,
		aliveStemAnimals: state.stemAnimals.entities,
		departuredStemAnimals: state.stemAnimals.departuredAnimals,
		profile: state.profile.active,
		pregnancies: state.pregnancyEvents.entities,
		nucleusManagement: selectNucleusManagementOrAssignIdAccess(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	handleDeparture: (sow: IStemAnimal) => SaveSow(sow)(dispatch),
	updateTreatmentPlansForDepartedSow: (stemAnimalId: string, isSowActive: boolean) =>
		UpdateTreatmentPlansForDepartedSow(stemAnimalId, isSowActive)(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	loadMarkings: () => MarkingGetSyncData()(dispatch),
	getReasons: () => ReasonsGetSyncData()(dispatch),
});

const DefaultDepartureReasonOption: Option = { label: ' ', value: '' };
const DefaultDepartureTypeOption: Option = { label: ' ', value: '' };
const DefaultMarkingOption: Option = { label: ' ', value: '' };

interface PropsFromParent {
	stemAnimalId?: string;
	closeEditModal?: () => void;
}

interface RegisterDepartureState {
	sow: IStemAnimal;
	animalNumber: string | undefined;
	departureTypes: Option[];
	departureType: Option;
	isEdit: boolean;
	departureDate?: Date;
	marking: Option;
	departureReasonIdOption: Option;
	gender: Gender;
	toggleFocus: boolean;
	transponderNumber: number | undefined;
}

type RegisterDepartureProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	PropsFromParent;
class RegisterDeparture extends React.PureComponent<RegisterDepartureProps, RegisterDepartureState> {
	private animalNumInputRef: StemAnimalInputRef | null | undefined;

	constructor(props: RegisterDepartureProps) {
		super(props);
		this.state = {
			sow: {} as IStemAnimal,
			departureDate: new Date(),
			departureTypes: [
				DefaultDepartureTypeOption,
				{
					label: localized(DepartureTypes.departureTypeSold),
					value: DepartureTypes.departureTypeSold,
				},
				{
					label: localized(DepartureTypes.departureTypeDead),
					value: DepartureTypes.departureTypeDead,
				},
				{
					label: localized(DepartureTypes.departureTypeKilled),
					value: DepartureTypes.departureTypeKilled,
				},
				{
					label: localized(DepartureTypes.departureTypePutDown),
					value: DepartureTypes.departureTypePutDown,
				},
				{
					label: localized(DepartureTypes.departureTypeShouldDeparture),
					value: DepartureTypes.departureTypeShouldDeparture,
				},

				/*,
                {
                    label: localized(DepartureTypes.departureTypeToBeKilled),
                    value: DepartureTypes.departureTypeToBeKilled
                }*/
			],
			isEdit: false,
			departureType: DefaultDepartureTypeOption,
			marking: DefaultMarkingOption,
			departureReasonIdOption: DefaultDepartureReasonOption,
			animalNumber: undefined,
			gender: Gender.Female,
			toggleFocus: false,
			transponderNumber: undefined,
		};
	}

	public componentDidMount() {
		if (!this.props.stemAnimalId) {
			this.props.stemAnimalGetSyncData();
		}
		this.props.getReasons();
		this.props.loadMarkings();
		if (this.props.stemAnimalId) {
			this.findSowById(this.props.stemAnimalId);
		}
	}

	public animalChanged(newAnimal?: IStemAnimal): void {
		let foundSow = newAnimal ? newAnimal : StemAnimal.fromJS({});

		this.setState({
			sow: foundSow,
		});
	}

	public departureDateChanged(newDate: Date) {
		this.setState({ departureDate: newDate });
	}

	public departureTypeChanged = (departureTypeOption: Option) => {
		if (departureTypeOption.value === DepartureTypes.departureTypeShouldDeparture) {
			this.setState({
				departureType: departureTypeOption,
				departureReasonIdOption: DefaultDepartureReasonOption,
				departureDate: undefined,
			});
		} else {
			this.setState({
				departureType: departureTypeOption,
				departureReasonIdOption: DefaultDepartureReasonOption,
				departureDate: this.state.departureDate ? this.state.departureDate : new Date(),
			});
		}
	};

	public departureReasonChanged = (departureReasonIdOption: Option) => {
		this.setState({ departureReasonIdOption });
	};

	public sowPriceTextChanged(newNumber: number | undefined) {
		this.setState(prevState => ({ sow: { ...prevState.sow, departurePrice: newNumber } }));
	}

	public markingChanged(markingOption: Option) {
		this.setState({ marking: markingOption });
	}

	public Remove_Departure_Status = async () => {
		const sowCopy = deepCopy(this.state.sow);
		if (this.props.stemAnimalId) {
			sowCopy.transponder = this.state.transponderNumber ? this.state.transponderNumber.toString() : undefined;
			let animal = this.props.aliveStemAnimals.find(
				aliveAnimal => aliveAnimal.transponder === sowCopy.transponder,
			);
			if (animal && sowCopy.transponder) {
				showAlert(
					localized(ExceptionMessage.VALIDATION_ERROR_TRANSPONDER_ALREADY_IN_USE_BY_SOW) +
						animal.animalNumber,
				);
				return;
			}

			let animalNumberMatch = this.props.aliveStemAnimals.find(
				aliveAnimal =>
					aliveAnimal.animalNumber === sowCopy.animalNumber && sowCopy.gender === aliveAnimal.gender,
			);
			if (animalNumberMatch) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUMBER_ALREADY_IN_USE_BY_SOW));
				return;
			}
		}
		sowCopy.departureDate = undefined;
		sowCopy.departureType = undefined;
		sowCopy.departureMarking = undefined;
		sowCopy.departureReasonId = undefined;

		await this.props.handleDeparture(sowCopy);
		await this.props.updateTreatmentPlansForDepartedSow(sowCopy.id!, true);

		this.resetComponent();
	};

	private getTransponderRow(): FormRow {
		return {
			name: localized('transponder'),
			component: (
				<SkioldFormIntegerInput
					onChangeNumber={this.transponderTextChanged}
					text={this.state.transponderNumber}
				/>
			),
		};
	}

	public render() {
		return <ViewWeb className="register-sow-departure">{this.renderContent()}</ViewWeb>;
	}

	public renderContent() {
		return (
			<ViewWeb>
				{this.renderButtons()}
				<ViewWeb className="departure-z-index">
					<SkioldFormsWrapper formRows={this.getFormRows()} containerClassName="departure-forms-wrapper" />
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getFormRows() {
		let formRows = new Array<FormRow>();
		formRows.push(this.getGenderRow());
		if (this.props.stemAnimalId) {
			formRows.push(this.getAnimalNumberRowDisabled());
		} else {
			formRows.push(this.getAnimalNumberRow());
		}
		if (this.state.sow.id) {
			formRows.push(this.getAnimalRetensionRow(this.state.sow.id));
		}

		formRows.push(this.getLocationRow());
		formRows.push(this.getDateRow());
		if (this.props.stemAnimalId) {
			formRows.push(this.getTransponderRow());
		}
		formRows.push(this.getDepartureTypeRow());
		formRows.push(this.getReasonsRow());
		formRows.push(this.getMarkingPickerRow());

		return formRows;
	}

	private getGenderRow(): FormRow {
		return {
			name: localized('Gender'),
			component: (
				<GenderPicker
					disabled={this.state.isEdit}
					gender={this.state.gender}
					onGenderChanged={(gender: Gender) => this.genderChanged(gender)}
				/>
			),
		};
	}

	private getAnimalNumberRow(): FormRow {
		return {
			name: localized('animalNr'),
			component: (
				<StemAnimalInput
					onAnimalFound={(newAnimal: IStemAnimal | undefined) => this.animalChanged(newAnimal)}
					onAnimalNumberChanged={(animalNumber: string | undefined) => this.setState({ animalNumber })}
					animalNumber={this.state.animalNumber}
					inputType={'form'}
					autoFocus={true}
					gender={this.state.gender}
					ref={this.setAnimalNumInputRef}
				/>
			),
		};
	}

	private getAnimalRetensionRow(stemAnimalId: string): FormRow {
		return {
			name: localized('slaughterRetention'),
			component: <SkioldFormTextField>{findRetentionTime(stemAnimalId)}</SkioldFormTextField>,
		};
	}

	private getAnimalNumberRowDisabled(): FormRow {
		return {
			name: localized('animalNr'),
			component: <SkioldFormTextField>{this.state.sow ? this.state.sow.animalNumber : ''}</SkioldFormTextField>,
		};
	}

	private getLocationRow(): FormRow {
		return {
			name: localized('location'),
			component: (
				<SkioldFormTextField>{this.state.sow ? getLocationString(this.state.sow) : ''}</SkioldFormTextField>
			),
		};
	}

	private getDateRow(): FormRow {
		return {
			name: localized('Date'),
			component: (
				<SkioldDatePicker
					disabled={this.state.departureType.value === DepartureTypes.departureTypeShouldDeparture}
					onDateChanged={newDate => this.departureDateChanged(newDate)}
					selectedDate={this.state.departureDate}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}

	private getDepartureTypeRow(): FormRow {
		return {
			name: localized('departureType'),
			component: (
				<SkioldFormDropdown
					items={this.state.departureTypes}
					selectedValue={this.state.departureType}
					onValueChanged={this.departureTypeChanged}
				/>
			),
		};
	}

	private getReasonsRow(): FormRow {
		return {
			name: localized('reasons'),
			component: (
				<SkioldFormDropdown
					items={this.getReasons()}
					selectedValue={this.state.departureReasonIdOption}
					onValueChanged={this.departureReasonChanged}
				/>
			),
		};
	}

	private getPriceRow(): FormRow {
		return {
			name: localized('departurePrice'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={newNumber => this.sowPriceTextChanged(newNumber)}
					text={this.state.sow.departurePrice}
				/>
			),
		};
	}

	private getMarkingPickerRow(): FormRow {
		return {
			name: localized('marking'),
			component: (
				<SkioldFormDropdown
					items={this.getMarkings()}
					selectedValue={this.state.marking}
					onValueChanged={(marking: Option) => this.markingChanged(marking)}
				/>
			),
		};
	}

	private renderButtons() {
		return (
			<ViewWeb
				className={
					this.props.stemAnimalId ? 'button-view-style-upper-corner-edit' : 'button-view-style-upper-corner'
				}
			>
				{this.props.closeEditModal && (
					<SkioldButton title={localized('Close')} onPress={this.props.closeEditModal} theme="grey" />
				)}

				{this.props.closeEditModal && (
					<SkioldButton
						title={localized('removeDeparture')}
						onPress={this.Remove_Departure_Status}
						theme="grey"
					/>
				)}
				{this.props.closeEditModal && hasDanishNucleusFeatureLicence(this.props.nucleusManagement) && (
					<SkioldButton title={localized('resend')} onPress={this.resendDeparture} theme="grey" />
				)}

				<SkioldButton title={localized('Save')} onPress={this.save} />
			</ViewWeb>
		);
	}

	private resendDeparture = () => {
		ResendStemAnimal(this.state.sow);
	};

	private setAnimalNumInputRef = (ref: StemAnimalInputRef) => {
		this.animalNumInputRef = ref;
	};

	private genderChanged(gender: Gender) {
		this.setState({ gender });
	}

	private getMarkings() {
		let defaultMarkings = [DefaultMarkingOption];
		let markings = this.props.markings.map(marking => {
			return {
				label: marking.translationKey ? localizedDynamic(marking.translationKey) : '',
				value: marking.translationKey ? marking.translationKey : '',
			};
		});

		if (markings) {
			defaultMarkings = defaultMarkings.concat(markings);
		}

		return defaultMarkings;
	}

	private getReasons(): Option[] {
		if (!this.state.departureType.value) {
			return [];
		}

		let reasons: IReasonDto[];

		if (DepartureTypes.IsDeathType(this.state.departureType.value)) {
			reasons = this.props.reasons.filter(
				reason => reason.reasonSettings && reason.reasonSettings.stemAnimalDead,
			);
		} else {
			reasons = this.props.reasons.filter(
				reason => reason.reasonSettings && reason.reasonSettings.stemAnimalDeparture,
			);
		}

		reasons = this.sortReasons(reasons);

		let reasonsForSelect: Option[] = reasons.map(reason => {
			return {
				label: reason.reason!.name![
					this.props.profile && this.props.profile.language ? this.props.profile.language : 'da'
				],
				value: reason.reason!.id!,
			};
		});
		reasonsForSelect.unshift(DefaultDepartureReasonOption);
		return reasonsForSelect;
	}

	public transponderTextChanged = (transponderNumber: number | undefined) => {
		if (!transponderNumber || transponderNumber <= MAX_TRANSPONDER_NUMBER) {
			this.setState({ transponderNumber: transponderNumber });
		}
	};

	private sortReasons(reasons: IReasonDto[]) {
		reasons = reasons.sort((reasonA, reasonB) => {
			return reasonA!.reason!.priorityCode! > reasonB!.reason!.priorityCode! ? 1 : -1;
		});

		return reasons;
	}

	private save = async () => {
		const sowCopy = deepCopy(this.state.sow);
		sowCopy.departureDate = this.state.departureDate;
		sowCopy.departureType = this.state.departureType.value;
		sowCopy.departureMarking = this.state.marking.value;
		sowCopy.departureReasonId = this.state.departureReasonIdOption.value;
		if (this.state.isEdit) {
			sowCopy.transponder = this.state.transponderNumber
				? this.state.transponderNumber.toString()
				: sowCopy.transponder;
		}

		if (!this.validate(sowCopy)) {
			return;
		}

		await this.props.handleDeparture(sowCopy);
		if (sowCopy.departureType !== DepartureTypes.departureTypeShouldDeparture) {
			await this.props.updateTreatmentPlansForDepartedSow(sowCopy.id!, false);
		}

		this.resetComponent();
	};

	private validate(sow: IStemAnimal) {
		let animalNumberCheck = validateSowAnimalNumberIsNotDepartured(
			this.state.sow,
			this.props.departuredStemAnimals,
			this.state.animalNumber,
		);
		if (animalNumberCheck !== 'ignore') {
			showAlert(localized(animalNumberCheck));
			return false;
		}

		if (!sow || !sow.id) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NOT_FOUND));
			return false;
		}

		if (!sow.animalNumber) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_ANIMAL_NUM_NOT_SET));
			return false;
		}

		if (this.state.sow!.entranceKind === AnimalKind.Boar) {
			if (!this.state.sow.entrancePenId) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_BOAR_IS_KS));
				return false;
			}
		}

		if (sow.departureType !== DepartureTypes.departureTypeShouldDeparture) {
			if (!sow.departureType) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_TYPE_NOT_SET));
				return false;
			}

			if (!sow.departureDate) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_NOT_SET));
				return false;
			}

			if (this.props.pregnancies[sow.id]) {
				const lastPregDate = new Date(
					Math.max.apply(
						null,
						this.props.pregnancies[sow.id].map(preg => {
							return new Date(preg.date!).getTime();
						}),
					),
				);
				if (lastPregDate.withoutTime() > sow.departureDate.withoutTime()) {
					showAlert(
						localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_IS_EARLIER_THAN_LAST_PREGNANCY),
					);
					return false;
				}
				const sowState = getSowState(this.props.pregnancies[sow.id]);
				if (sowState === SowState.Nursing || sowState === SowState.PregnantAndNursing) {
					showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_DEPARTURE_ON_NURSING_SOW));
					return false;
				}
			}

			if (sow.departureType === DepartureTypes.departureTypeToBeKilled && sow.departureDate < new Date()) {
				showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DEPARTURE_DATE_AFTER_CURRENT_DATE));
				return false;
			}
		}
		return true;
	}

	// Called after successfull save to remove one-time values
	private async resetComponent() {
		if (this.state.isEdit && this.props.closeEditModal) {
			this.props.closeEditModal();
		}

		const sowCopy = deepCopy(this.state.sow);
		this.setState({
			sow: StemAnimal.fromJS({
				departureDate: new Date(),
				animalNumber: '',
				departureMarking: sowCopy.departureMarking,
				departureType: sowCopy.departureType,
				departureReasonId: sowCopy.departureReasonId,
				departurePrice: sowCopy.departurePrice,
			} as IStemAnimal),
			animalNumber: '',
			toggleFocus: !this.state.toggleFocus,
		});

		this.focusAnimalNumber();
	}

	private focusAnimalNumber = () => {
		if (this.animalNumInputRef) {
			this.animalNumInputRef.focus();
		}
	};

	private findSowById(id: string) {
		const animal = findAnimalById(id);

		if (animal) {
			const reason =
				this.props.reasons &&
				this.props.reasons.find(r => r.reason && r.reason.id === animal.departureReasonId);
			const reasonOption = reason
				? {
						label: reason.reason!.name![
							this.props.profile && this.props.profile.language ? this.props.profile.language : 'da'
						],
						value: reason.reason!.id!,
				  }
				: DefaultReasonOption;

			let stateToSet = {
				sow: animal,
				isEdit: true,
				gender: animal.gender,
				departureDate: new Date(animal.departureDate!),
				animalNumber: animal.animalNumber,
				transponderNumber: animal.transponder && parseInt(animal.transponder),
				departureType: animal.departureType
					? { label: localizedDynamic(animal.departureType), value: animal.departureType }
					: { label: ' ', value: '' },
				departureReasonIdOption: reasonOption,
			} as RegisterDepartureState;
			this.setState(stateToSet);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDeparture);
