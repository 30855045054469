import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AmountType, AnimalKind, DrugTypeType, IAdjustment, IDrug, IDrugType } from 'shared/api/api';
import { getTreatmentAnimalKindsOptions } from 'shared/helpers/general-helpers';
import { GetDrugs, saveDrug } from 'shared/state/ducks/drug/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from '../../page-container/page-container';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldDatePicker } from '../../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFormIntegerInput } from '../../skiold-components/skiold-integer-input/skiold-form-integer-input';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';

export type drugAndDrugType = {
	drug: IDrug;
	drugType: IDrugType;
	id?: string;
	price?: number;
	amount?: number;
};

export type DrugAndAdjustment = {
	drug: IDrug;
	adjustment: IAdjustment;
};

interface PropsFromParent {
	animalKinds: AnimalKind[];
	close: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profileId: state.profile.active!.id,
		drugs: state.drugs.drugs,
		drugTypes: state.drugType.entities,
		profile: state.profile.active!,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveDrug: (drug: IDrug, adjustment: IAdjustment) => saveDrug(drug, adjustment)(dispatch),
	getDrugs: (siteId: string, startDate: Date, endDate: Date) => GetDrugs(siteId, startDate, endDate)(dispatch),
});

interface State {
	selectedDrugTypeOption?: Option;
	selectedDate?: Date;
	selectedTypeOption?: Option;
	animalKinds: AnimalKind[];
	selectedAnimalKind: Option;
	drugsToSave: DrugAndAdjustment[];
	drugAndDrugType: drugAndDrugType[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class AddMedicine extends React.PureComponent<Props, State> {
	private findSelectedAnimalKind(animalKinds: AnimalKind[]) {
		if (
			animalKinds.find(kind => kind === AnimalKind.Sow || kind === AnimalKind.Boar || kind === AnimalKind.Gilt) !=
			null
		) {
			return {
				animalKinds: [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt],
				selectedAnimalKind: { label: localized('SowBoarGlit'), value: AnimalKind.Sow },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.YoungFemale) != null) {
			return {
				animalKinds: [AnimalKind.YoungFemale],
				selectedAnimalKind: { label: localized(AnimalKind.YoungFemale), value: AnimalKind.YoungFemale },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Piglet) != null) {
			return {
				animalKinds: [AnimalKind.Piglet],
				selectedAnimalKind: { label: localized(AnimalKind.Piglet), value: AnimalKind.Piglet },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Finisher) != null) {
			return {
				animalKinds: [AnimalKind.Finisher],
				selectedAnimalKind: { label: localized(AnimalKind.Finisher), value: AnimalKind.Finisher },
			};
		} else if (animalKinds.find(kind => kind === AnimalKind.Weaner) != null) {
			return {
				animalKinds: [AnimalKind.Weaner],
				selectedAnimalKind: { label: localized(AnimalKind.Weaner), value: AnimalKind.Weaner },
			};
		}
	}

	private getDrugs = (fromDate: Date, toDate: Date) => {
		if (this.props.siteId) {
			this.props.getDrugs(this.props.siteId, fromDate, toDate);
		}
	};

	constructor(props: Props) {
		super(props);
		let tempFromDate = new Date();
		tempFromDate.setMonth(new Date().getMonth() - 1);
		let tempToDate = new Date();
		tempToDate.setMonth(new Date().getMonth());

		const selectedAnimalKindOption = this.findSelectedAnimalKind(props.animalKinds);
		if (this.props.siteId) {
			this.getDrugs(tempFromDate, tempToDate);
		}
		this.state = {
			drugsToSave: [],
			selectedAnimalKind: selectedAnimalKindOption
				? selectedAnimalKindOption.selectedAnimalKind
				: { label: localized('SowBoarGlit'), value: AnimalKind.Sow },
			animalKinds: selectedAnimalKindOption
				? selectedAnimalKindOption.animalKinds
				: [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt],
			selectedDrugTypeOption: { label: '', value: '' },
			selectedDate: new Date(),
			selectedTypeOption: { label: localized(DrugTypeType.Medicine), value: DrugTypeType.Medicine },
			drugAndDrugType: this.getDrugTypesForAnimalType(
				selectedAnimalKindOption
					? selectedAnimalKindOption.animalKinds
					: [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt],
			),
		};
	}

	private getDrugTypesForAnimalType(animalKinds: AnimalKind[]) {
		let drugs = this.getFilteredData(animalKinds);
		const drugList: drugAndDrugType[] = [];
		drugs.forEach(drug => {
			if(drug.isArchived){
				return;
			}
			const drugType = this.props.drugTypes.find(a => a.id === drug.drugTypeId);
			if (drugType) {
				drugList.push({ drug, drugType, id: drug.id });
			}
		});
		return drugList;
	}

	private getFilteredData(animalKinds: AnimalKind[]) {
		return this.props.drugs.filter(a => a.animalKinds!.some(r => animalKinds.indexOf(r) >= 0));
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('AddDrug')} />
				<ViewWeb className="flexDirectionRowJustifyContentSpaceBetween">
					<ViewWeb className="flexDirectionRow">
						{this.selectAnimalKindRow()}
						<SkioldDatePicker
							color={'lightGrey'}
							containerClassName="marginLeftTen"
							theme="light"
							fontSize={16}
							selectedDate={this.state.selectedDate}
							onDateChanged={this.onDateChanged}
						/>
					</ViewWeb>
					<ViewWeb className="flexDirectionRow">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.close} />
						<SkioldButton className="marginLeftTen" title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
				<ViewWeb className="alignItemsCenterMarginVerticalTen">
					<SkioldTableGrid
						columns={this.generateColumns()}
						ColumnExtensions={this.columnExtensions}
						data={this.state.drugAndDrugType}
					/>
				</ViewWeb>
			</PageContainer>
		);
	}
	private getDrugTypeNameCell = (item: drugAndDrugType) => item.drugType.name;
	private getDrugUnitOfMeasurementCell = (item: drugAndDrugType) => item.drugType.unitOfMeasurement;
	private getAmountCell = (item: drugAndDrugType) => (
		<SkioldFormIntegerInput text={item.amount} itemFromParent={item.id} onChangeNumber={this.amountChanged} />
	);

	private getPriceCell = (item: drugAndDrugType) => (
		<SkioldFormDecimalInput text={item.price} itemFromParent={item.id} onChangeNumber={this.priceChanged} />
	);

	private generateColumns() {
		return [
			{
				name: 'drug',
				title: localized('drug'),
				getCellValue: this.getDrugTypeNameCell,
			},
			{
				name: 'unit',
				title: localized('Unit'),
				getCellValue: this.getDrugUnitOfMeasurementCell,
			},
			{
				name: 'amount',
				title: localized('amount'),
				getCellValue: this.getAmountCell,
			},

			{
				name: 'price',
				title: localized('price'),
				sortable: false,
				getCellValue: this.getPriceCell,
			},
		];
	}

	private columnExtensions = [
		{
			columnName: 'drug',
			width: 400,
		},
		{
			columnName: 'unit',
			width: 80,
		},
		{
			columnName: 'amount',
			width: 80,
		},
		{
			columnName: 'price',
			width: 80,
		},
	];

	public selectAnimalKindRow = () => (
		<ViewWeb className="alignDropdownCenter">
			<SkioldDropdown
				items={getTreatmentAnimalKindsOptions(this.props.profile)}
				onValueChanged={this.animalKindChanged}
				selectedValue={this.state.selectedAnimalKind}
				theme="light"
			/>
		</ViewWeb>
	);

	private animalKindChanged = (animalKind?: Option) => {
		if (animalKind) {
			if (
				animalKind.value === AnimalKind.Sow ||
				animalKind.value === AnimalKind.Boar ||
				animalKind.value === AnimalKind.Gilt
			) {
				const animalKinds = [AnimalKind.Boar, AnimalKind.Sow, AnimalKind.Gilt];
				this.setAnimalKinds(animalKinds, animalKind);
			} else if (animalKind.value === AnimalKind.YoungFemale) {
				const animalKinds = [AnimalKind.YoungFemale];
				this.setAnimalKinds(animalKinds, animalKind);
			} else if (animalKind.value === AnimalKind.Piglet) {
				const animalKinds = [AnimalKind.Piglet];
				this.setAnimalKinds(animalKinds, animalKind);
			} else if (animalKind.value === AnimalKind.Weaner) {
				const animalKinds = [AnimalKind.Weaner];
				this.setAnimalKinds(animalKinds, animalKind);
			} else if (animalKind.value === AnimalKind.Finisher) {
				const animalKinds = [AnimalKind.Finisher];
				this.setAnimalKinds(animalKinds, animalKind);
			}
		}
	};
	private save = async () => {
		const drugIdsToRemove: string[] = [];
		for (let index = 0; index < this.state.drugsToSave.length; index++) {
			const drugAndAdjustment = this.state.drugsToSave[index];
			if (drugAndAdjustment.drug.id && drugAndAdjustment.adjustment.amount) {
				await this.props.saveDrug(drugAndAdjustment.drug, drugAndAdjustment.adjustment);
				drugIdsToRemove.push(drugAndAdjustment.drug.id);
			}
		}
		const drugsToReset = [...this.state.drugAndDrugType];
		for (let index = 0; index < drugsToReset.length; index++) {
			const drugToCheck = { ...drugsToReset[index] };
			if (drugToCheck.drug && drugToCheck.drug.id && drugIdsToRemove.includes(drugToCheck.drug.id)) {
				drugToCheck.amount = undefined;
				drugToCheck.price = undefined;
				drugsToReset[index] = drugToCheck;
			}
		}
		this.setState({
			drugAndDrugType: drugsToReset,
			drugsToSave: [
				...this.state.drugsToSave.filter(dts => dts.drug.id && !drugIdsToRemove.includes(dts.drug.id)),
			],
		});
	};

	private setAnimalKinds(animalKinds: AnimalKind[], animalKind: Option) {
		this.setState({
			animalKinds: animalKinds,
			selectedAnimalKind: animalKind,
			drugAndDrugType: this.getDrugTypesForAnimalType(animalKinds),
			drugsToSave: [],
		});
	}

	private priceChanged = (price?: number, itemFromParent?: string) => {
		if (itemFromParent) {
			const currentDrugsToSave = [...this.state.drugsToSave];
			const drugAddedToListIndex = currentDrugsToSave.findIndex(
				d => itemFromParent && d.drug.id === itemFromParent,
			);
			const drugAndDrugTypes = [...this.state.drugAndDrugType];
			const drugAndDrugTypeIndex = drugAndDrugTypes.findIndex(d => d.id === itemFromParent);
			const copyOfDrugAndDrugTypes = { ...drugAndDrugTypes[drugAndDrugTypeIndex] };
			copyOfDrugAndDrugTypes.price = price;
			drugAndDrugTypes[drugAndDrugTypeIndex] = copyOfDrugAndDrugTypes;
			if (drugAddedToListIndex === -1 && drugAndDrugTypeIndex >= 0) {
				let adjustment = {
					type: AmountType.Adjustment,
					id: new ObjectID().toHexString(),
					price: price,
					amount: undefined,
					date: this.state.selectedDate!,
					registeredById: this.props.profileId,
				} as IAdjustment;
				currentDrugsToSave.push({ drug: drugAndDrugTypes[drugAndDrugTypeIndex].drug, adjustment });
			} else if (drugAndDrugTypeIndex >= 0) {
				const drugAndAdjustment = currentDrugsToSave[drugAddedToListIndex];
				const adjustment = { ...drugAndAdjustment.adjustment };
				adjustment.price = price;
				drugAndAdjustment.adjustment = adjustment;
				currentDrugsToSave[drugAddedToListIndex] = drugAndAdjustment;
			}
			this.setState({ drugsToSave: currentDrugsToSave, drugAndDrugType: drugAndDrugTypes });
		}
	};

	private amountChanged = (amount?: number, itemFromParent?: string) => {
		if (itemFromParent) {
			const currentDrugsToSave = [...this.state.drugsToSave];
			const drugAddedToListIndex = currentDrugsToSave.findIndex(
				d => itemFromParent && d.drug.id === itemFromParent,
			);
			const drugAndDrugTypes = [...this.state.drugAndDrugType];
			const drugAndDrugTypeIndex = drugAndDrugTypes.findIndex(d => d.id === itemFromParent);
			const copyOfDrugAndDrugTypes = { ...drugAndDrugTypes[drugAndDrugTypeIndex] };
			copyOfDrugAndDrugTypes.amount = amount;
			drugAndDrugTypes[drugAndDrugTypeIndex] = copyOfDrugAndDrugTypes;
			if (drugAddedToListIndex === -1 && drugAndDrugTypeIndex >= 0) {
				let adjustment = {
					type: AmountType.Adjustment,
					id: new ObjectID().toHexString(),
					price: undefined,
					amount: amount,
					date: this.state.selectedDate!,
					registeredById: this.props.profileId,
				} as IAdjustment;
				currentDrugsToSave.push({ drug: drugAndDrugTypes[drugAndDrugTypeIndex].drug, adjustment });
			} else if (drugAndDrugTypeIndex >= 0) {
				const drugAndAdjustment = currentDrugsToSave[drugAddedToListIndex];
				const adjustment = { ...drugAndAdjustment.adjustment };
				adjustment.amount = amount;
				drugAndAdjustment.adjustment = adjustment;
				currentDrugsToSave[drugAddedToListIndex] = drugAndAdjustment;
			}
			this.setState({ drugsToSave: currentDrugsToSave, drugAndDrugType: drugAndDrugTypes });
		}
	};

	private readonly onDateChanged = (newDate: Date) => {
		const currentDrugsToSave = [...this.state.drugsToSave];
		for (let index = 0; index < currentDrugsToSave.length; index++) {
			const drug = currentDrugsToSave[index];
			const adjustment = { ...drug.adjustment };
			adjustment.date = newDate;
			drug.adjustment = adjustment;
			currentDrugsToSave[index] = drug;
		}
		this.setState({ selectedDate: newDate, drugsToSave: currentDrugsToSave });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicine);
