import React, { useCallback, useMemo } from 'react';
import { FC } from 'react';
import { AnimalType } from 'shared/api/api';
import { SkioldFormDropdown } from '../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldDropdown } from '../skiold-components/skiold-dropdown/skiold-dropdown';
import { selectIsSkioldDigitalRecipes } from 'shared/state/ducks/recipe/selectors';
import { useSelector } from 'react-redux';
import { Option } from 'react-dropdown';
import { use } from 'i18next';

interface PropsFromParent {
	useInForm?: boolean;
	animalType: AnimalType;
	recipeId?: string;
	onChosenRecipe: (recipeId: string) => void;
}

export const RecipePicker: FC<PropsFromParent> = React.memo(({ useInForm, animalType, recipeId, onChosenRecipe }) => {
	const recipes = useSelector(selectIsSkioldDigitalRecipes);

	// Genererate recipe options by animal type
	const animalRecipeOptions = useMemo(() => {
		return recipes
			.filter(r => r.animalType === animalType)
			.map(recipe => ({
				label: recipe.name,
				value: recipe.id,
			})) as Option[];
	}, [recipes, animalType]);

	// Convert the selected recipe, into a option object
	const selectedOption = useMemo(() => {
		return animalRecipeOptions.find(option => option.value === recipeId);
	}, [recipeId]);

	const onChange = useCallback(
		(option: Option) => {
			onChosenRecipe(option.value);
		},
		[onChosenRecipe],
	);

	return useInForm ? (
		<SkioldFormDropdown items={animalRecipeOptions} selectedValue={selectedOption} onValueChanged={onChange} />
	) : (
		<SkioldDropdown items={animalRecipeOptions} selectedValue={selectedOption} onValueChanged={onChange} />
	);
});
