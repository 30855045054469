
import { createReducer } from '@reduxjs/toolkit';

import { GetEsfCloudToDeviceMessageByIds, GetEsfCloudToDeviceMessages, ResendEsfC2M } from './operations';
import { EsfCloudToDeviceMessages } from './types';
import { removeValueFromArray, upsertValueInArray } from 'shared/helpers/reducer-helpers';
import { CloudToDeviceStatusEnum } from 'shared/api/api';

const initialState: EsfCloudToDeviceMessages = {
	messages: [],
	pending: false,
};

const esfCloudToDeviceMessagesReducer = createReducer(initialState, builder => {
	builder.addCase(GetEsfCloudToDeviceMessages.fulfilled, (state, action) => {
		state.messages = action.payload ?? [];
		state.pending = false;
	});

	builder.addCase(GetEsfCloudToDeviceMessages.pending, state => {
		state.pending = true;
	});

	builder.addCase(GetEsfCloudToDeviceMessages.rejected, state => {
		state.pending = false;
	});

	builder.addCase(GetEsfCloudToDeviceMessageByIds.fulfilled, (state, action) => {
		state.messages = action.payload ?? [];

	});

	builder.addCase(GetEsfCloudToDeviceMessageByIds.pending, state => {

	});

	builder.addCase(GetEsfCloudToDeviceMessageByIds.rejected, state => {

	});

	builder.addCase(ResendEsfC2M.pending, (state, action) => {
		if (action.meta.arg.status === CloudToDeviceStatusEnum.Resend) {
			let message = state.messages.find(m => m.id === action.meta.arg.id);
			if (message) {
				message.status = CloudToDeviceStatusEnum.Resend;
				state.messages = upsertValueInArray(state.messages, message);
			}
		}
		state.pending = true;
	});

	builder.addCase(ResendEsfC2M.fulfilled, (state, action) => {
		if (action.meta.arg.status === CloudToDeviceStatusEnum.Deleted) {
			state.messages = removeValueFromArray(state.messages, action.meta.arg.id!)
		}
		state.pending = false;
	});

	builder.addCase(ResendEsfC2M.rejected, state => {
		state.pending = false;
	});
});

export default esfCloudToDeviceMessagesReducer;

