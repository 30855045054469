import { Sorting } from '@devexpress/dx-react-grid';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimalKind, AnimalType, IDrug } from 'shared/api/api';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString } from 'shared/helpers/date-helpers';
import { DeArchiveDrug } from 'shared/state/ducks/drug/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import sendFromArchive from 'shared/assets/src-assets/Icon_Arkiv_Medicin.svg';

interface PropsFromParent {
	closeModal: () => void;
	selectedAnimalKinds: AnimalKind[];
}
const defaultSorting = [{ columnName: 'type', direction: 'asc' }] as Sorting[];
export const DrugStockArchived: FC<PropsFromParent> = React.memo(({ closeModal, selectedAnimalKinds }) => {
	const dispatch = useDispatch();

	const drugs = useSelector((state: WebAppState) => state.drugs.drugs.filter(d => !d.isDeleted));
	const diagnoses = useSelector((state: WebAppState) => state.diagnose.entities);

	const drugTypes = useSelector((state: WebAppState) => state.drugType.entities);
	const language = useSelector((state: WebAppState) => state.profile.active!.language);
	const animalTypes = useSelector((state: WebAppState) =>
		state.navigation.query && state.navigation.query.type
			? (state.navigation.query.type as AnimalType)
			: AnimalType.Sow,
	);

	const columnExtensions = useMemo(
		() => [
			{
				columnName: 'Activate',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'type',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'drugType',
				width: SowListConstants.treatDefinitionDrugWidth,
			},
			{
				columnName: 'lastRegulation',
				width: SowListConstants.adjustmentType,
			},
			{
				columnName: 'drugWaste',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'drugUsage',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'drugStock',
				width: SowListConstants.animalNrWidth,
			},
		],
		[],
	);

	const findDrugTypeType = (drug: IDrug) => {
		let drugType = drugTypes.find(a => a.id === drug.drugTypeId);
		if (drugType !== undefined) {
			return localized(drugType.type!);
		} else {
			return '';
		}
	};

	const findDrugType = (drug: IDrug) => {
		let drugType = drugTypes.find(a => a.id === drug.drugTypeId);
		if (drugType !== undefined) {
			return drugType.name;
		} else {
			return '';
		}
	};
	const getCurrentStock = (drug: IDrug) => {
		return drug.total;
	};

	const getActivateCell = useCallback((drug: IDrug) => {
		const activate = async () => {
			if (await ShowConfirmAlert(localized('ConfirmDrugDeArchivation'))) {
				DeArchiveDrug(drug)(dispatch);
			}
		};
		return (
			<SkioldTouchableOpacity className="align-items-center" onPress={activate}>
				<SkioldImage
					className="align-items-center"
					width={35}
					height={35}
					imageData={sendFromArchive}
				/>
			</SkioldTouchableOpacity>
		);
	}, []);

	const columns = useMemo(
		() => [
			{
				name: 'Activate',
				title: localized('Activate'),
				getCellValue: getActivateCell,
			},
			{
				name: 'type',
				title: localized('Type'),
				getCellValue: (drug: IDrug) => findDrugTypeType(drug),
			},
			{
				name: 'drugType',
				title: localized('DrugTypes'),
				getCellValue: (drug: IDrug) => findDrugType(drug),
			},
			{
				name: 'lastRegulation',
				title: localized('lastRegulation'),
				getCellValue: (drug: IDrug) => getDateString(drug.lastAdjusted),
			},
			{
				name: 'drugWaste',
				title: localized('Waste'),
				getCellValue: (drug: IDrug) => (drug.waste ? -drug.waste : 0),
			},
			{
				name: 'drugUsage',
				title: localized('Usage'),
				getCellValue: (drug: IDrug) => (drug.usage ? -drug.usage : 0),
			},
			{
				name: 'drugStock',
				title: localized('MedicineStockStorage'),
				getCellValue: (drug: IDrug) => getCurrentStock(drug),
			},
		],
		[],
	);

	const archived = useMemo(() => {
		return drugs.filter(a => a.isArchived && a.animalKinds!.some(r => selectedAnimalKinds.indexOf(r) >= 0));
	}, [drugs, selectedAnimalKinds]);

	return (
		<PageContainer className="archived-treatment-definitions">
			<Heading text={localized('Archive')} />
			<SkioldTableGrid
				tableKey={'drugStockOverviewListTableArchived'}
				columns={columns}
				ColumnExtensions={columnExtensions}
				data={archived}
				ignoreSetCount={true}

				sortHeaderId={defaultSorting}
			/>
			<ViewWeb className="view-button-container">
				<SkioldButton className="close-button" theme="grey" title={localized('Close')} onPress={closeModal} />
			</ViewWeb>
		</PageContainer>
	);
});
