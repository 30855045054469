import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalType,
	IBuilding,
	IDiagnose,
	IMoveEvent,
	IPen,
	ISection,
	IStemAnimal,
	ITreatment,
	ITreatmentDefinition,
	ITreatmentPlan,
	IUserProfile,
} from 'shared/api/api';
import animalTreatmentList from 'shared/assets/src-assets/png/medicin_list_grey.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getDateString, setCorrectTimeStamp } from 'shared/helpers/date-helpers';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { exactStartsWithMethodGrid, setOneDecimalsAsNumber } from 'shared/helpers/general-helpers';
import {
	getBuildingNameByPenId,
	getPenNameByPenId,
	getSectionNameByPenId,
	getStemAnimalPenHashMaps,
	getSectionNameById,
	getBuildingNameBySectionId,
} from 'shared/helpers/location-helper';
import {
	memoizeHashmapAllLocation,
	memoizeHashmapAllMoveEventsToStemAnimalId,
	memoizeHashmapDiagnose,
	memoizeHashmapDrugTypes,
	memoizeHashmapProfile,
	memoizeHashmapStemAnimal,
	memoizeHashmapTreatmentDefinitions,
	memoizeHashmapTreatmentPlan,
	memoizeHashmapTreatmentPlanToStemAnimalId,
	memoizeHashmapTreatmentsToTreatmentPlanId,
} from 'shared/helpers/memoize-getters/memoize-getters';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { GetSyncData as GetMoveEventSyncData } from 'shared/state/ducks/move-events/operations';
import { GetSyncData as ProfileGetSyncData } from 'shared/state/ducks/profile/operations';
import { GetDeparturedAnimals, GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { GetTreatmentPlansForLog } from 'shared/state/ducks/treatment-plan/operations';
import { GetCompletedTreatmentsInPeriod } from 'shared/state/ducks/treatment/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { getDrugAmountCell } from 'web/view/components/treatments/treatment-log/drug-amount-text';
import { DrugMethodText } from 'web/view/components/treatments/treatment-log/drug-method-text';
import { getDrugNameCell } from 'web/view/components/treatments/treatment-log/drug-name-text';
import EditTreatment from 'web/view/components/treatments/treatment-plan/edit-treatment';
import {
	SubtreatmentTableModel,
	TreatmentTableModel,
} from 'web/view/components/treatments/treatment-plan/treatment-table-model';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { getStemAnimalNumberColor } from 'web/view/components/work-lists/work-list-helper';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import TreatmentLogAnimalTypes from './treatment-log-animal-types';
import {
	animalNumberTreatmentLogColumnExtension,
	checkAnimalForYoungFemale,
	defaultDrugAmountTreatmentLogColumnExtension,
	defaultDrugTreatmentLogColumnExtensions,
	generateDefaultTreatmentLogTableModel,
	generateTreatmentLogColumnData,
	handleMissingPropsPigTreatments,
	handleMissingPropsSowTreatments,
	pigAmountAndWeightTreatmentLogColumnExtension,
	pigletAmountTreatmentLogColumnExtension,
} from './treatment-log-helper';
import './treatment-log.scss';

const treatmentLogMapStateToProps = (state: WebAppState) => {
	return {
		treatmentPlans: memoizeHashmapTreatmentPlan(state.treatmentPlans.treatmentPlansForLog),
		treatments: state.treatments.treatmentsForLog,
		diagnoses: memoizeHashmapDiagnose(state.diagnose.entities),
		treatmentDefinitions: memoizeHashmapTreatmentDefinitions(state.treatmentDefinitions.entities),
		drugTypes: memoizeHashmapDrugTypes(state.drugType.entities),
		language: state.profile.active!.language,
		siteId: state.profile.active!.siteId,
		profiles: memoizeHashmapProfile(state.profile.entities),
		memorizesLocations: memoizeHashmapAllLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		moveEvents: memoizeHashmapAllMoveEventsToStemAnimalId(state.moveEvents.entities),
		allAnimals: memoizeHashmapStemAnimal(
			state.stemAnimals.entities,
			state.stemAnimals.departuredAnimals as IStemAnimal[],
		),
		fetchingTreatmentPlans: state.treatmentPlans.fetchingTreatmentPlansForLog,
		fetchingTreatments: state.treatments.fetchingTreatmentsForLog,
		treatmentPlanMappedToStemAnimalId: memoizeHashmapTreatmentPlanToStemAnimalId(
			state.treatmentPlans.treatmentPlansForLog,
		),
		treatmentsMappedToTreatmentPlanId: memoizeHashmapTreatmentsToTreatmentPlanId(state.treatments.treatmentsForLog),
		animalTypes:
			state.navigation.query && state.navigation.query.type
				? (state.navigation.query.type as AnimalType)
				: AnimalType.Sow,
	};
};

const treatmentLogMapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
	treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
	getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
	getTreatmentPlans: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetTreatmentPlansForLog(siteId, startInterval, endInterval)(dispatch),
	getTreatments: (siteId: string, startInterval: Date, endInterval: Date) =>
		GetCompletedTreatmentsInPeriod(siteId, startInterval, endInterval)(dispatch),
	getMoveEventSyncData: () => GetMoveEventSyncData()(dispatch),
	getLocations: () => GetLocations()(dispatch),
	getProfiles: () => ProfileGetSyncData()(dispatch),
	getDeparturedAnimals: () => GetDeparturedAnimals()(dispatch),
});

// tmp dates are used in the datepicker as they shouldnt come into effect before the fetch button is clicked
interface TreatmentLogState {
	modalIsOpen: boolean;
	startDate: Date;
	endDate: Date;
	fetchingData: boolean; // Made so the spinner does not spin, when fetching data is used inside modal
	selectedDrugType: Option;
	drugTypeOptions: Option[];
	treatmentTableModels: TreatmentTableModel[];
	treatmentToEdit?: ITreatment;
	treatmentLogAnimalTypeOpen?: boolean;
	treatmentLogAnimalTypeData?: TreatmentTableModel[];
	columns: any[];
	columnExte: any[];
	isSow?: boolean;
	selectedAnimalType: Option;
	animalTypeOptions: Option[];
	showChangeAnimalTypeDropdown?: boolean;
}

type TreatmentLogProps = ReturnType<typeof treatmentLogMapStateToProps> &
	ReturnType<typeof treatmentLogMapDispatchToProps>;
class TreatmentLog extends React.PureComponent<TreatmentLogProps, TreatmentLogState> {
	private generateData = memoize(
		(
			selectedDrugType,
			treatments,
			treatmentDefinitions,
			treatmentPlans,
			diagnoses,
			allAnimals,
			profiles,
			treatmentPlanMappedToStemAnimalId,
			treatmentsMappedToTreatmentPlanId,
			moveEvents,
			startDate,
			endDate,
			selectedAnimalType,
			propsAnimalType,
		) => {
			return this.getTableModels(
				selectedDrugType,
				treatments,
				treatmentDefinitions,
				treatmentPlans,
				diagnoses,
				allAnimals,
				profiles,
				treatmentPlanMappedToStemAnimalId,
				treatmentsMappedToTreatmentPlanId,
				moveEvents,
				startDate,
				endDate,
				selectedAnimalType,
				propsAnimalType,
			);
		},
	);
	private readonly getDrugAdministrationCell = (t: TreatmentTableModel) => (
		<ViewWeb>
			<DrugMethodText treatment={t} />
		</ViewWeb>
	);
	private readonly getTreatmentNumberCell = (t: TreatmentTableModel) =>
		t.isRegistration
			? ''
			: (t.treatmentNumber ? t.treatmentNumber : '')
					.toString()
					.concat('/')
					.concat(t.totalTreatmentNumber ? t.totalTreatmentNumber.toString() : '');

	private readonly getRetentionDateCell = (t: TreatmentTableModel) => t.retentionTime;

	private readonly getStableCell = (t: TreatmentTableModel) => {
		if (t.stemAnimal) {
			const foundStable = this.getStable(t.stemAnimal);
			if (foundStable) {
				return foundStable.name;
			}
		} else if (t.penId) {
			return getBuildingNameByPenId(t.penId, this.props.memorizesLocations);
		} else if (t.sectionId) {
			return getBuildingNameBySectionId(t.sectionId, this.props.memorizesLocations);
		}
		return '';
	};

	private readonly getSectionCell = (t: TreatmentTableModel) => {
		if (t.stemAnimal) {
			const foundStable = this.getStable(t.stemAnimal);
			const foundSection = this.getSection(t.stemAnimal);
			if (foundSection && foundStable && foundStable.useSections) {
				return foundSection.name;
			}
		} else if (t.penId) {
			return getSectionNameByPenId(t.penId, this.props.memorizesLocations);
		} else if (t.sectionId) {
			return getSectionNameById(t.sectionId, this.props.memorizesLocations);
		}
		return '';
	};

	private readonly getPenCell = (t: TreatmentTableModel) => {
		if (t.stemAnimal) {
			const section = this.getSection(t.stemAnimal);
			const foundPen = this.getPen(t.stemAnimal);
			if (foundPen && section && section.usePens) {
				return foundPen.name;
			}
		} else if (t.penId) {
			return getPenNameByPenId(t.penId, this.props.memorizesLocations);
		}
		return '';
	};

	private readonly getUserCell = (t: TreatmentTableModel) => (t.executedByUser ? t.executedByUser : '');

	private readonly getEditCell = (row: TreatmentTableModel) => (
		<SkioldTouchableOpacity itemFromParent={row} onPress={this.edit}>
			<SkioldImage width="30" height="30" imageData={PenIcon} />
		</SkioldTouchableOpacity>
	);

	private readonly getSeverityCell = (row: TreatmentTableModel) => {
		return row?.severity;
	};

	constructor(props: TreatmentLogProps) {
		super(props);

		let endDate = setCorrectTimeStamp(new Date(), false, true)!;

		let startDate = setCorrectTimeStamp(new Date(), false, false)!;

		startDate.setMonth(startDate.getMonth() - 1);

		const selectedAnimalType = this.props.animalTypes;
		const isSow = selectedAnimalType === AnimalType.Sow;
		let selectedAnimalTypeOption = { value: AnimalType.Sow, label: localized('SowBoarGlit') };

		if (!isSow) {
			selectedAnimalTypeOption = { value: selectedAnimalType, label: localized(selectedAnimalType) };
		}
		this.state = {
			modalIsOpen: false,
			fetchingData: false,
			endDate,
			selectedDrugType: { value: 'Medicine', label: localized('Medicine') },
			drugTypeOptions: [
				{ value: 'Medicine', label: localized('Medicine') },
				{ value: 'Vaccine', label: localized('vaccine') },
			],
			animalTypeOptions: [
				{ value: AnimalType.Sow, label: localized('SowBoarGlit') },
				{ value: AnimalType.YoungFemale, label: localized(AnimalType.YoungFemale) },
				{ value: AnimalType.Piglet, label: localized(AnimalType.Piglet) },
			],
			selectedAnimalType: selectedAnimalTypeOption,
			startDate,
			treatmentTableModels: [],
			treatmentLogAnimalTypeData: undefined,
			treatmentToEdit: undefined,

			isSow,
			columns: [],
			columnExte: [],
			showChangeAnimalTypeDropdown: isSow,
		};
		this.props.getDeparturedAnimals();
		this.props.getDiagnoses();
		this.props.treatmentDefinitionGetSyncData();
		this.props.getDrugTypes();
		this.props.getMoveEventSyncData();
		this.props.getLocations();
		this.props.stemAnimalGetSyncData();
		this.props.getProfiles();
		this.props.getTreatmentPlans(this.props.siteId!, startDate, endDate);
		this.props.getTreatments(this.props.siteId!, startDate, endDate);
	}

	public componentDidUpdate(prevProps: TreatmentLogProps) {
		if (!isEqual(prevProps.animalTypes, this.props.animalTypes)) {
			const state = { ...this.state };

			if (this.props.animalTypes) {
				const selectedAnimalType = this.props.animalTypes;
				const isSow = selectedAnimalType === AnimalType.Sow;
				if (isSow) {
					state.selectedAnimalType = { value: AnimalType.Sow, label: localized('SowBoarGlit') };
				} else {
					state.selectedAnimalType = { value: selectedAnimalType, label: localized(selectedAnimalType) };
				}
				this.setState({ ...state, isSow, showChangeAnimalTypeDropdown: isSow });
			}
		}
	}

	public render() {
		const data = this.generateData(
			this.state.selectedDrugType,
			this.props.treatments,
			this.props.treatmentDefinitions,
			this.props.treatmentPlans,
			this.props.diagnoses,
			this.props.allAnimals,
			this.props.profiles,
			this.props.treatmentPlanMappedToStemAnimalId,
			this.props.treatmentsMappedToTreatmentPlanId,
			this.props.moveEvents,
			this.state.startDate,
			this.state.endDate,
			this.state.selectedAnimalType.value as AnimalType,
			this.props.animalTypes,
		);
		return (
			<PageContainer className="treatment-log">
				<Heading text={localized('CHOOSELIST')} />
				<ViewWeb className="viewContainerStyle">
					<ViewWeb className="ViewStyle">
						<SkioldFetch
							showSpinner={
								this.state.fetchingData &&
								(this.props.fetchingTreatmentPlans || this.props.fetchingTreatments)
							}
							fromDate={this.state.startDate}
							toDate={this.state.endDate}
							fetchData={this.fetchTreatmentPlans}
						/>

						<SkioldTouchableOpacity className="showIconViewStyle" onPress={this.openTreatmentLogAnimalType}>
							<ViewWeb className="alignButtonRight">
								<SkioldImage width="50" height="50" imageData={animalTreatmentList} />
								<TextWeb className="textStyle">{localized('Usage')}</TextWeb>
							</ViewWeb>
						</SkioldTouchableOpacity>
					</ViewWeb>
					<ViewWeb className="alignDropdownCenter">
						<SkioldDropdown
							onValueChanged={this.drugTypeChanged}
							selectedValue={this.state.selectedDrugType}
							itemFromParent={'selectedDrugType'}
							items={this.state.drugTypeOptions}
							containerClassName="drugType-dropdown"
						/>
					</ViewWeb>
					{this.state.showChangeAnimalTypeDropdown && (
						<ViewWeb className="alignDropdownCenter animalType">
							<SkioldDropdown
								onValueChanged={this.optionChanged}
								selectedValue={this.state.selectedAnimalType}
								itemFromParent={'selectedAnimalType'}
								items={this.state.animalTypeOptions}
								containerClassName="animalType-dropdown"
							/>
						</ViewWeb>
					)}
					<ViewWeb className="behindDropdown">
						<SkioldTableGrid
							tableKey={
								this.state.isSow
									? this.state.selectedAnimalType.value === AnimalType.YoungFemale
										? 'treatmentLogListTableSowYoungFemale2'
										: 'treatmentLogListTableSow2'
									: 'treatmentLogListTablePig2'
							}
							columns={this.generateColumns(this.state.isSow, this.state.selectedAnimalType)}
							ColumnExtensions={this.generateColumnsExte(this.state.isSow, this.state.selectedAnimalType)}
							data={data}
						/>
					</ViewWeb>
				</ViewWeb>

				<SkioldModal padding="0" close={this.closeModal} isOpen={this.state.modalIsOpen}>
					{this.state.treatmentToEdit && (
						<EditTreatment
							close={this.closeModal}
							isOpen={this.state.modalIsOpen}
							treatment={this.state.treatmentToEdit!}
						/>
					)}
				</SkioldModal>
				<SkioldModal padding="0" close={this.closeModal} isOpen={this.state.treatmentLogAnimalTypeOpen}>
					{this.state.treatmentLogAnimalTypeOpen && (
						<TreatmentLogAnimalTypes
							close={this.closeModal}
							tableModels={
								this.state.treatmentLogAnimalTypeData ? this.state.treatmentLogAnimalTypeData : data
							}
							generateTableModelsForSummary={this.generateTableModelsForSummary}
							endDate={this.state.endDate}
							startDate={this.state.startDate}
							selectedOption={this.state.selectedAnimalType}
						/>
					)}
				</SkioldModal>
			</PageContainer>
		);
	}

	private generateColumnsPig() {
		let columns: any[] = [
			generateTreatmentLogColumnData('date', localized('Date'), this.getExecuteDateCell, NaturalSortDates),
		];
		columns = columns.concat(this.pigAmountAndWeightTreatmentLogColumn());
		columns = columns.concat(this.defaultDrugTreatmentLogColumn());

		columns = columns.concat(this.defaultDrugAmountTreatmentLogColumn());
		return columns;
	}

	private generateColumns = memoize((isSow?: boolean, selectedAniamlTypeOption?: Option) => {
		return isSow
			? this.state.selectedAnimalType.value === AnimalType.YoungFemale
				? this.generateColumnsSowYoungFemale()
				: this.generateColumnsSow()
			: this.generateColumnsPig();
	});

	private generateColumnsExte = memoize((isSow?: boolean, selectedAniamlTypeOption?: Option) => {
		return isSow
			? this.state.selectedAnimalType.value === AnimalType.YoungFemale
				? this.generateColumnsExtensionsSowYoungFemale()
				: this.generateColumnsExtensionsSow()
			: this.generateColumnsExtensionsPig();
	});

	private generateTableModelsForSummary = (
		treatments: ITreatment[],
		treatmentPlans: ITreatmentPlan[],
		startDate: Date,
		endDate: Date,
	) => {
		const treatmentsToUse = treatments.filter(
			///memorize
			t => t.executedDate && t.executedDate >= startDate && t.executedDate <= endDate,
		);
		return this.getTableModels(
			this.state.selectedDrugType,
			treatmentsToUse,
			this.props.treatmentDefinitions,
			this.props.treatmentPlans,
			this.props.diagnoses,
			this.props.allAnimals,
			this.props.profiles,
			this.props.treatmentPlanMappedToStemAnimalId,
			this.props.treatmentsMappedToTreatmentPlanId,
			this.props.moveEvents,
			this.state.startDate,
			this.state.endDate,
			this.state.selectedAnimalType.value as AnimalType,
			this.props.animalTypes,
		);
	};

	private openTreatmentLogAnimalType = () => {
		this.setState({ treatmentLogAnimalTypeOpen: true });
	};

	public generateColumnsExtensionsSow() {
		let columnExtensionsSow: any[] = animalNumberTreatmentLogColumnExtension;
		columnExtensionsSow = columnExtensionsSow.concat(defaultDrugTreatmentLogColumnExtensions);
		if (this.state.selectedAnimalType.value === AnimalType.Piglet) {
			columnExtensionsSow = columnExtensionsSow.concat(pigletAmountTreatmentLogColumnExtension);
		}
		columnExtensionsSow = columnExtensionsSow.concat(defaultDrugAmountTreatmentLogColumnExtension);
		return columnExtensionsSow;
	}

	public generateColumnsExtensionsSowYoungFemale() {
		let columnExtensionsSow: any[] = animalNumberTreatmentLogColumnExtension;
		columnExtensionsSow = columnExtensionsSow.concat(defaultDrugTreatmentLogColumnExtensions);
		columnExtensionsSow = columnExtensionsSow.concat(pigAmountAndWeightTreatmentLogColumnExtension);
		columnExtensionsSow = columnExtensionsSow.concat(defaultDrugAmountTreatmentLogColumnExtension);
		return columnExtensionsSow;
	}

	public generateColumnsExtensionsPig() {
		let columnExtensionsSow: any[] = defaultDrugTreatmentLogColumnExtensions;
		columnExtensionsSow = columnExtensionsSow.concat(pigAmountAndWeightTreatmentLogColumnExtension);
		columnExtensionsSow = columnExtensionsSow.concat(defaultDrugAmountTreatmentLogColumnExtension);

		return columnExtensionsSow;
	}

	private fetchTreatmentPlans = async (fromDate: Date, toDate: Date) => {
		this.setState({ startDate: fromDate, endDate: toDate, fetchingData: true });
		await this.props.getTreatmentPlans(this.props.siteId!, fromDate, toDate);
		await this.props.getTreatments(this.props.siteId!, fromDate, toDate);
		this.setState({ fetchingData: false });
	};

	private optionChanged = (option: Option, itemFromParent: string) => {
		const state = { ...this.state };
		state[itemFromParent] = option;
		this.setState(state);
	};
	private isInInterval(value: Date | undefined, startDateInclusive: Date, endDateInclusive: Date) {
		if (value === undefined || value === null) {
			return false;
		}
		if (value! >= startDateInclusive && value! <= endDateInclusive) {
			return true;
		} else {
			return false;
		}
	}

	private getTableModels(
		selectedDrugType: Option,
		treatments: ITreatment[],
		treatmentDefinitions: { [key: string]: ITreatmentDefinition },
		treatmentPlans: { [key: string]: ITreatmentPlan },
		diagnoses: { [key: string]: IDiagnose },
		allAnimals: { [key: string]: IStemAnimal },
		profiles: { [key: string]: IUserProfile },
		treatmentPlanMappedToStemAnimalId: { [stemAnimalId: string]: ITreatmentPlan[] },
		treatmentsMappedToTreatmentPlanId: { [treatmentPlanId: string]: ITreatment[] },
		moveEvents: { [key: string]: IMoveEvent[] },
		startDate: Date,
		endDate: Date,
		selectedAnimalType: AnimalType,
		propsAnimalType: AnimalType,
	) {
		const treatmentTableModels: TreatmentTableModel[] = [];
		treatments.forEach(treatment => {
			if (treatment.treatmentPlanId === '666c3dc86f2167c8bab29c3b') {
				debugger;
			}
			if (
				treatment.treatmentPlanId &&
				treatment.executedDate &&
				this.isInInterval(treatment.executedDate, startDate, endDate)
			) {
				const treatmentPlan = treatmentPlans[treatment.treatmentPlanId];
				// Each get method handles getting undefined as a param, if the one preceding it didn't find anything
				// to keep this part of the logic cleaner
				if (treatmentPlan && treatmentPlan.treatmentDefinitionId) {
					const treatmentDefinition = treatmentDefinitions[treatmentPlan.treatmentDefinitionId];
					if (
						treatmentDefinition &&
						treatmentDefinition.diagnoseId &&
						treatmentDefinition.animalKinds &&
						treatmentDefinition.animalKinds.find(ak => ak.toString() === selectedAnimalType)
					) {
						const diagnose = diagnoses[treatmentDefinition.diagnoseId];
						const subtreatments = this.getSubTreatmentTableModels(treatmentDefinition);
						if (diagnose) {
							if (diagnose.isVaccination && selectedDrugType.value === 'Medicine') {
								return;
							} else if (!diagnose.isVaccination && selectedDrugType.value !== 'Medicine') {
								return;
							}
						}

						// TODO: Should properly handle animal kinds
						if (selectedAnimalType !== AnimalType.Piglet) {
							if (
								checkAnimalForYoungFemale(
									treatment,
									propsAnimalType,
									selectedAnimalType,
									treatmentPlan,
								) ||
								(treatment.animalType && treatment.animalType !== selectedAnimalType) ||
								treatment.pigletAmount
							) {
								return;
							}
						}
						if (
							selectedAnimalType === AnimalType.Piglet &&
							(treatment.animalType !== AnimalType.Sow || !treatment.pigletAmount)
						) {
							return;
						}

						const stemAnimal = treatmentPlan.stemAnimalId
							? allAnimals[treatmentPlan.stemAnimalId]
							: undefined;
						const profile =
							profiles && treatment.executedByProfileId
								? profiles[treatment.executedByProfileId]
								: undefined;
						let model = generateDefaultTreatmentLogTableModel(
							treatment,
							profile,
							treatmentPlan,
							diagnose,
							subtreatments,
							treatmentDefinition,
						);
						if (treatmentPlan && !treatmentPlan.stemAnimalId) {
							handleMissingPropsPigTreatments(model, treatmentPlan, treatment, treatmentDefinitions);
						} else {
							handleMissingPropsSowTreatments(
								model,
								treatment,
								stemAnimal,
								treatmentPlan,
								moveEvents,
								treatmentDefinitions,
								treatmentPlanMappedToStemAnimalId,
								treatmentsMappedToTreatmentPlanId,
								selectedAnimalType,
							);
						}
						treatmentTableModels.push(model);
					}
				}
			}
		});
		return treatmentTableModels;
	}

	public generateColumnsSowYoungFemale() {
		let columnsSow: any[] = this.animalNumberTreatmentLogColumn();
		columnsSow.push(
			generateTreatmentLogColumnData('date', localized('Date'), this.getExecuteDateCell, NaturalSortDates),
		);
		columnsSow = columnsSow.concat(this.pigAmountAndWeightTreatmentLogColumn());
		columnsSow = columnsSow.concat(this.defaultDrugTreatmentLogColumn());

		columnsSow = columnsSow.concat(this.defaultDrugAmountTreatmentLogColumn());
		return columnsSow;
	}

	private generateColumnsSow() {
		let columnsSow: any[] = this.animalNumberTreatmentLogColumn();
		columnsSow.push(
			generateTreatmentLogColumnData('date', localized('Date'), this.getExecuteDateCell, NaturalSortDates),
		);
		if (this.state.selectedAnimalType.value === AnimalType.Piglet) {
			columnsSow = columnsSow.concat(this.pigletAmountTreatmentLogColumn());
		}

		columnsSow = columnsSow.concat(this.defaultDrugTreatmentLogColumn());

		columnsSow = columnsSow.concat(this.defaultDrugAmountTreatmentLogColumn());
		return columnsSow;
	}
	private drugTypeChanged = (option: Option) => {
		this.setState({ selectedDrugType: option });
	};
	private closeModal = () => {
		this.setState({ modalIsOpen: false, treatmentToEdit: undefined, treatmentLogAnimalTypeOpen: false });
	};

	private getSubTreatmentTableModels(treatmentDefinition: ITreatmentDefinition | undefined) {
		if (!treatmentDefinition) {
			return undefined;
		}
		const subTreatmentModels: SubtreatmentTableModel[] = [];
		if (treatmentDefinition.subTreatments) {
			treatmentDefinition.subTreatments.forEach(subTreatment => {
				if (subTreatment.drugTypeId) {
					const subTreatmentDrugType = this.props.drugTypes[subTreatment.drugTypeId];
					const drugAmount = subTreatment.drugAmount!;
					const treatmentType = subTreatment.treatmentType!;

					subTreatmentModels.push(
						new SubtreatmentTableModel(subTreatmentDrugType!, drugAmount, treatmentType),
					);
				}
			});
		}

		// Undefined has been filtered out, typecast should be safe
		return subTreatmentModels;
	}

	private animalNumberTreatmentLogColumn = () => [
		generateTreatmentLogColumnData(
			'animalNumber',
			localized('animalNumber'),
			this.getStemAnimalNumberCell,
			undefined,
			exactStartsWithMethodGrid,
			getStemAnimalNumberColor,
			undefined,
			undefined,
			'stemAnimal',
		),
	];
	private defaultDrugTreatmentLogColumn = () => [
		generateTreatmentLogColumnData(
			'diagnose',
			localized('Diagnose'),
			this.getDiagnoseCell,
			undefined,
			undefined,
			'left-align-content',
		),
		generateTreatmentLogColumnData(
			'drug',
			localized('drug'),
			getDrugNameCell,
			undefined,
			undefined,
			'left-align-content',
			false,
			false,
		),
	];
	private pigletAmountTreatmentLogColumn = () => [
		generateTreatmentLogColumnData('pigletAmount', localized('AmountOfPigletsShort'), this.getPigletAmountCell),
	];

	private pigAmountAndWeightTreatmentLogColumn = () => [
		generateTreatmentLogColumnData('pigAmount', localized('Count'), this.getPigAmountCell),
		generateTreatmentLogColumnData('pigWeight', localized('Weight'), this.getPigWeightCell),
	];
	private defaultDrugAmountTreatmentLogColumn = () => [
		generateTreatmentLogColumnData(
			'drugAmount',
			localized('DrugAmountShort'),
			getDrugAmountCell,
			undefined,
			undefined,
			undefined,
			false,
			false,
		),
		generateTreatmentLogColumnData(
			'drugAdministration',
			localized('method'),
			this.getDrugAdministrationCell,
			undefined,
			undefined,
			undefined,
			false,
			false,
		),
		generateTreatmentLogColumnData('severity', localized('severity'), this.getSeverityCell),
		generateTreatmentLogColumnData(
			'treatmentNumber',
			localized('Treatment'),
			this.getTreatmentNumberCell,
			undefined,
			undefined,
			undefined,
			false,
			false,
		),
		generateTreatmentLogColumnData(
			'retentionDate',
			localized('slaughterRetention'),
			this.getRetentionDateCell,
			NaturalSortDates,
		),
		generateTreatmentLogColumnData('building', localized('building'), this.getStableCell),
		generateTreatmentLogColumnData('section', localized('section'), this.getSectionCell),
		generateTreatmentLogColumnData('pen', localized('pen'), this.getPenCell),
		generateTreatmentLogColumnData('user', localized('User'), this.getUserCell),
		generateTreatmentLogColumnData('edit', ' ', this.getEditCell),
	];

	private readonly getStemAnimalNumberCell = (t: TreatmentTableModel) =>
		t.stemAnimal ? t.stemAnimal.animalNumber : '';

	private readonly getExecuteDateCell = (t: TreatmentTableModel) => getDateString(t.executedDate);

	private readonly getPigletAmountCell = (t: TreatmentTableModel) =>
		t.pigCount && t.pigCount >= 1 ? t.pigCount : '';

	private readonly getPigAmountCell = (t: TreatmentTableModel) => (t.pigAmount && t.pigAmount > 0 ? t.pigAmount : '');
	private getPen(stemAnimal: IStemAnimal): IPen | undefined {
		if (stemAnimal) {
			const foundPen = getStemAnimalPenHashMaps(
				stemAnimal,
				this.props.moveEvents,
				this.props.memorizesLocations as { [key: string]: IPen },
			);
			if (foundPen) {
				return foundPen;
			}
		}
		return undefined;
	}

	private readonly getPigWeightCell = (t: TreatmentTableModel) =>
		t.pigWeight && t.pigAmount && t.pigAmount > 0 && t.pigWeight > 0
			? setOneDecimalsAsNumber(t.pigWeight / t.pigAmount)
			: '';
	private getSection(stemAnimal: IStemAnimal): ISection | undefined {
		if (stemAnimal) {
			const foundPen = this.getPen(stemAnimal);
			if (foundPen && foundPen.sectionId) {
				return this.props.memorizesLocations[foundPen.sectionId] as ISection;
			}
		}
		return undefined;
	}

	private readonly getDiagnoseCell = (t: TreatmentTableModel) =>
		getDiagnoseName(t.diagnose, this.props.language, t.isRegistration);
	private getStable(stemAnimal: IStemAnimal): IBuilding | undefined {
		if (stemAnimal) {
			const foundSection = this.getSection(stemAnimal);
			if (foundSection && foundSection.buildingId) {
				return this.props.memorizesLocations[foundSection.buildingId] as IBuilding;
			}
		}
		return undefined;
	}

	private getStemAnimalNumberColor = (tableItem: TreatmentTableModel) => {
		return tableItem && tableItem && tableItem.stemAnimal
			? getWebAnimalColorStyleTable(tableItem.stemAnimal.id)
			: '';
	};

	private edit = (treatmentTableModel: TreatmentTableModel) => {
		const treatment = this.props.treatments.find(t => t.id === treatmentTableModel.treatmentId);
		this.setState({ modalIsOpen: true, treatmentToEdit: treatment! });
	};
}

export default connect(treatmentLogMapStateToProps, treatmentLogMapDispatchToProps)(TreatmentLog);
