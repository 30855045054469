import { addDays } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
	DashboardTableModel,
	DashboardTableModelItem,
	IDashboardSettings,
	IDashboardTableModel,
	IMatingBatch,
	LocationType,
} from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { RefType } from 'shared/helpers/ref-type';
import { localized } from 'shared/state/i18n/i18n';
import styled from 'styled-components';
import { WebAppState } from 'web/state/store.web';
import SkioldTableGrid, {
	SkioldTableGrid as SkioldTableRef,
} from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { TextWeb } from 'web/web-helpers/styled-text-components';
import { SkioldModal } from '../skiold-components/skiold-modal/skiold-modal';
import { SkioldTouchableOpacity } from '../skiold-components/skiold-touchable-opacity';
import AnimalsToFarrowingsDashboardTable from './animals-to-farrowing-dashboard-table/animals-to-farrowing-dashboard-table';
import NeedWeaningsDashboardTable from './need-weaning-dashboard-table/need-weaning-dashboard-table';
import TotalFarrowingsDashboardTable from './total-farrowings-dashboard-table/total-farrowings-dashboard-table';
import TotalMatingsDashboardTable, {
	TotalMatingsModalMode,
} from './total-matings-dashboard-table/total-matings-dashboard-table';
import TotalWeaningsDashboardTable from './total-weanings-dashboard-table/total-weanings-dashboard-table';
import '../../pages/dashboard-overview/dashboard-overview.scss';
import { SowFeedUsageGraph } from './sow-feed-usage-graph';
import { SkioldImage } from '../utils/svg/skiold-image';
import feedLog from 'shared/assets/src-assets/png/FoderLog_DarkGrey.png';
import './dashboard-overview-table.scss';
import { DeadPigletsDasbboardTable } from './dead-piglets-dasbboard-table/dead-piglets-dasbboard-table';
import { memoizeGetDistriwinProcessEquipmentData } from 'shared/helpers/memoize-getters/memoize-getters';
import { sectionTypeUseDistriwin } from 'shared/helpers/location-helper';

const OuterDiv = styled.div`
	margin: 10px 0 0 0;
`;

interface PropsFromParent {
	showGoalOverUnderYear: boolean;
	dontShowYearColumns?: boolean;
	dashboardTableItems: { [key: string]: DashboardTableModel[] };
	loading: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		dashboardSetting: state.dashboardSettings.entity,
		matingBatches: state.matingBatches.entities,
		isSyncing: state.offline.isSyncing,
		matingBatchSetting: state.matingBatchSetting.currentMatingBatchSetting,
		sections: state.locations.sections,
		processEquipmentData: memoizeGetDistriwinProcessEquipmentData(state.processEquipmentData.entities),
	};
};

export interface State {
	loading: boolean;
	modalOpen: boolean;
	modalSelected?: string;
	batchId?: string;
	columns: any[];
	columnExte: any[];
	count?: string;
	graphModalOpen: boolean;
	locationTypes?: LocationType[];
}

type Props = ReturnType<typeof mapStateToProps> & PropsFromParent;

type DashboardTableModelWithKey = { tableModel: IDashboardTableModel; key: string };

export class DashBoardOverviewTable extends React.PureComponent<Props, State> {
	public SkioldTableRef: SkioldTableRef | undefined;
	private KeysWithModals: string[] = [
		'TotalMatings',
		'TotalDepartures',
		'AnimalsToFarrowing',
		'TotalFarrowings',
		'NeedFarrowing',
		'TotalWeanings',
		'NeedWeaning',
		'DeadPiglets',
	];

	private KeysWithGoalValues: string[] = [
		'TotalMatings',
		'YoungAnimalMatings',
		'ReturnSows',
		'TotalDepartures',
		'WeaningToFirstMating',
		'AvgWasteDays',
		'AnimalsToFarrowing',
		'TotalFarrowings',
		'TotalLiveBorn',
		'AvgAlive',
		'AvgDead',
		'StillbornToAliveRate',
		'FarrowingRate',
		'TotalWeanings',
		'AvgWeanedPerLitter',
		'AvgWeight',
		'TotalWeanedPiglets',
		'AvgLivebornFirstParity',
	];

	private ReversePositiveNegative = [
		'ReturnSows',
		'TotalDepartures',
		'WeaningToFirstMating',
		'AvgWasteDays',
		'AvgDead',
		'StillbornToAliveRate',
		'ThereOfFirstParity',
		'NursingSows',
	];

	private getPlanHeader = (
		<div className={'dashboardOverviewTableSettingContainer headerWithRows all-capitalized borderLeft'}>
			{localized('Plan')}
		</div>
	);

	private groupedColumns = (dashboardSetting?: IDashboardSettings) => {
		const { dateFrom, dateTo } = this.HandleDateFromAndDateTo(dashboardSetting);
		return [
			{
				title: localized('year') + ': ' + getDateString(dateFrom) + ' - ' + getDateString(dateTo),
				colSpan: 3,
				children: [{ columnName: 'achievedYear' }, { columnName: 'overGoalYear' }, { columnName: 'goalYear' }],
			},
		];
	};

	private readonly getGoalYearHeader = (
		<div className={'dashboardOverviewTableSettingContainer overview all-capitalized subheader-lineheight'}>
			{localized('goalYear')}
		</div>
	);

	private readonly getOverGoalYearHeader = (
		<div
			className={
				'dashboardOverviewTableSettingContainer overview all-capitalized borderLeft subheader-lineheight'
			}
		>
			{localized('overGoalYear')}
		</div>
	);

	private readonly getAchievedYearHeader = (
		<div
			className={
				'dashboardOverviewTableSettingContainer overview all-capitalized borderLeft subheader-lineheight'
			}
		>
			{localized('achievedYear').split(' ').join('\n')}
		</div>
	);

	private readonly getAchievedYear = (d: IDashboardTableModel) => (d.achievedYear ? d.achievedYear : '');

	private readonly getOverGoalYear = (d: IDashboardTableModel) =>
		!d.name ||
		!this.KeysWithGoalValues.includes(d.name) ||
		d.overGoalYear === undefined ||
		!this.props.showGoalOverUnderYear ? (
			''
		) : (
			<TextWeb className={this.GetOverGoalYearColor(d)}>{Math.abs(Math.round(d.overGoalYear * 10) / 10)}</TextWeb>
		);

	private readonly getGoalYear = (d: IDashboardTableModel) =>
		d.name === 'FarrowingMatingBatch' || d.name === 'WeaningMatingBatch' || !d.goalYear
			? ''
			: Math.round(d.goalYear * 10) / 10;

	private readonly getPlan = (d: IDashboardTableModel) =>
		d.planValue && d.planValue.pcs && d.planValue.pcs !== 0 ? Math.round(d.planValue.pcs * 10) / 10 : '';

	constructor(props: Props) {
		super(props);
		this.state = {
			columns: this.generateColumns(),
			loading: false,
			modalOpen: false,
			modalSelected: undefined,
			batchId: undefined,
			columnExte: this.generateColumnsExtensions(),
			graphModalOpen: false,
		};
	}

	private HandleDateFromAndDateTo(dashboardSetting: IDashboardSettings | undefined) {
		let dateFrom = new Date(),
			dateTo = new Date();
		if (!dashboardSetting || (dashboardSetting && dashboardSetting.rollingYear)) {
			const matingBatchEnd = this.props.matingBatches.find(
				mb =>
					mb.matingPeriodStart &&
					mb.matingPeriodEnd &&
					mb.matingPeriodStart >= dateTo &&
					mb.matingPeriodEnd <= dateTo,
			);
			dateFrom = addDays(dateFrom, -365);
			const matingBatchStart = this.props.matingBatches.find(
				mb =>
					mb.matingPeriodStart &&
					mb.matingPeriodEnd &&
					mb.matingPeriodStart >= dateFrom &&
					mb.matingPeriodEnd <= dateFrom,
			);
			({ dateFrom, dateTo } = this.SetDateFromAndDateTo(matingBatchEnd, matingBatchStart, dateFrom, dateTo));
		} else if (
			dashboardSetting &&
			!dashboardSetting.rollingYear &&
			dashboardSetting.startMatingBatchId &&
			dashboardSetting.endMatingBatchId
		) {
			const matingBatchEnd = this.props.matingBatches.find(mb => mb.id === dashboardSetting.endMatingBatchId);
			const matingBatchStart = this.props.matingBatches.find(mb => mb.id === dashboardSetting.startMatingBatchId);
			({ dateFrom, dateTo } = this.SetDateFromAndDateTo(matingBatchEnd, matingBatchStart, dateFrom, dateTo));
		}
		return { dateFrom, dateTo };
	}

	private SetDateFromAndDateTo(
		matingBatchEnd: IMatingBatch | undefined,
		matingBatchStart: IMatingBatch | undefined,
		dateFrom: Date,
		dateTo: Date,
	) {
		if (
			matingBatchEnd &&
			matingBatchStart &&
			matingBatchStart.matingPeriodStart &&
			matingBatchEnd.matingPeriodEnd
		) {
			dateFrom = matingBatchStart.matingPeriodStart;
			dateTo = matingBatchEnd.matingPeriodEnd;
		}
		return { dateFrom, dateTo };
	}

	public generateColumnsExtensions() {
		let columnsExt = [
			{
				columnName: 'name',
				width: 250,
			},
		] as any[];

		if (this.props.matingBatchSetting && !this.props.matingBatchSetting.isManuel) {
			columnsExt.push({
				columnName: 'plan',
				width: 60,
			});
		}

		if (this.props.dashboardTableItems && this.props.dashboardTableItems['MatingDashboard']) {
			const dashboardItem = this.props.dashboardTableItems['MatingDashboard'].find(a => a !== undefined);
			if (dashboardItem && dashboardItem.data) {
				Object.keys(dashboardItem.data).forEach(key => {
					if (dashboardItem.data) {
						let data = dashboardItem.data[key];
						columnsExt.push({
							columnName: data.matingBatch && data.matingBatch.id && data.matingBatch.id.toString(),
							width: 80,
						});
					}
				});
			}
		}

		if (!this.props.dontShowYearColumns) {
			columnsExt = columnsExt.concat([
				{
					columnName: 'achievedYear',
					width: 100,
					isFixedRight: true,
				},
				{
					columnName: 'overGoalYear',
					width: 80,
					isFixedRight: true,
				},
				{
					columnName: 'goalYear',
					width: 100,
					isFixedRight: true,
				},
			]);
		}

		return columnsExt;
	}

	public render() {
		return (
			<ViewWeb>
				<OuterDiv>
					{this.props.loading || !this.props.dashboardTableItems['MatingDashboard'] ? (
						<ViewWeb className="spinner-container-active-dashboard">
							<ClipLoader color="#f2ac40" size={70} />
						</ViewWeb>
					) : (
						<ViewWeb className="dashboard-overview-table">
							<SkioldTableGrid
								columns={this.generateColumns()}
								ref={this.setRef}
								tableKey={
									!this.props.dontShowYearColumns
										? 'dashboardOverview'
										: 'dashboardOverviewNoYearColumns'
								}
								resizeAbleEnabled={false}
								groupedColumns={this.groupedColumns(this.props.dashboardSetting)}
								InlineHeaders={['FarrowingMatingBatch', 'WeaningMatingBatch']}
								data={this.GenerateData(this.props.dashboardTableItems)}
								ColumnExtensions={this.generateColumnsExtensions()}
								sortable={false}
								ignoreSetCount={true}
								filterable={false}
								showPagination={true}
								estimatedRowHeight={25}
							/>
						</ViewWeb>
					)}
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.modalOpen}
						close={this.closeModal}
						justify-content="flex-end"
						max-width="calc(100% - 220px)"
					>
						<ViewWeb className="dashboardOverview">
							{(this.state.modalSelected === 'TotalMatings' ||
								this.state.modalSelected === 'TotalDepartures') && (
								<TotalMatingsDashboardTable
									mode={TotalMatingsModalMode.Dashboard}
									isDeparture={this.state.modalSelected === 'TotalDepartures'}
									batchId={this.state.batchId}
									closeModal={this.closeModal}
								/>
							)}
							{(this.state.modalSelected === 'AnimalsToFarrowing' ||
								this.state.modalSelected === 'NeedFarrowing') && (
								<AnimalsToFarrowingsDashboardTable
									needFarrowing={this.state.modalSelected === 'NeedFarrowing'}
									batchId={this.state.batchId}
									closeModal={this.closeModal}
								/>
							)}
							{this.state.modalSelected === 'TotalFarrowings' && (
								<TotalFarrowingsDashboardTable
									batchId={this.state.batchId}
									closeModal={this.closeModal}
								/>
							)}
							{this.state.modalSelected === 'TotalWeanings' && (
								<TotalWeaningsDashboardTable
									batchId={this.state.batchId}
									closeModal={this.closeModal}
									count={this.state.count}
								/>
							)}
							{this.state.modalSelected === 'NeedWeaning' && (
								<NeedWeaningsDashboardTable batchId={this.state.batchId} closeModal={this.closeModal} />
							)}
							{this.state.modalSelected === 'DeadPiglets' && (
								<DeadPigletsDasbboardTable batchId={this.state.batchId} closeModal={this.closeModal} />
							)}
						</ViewWeb>
					</SkioldModal>
				</OuterDiv>
				<SowFeedUsageGraph
					isOpen={this.state.graphModalOpen}
					close={this.closeFeedUsageGraph}
					locationTypes={this.state.locationTypes}
				/>
			</ViewWeb>
		);
	}

	private closeModal = () => {
		this.setState({ modalOpen: false, batchId: undefined, modalSelected: undefined });
	};

	private GenerateData = (data: { [key: string]: IDashboardTableModel[] }) => {
		let d: IDashboardTableModel[] = [];

		if (data['MatingDashboard']) {
			d = d.concat(data['MatingDashboard']);
		}
		if (data['FarrowingDashboard']) {
			d = d.concat(data['FarrowingDashboard']);
		}
		if (data['WeaningDashboard']) {
			d = d.concat(data['WeaningDashboard']);
		}
		return d;
	};

	private setRef = (m: any) => {
		if (m) {
			this.SkioldTableRef = m;
		}
	};

	private OpenModal = (dashboardTableModelWithKey: DashboardTableModelWithKey) => {
		if (dashboardTableModelWithKey.tableModel.data) {
			this.setState({
				modalOpen: true,
				modalSelected: dashboardTableModelWithKey.tableModel.name,
				batchId: dashboardTableModelWithKey.key,
				count: dashboardTableModelWithKey.tableModel.data[dashboardTableModelWithKey.key].value,
			});
		}
	};

	private openFeedUsageGraph = (value: string) => {
		let locationTypes = [LocationType.Pregnant];
		if (value === 'TotalFarrowings') {
			locationTypes = [LocationType.Farrowing];
		} else if (value === 'TotalWeanings') {
			locationTypes = [LocationType.Mating, LocationType.YoungFemale];
		}
		this.setState({ graphModalOpen: true, locationTypes });
	};

	private closeFeedUsageGraph = () => {
		this.setState({ graphModalOpen: false });
	};

	private GetDashboardCellValue = (d: IDashboardTableModel, key: string) => {
		if (
			d.data &&
			d.data[key] &&
			d.data[key].value !== undefined &&
			d.name &&
			this.KeysWithModals.includes(d.name)
		) {
			const itemFromParent = { tableModel: d, key } as DashboardTableModelWithKey;
			return (
				<SkioldTouchableOpacity itemFromParent={itemFromParent} onPress={this.OpenModal}>
					{d.data[key].value && <ViewWeb className="folder-arrow arrow-folded" />}

					{this.GetValueColor(d.data[key].value, d)}
				</SkioldTouchableOpacity>
			);
		} else if (d.data && d.data[key] && d.data[key].value && d.data[key].value !== '') {
			return this.GetValueColor(d.data[key].value, d);
		} else {
			return ' ';
		}
	};
	private matingheader = (
		<div className={'flexDirectionRow positionMatingHeader width100 all-capitalized'}>
			<div className="paddingLeftTen">{localized('Matings')}</div>
			<div className="dashboardOverviewTableSettingContainer headerWithRows positionMatingRightHeader positionCorrectly">
				<div>{localized('Batch')}</div>
				<div>{localized('FromDate')}</div>
				<div>{localized('ToDate')}</div>
				<div>{localized('Days')}</div>
			</div>
		</div>
	);
	private generateColumns() {
		let columns = [
			{
				name: 'name',
				title: localized('Matings'),
				isFixedLeft: true,
				header: this.matingheader,
				getCellValue: this.GetNameField,
			},
		] as any[];

		if (this.props.matingBatchSetting && !this.props.matingBatchSetting.isManuel) {
			columns.push({
				name: 'plan',
				title: localized('Plan'),
				header: this.getPlanHeader,
				isFixedLeft: true,
				getCellValue: this.getPlan,
			});
		}

		if (this.props.dashboardTableItems && this.props.dashboardTableItems['MatingDashboard']) {
			const dashboardItem = this.props.dashboardTableItems['MatingDashboard'].find(a => a !== undefined);
			if (dashboardItem && dashboardItem.data) {
				Object.keys(dashboardItem.data).forEach(key => {
					if (dashboardItem.data) {
						let data = dashboardItem.data[key];
						columns.push({
							name: data.matingBatch && data.matingBatch.id && data.matingBatch.id.toString(),
							title:
								data.matingBatch && data.matingBatch.batchNumber
									? data.matingBatch.batchNumber.toString()
									: '',
							header: this.GetHeaderForDashboardTableModelData(data),
							getCellValue: (d: IDashboardTableModel) => this.GetDashboardCellValue(d, key),
						});
					}
				});
			}
		}

		if (!this.props.dontShowYearColumns) {
			columns = columns.concat([
				{
					name: 'achievedYear',
					title: localized('achievedYear'),
					isFixedRight: true,
					header: this.getAchievedYearHeader,
					getCellValue: this.getAchievedYear,
				},
				{
					name: 'overGoalYear',
					title: localized('overGoalYear'),
					header: this.getOverGoalYearHeader,
					isFixedRight: true,
					getCellValue: this.getOverGoalYear,
				},
				{
					name: 'goalYear',
					title: localized('goalYear'),
					isFixedRight: true,
					header: this.getGoalYearHeader,
					getCellValue: this.getGoalYear,
				},
			]);
		}

		return columns;
	}

	private GetValueColor = (value: string | undefined, d: IDashboardTableModel) => {
		const v = value && Number(value.replace(',', '.'));
		if (v && d.planValue && !Number.isNaN(v) && d.planValue.pcs) {
			if (d.planValue.negativeDeviation && d.planValue.pcs * (1 - d.planValue.negativeDeviation / 100) > v) {
				if (d.name && this.ReversePositiveNegative.includes(d.name)) {
					return <TextWeb className={'greenColor bold'}>{value}</TextWeb>;
				}
				return <TextWeb className={'redColor bold'}>{value}</TextWeb>;
			} else if (d.planValue.plusDeviation && d.planValue.pcs * (1 + d.planValue.plusDeviation / 100) < v) {
				if (d.name && this.ReversePositiveNegative.includes(d.name)) {
					return <TextWeb className={'redColor bold'}>{value}</TextWeb>;
				}
				return <TextWeb className={'greenColor bold'}>{value}</TextWeb>;
			}
		}
		return value;
	};

	private GetOverGoalYearColor = (d: IDashboardTableModel) => {
		if (d.overGoalYear !== undefined && d.overGoalYear !== 0) {
			if (d.overGoalYear < 0) {
				if (d.name && this.ReversePositiveNegative.includes(d.name)) {
					return 'greenColor bold';
				}
				return 'redColor bold';
			} else if (d.overGoalYear > 0) {
				if (d.name && this.ReversePositiveNegative.includes(d.name)) {
					return 'redColor bold';
				}
				return 'greenColor bold';
			}
		}
		return '';
	};

	private GetNameField = (d: IDashboardTableModel) =>
		d.name === 'FarrowingMatingBatch' ? (
			this.getFarrowingMatingBatchColumnHeader()
		) : d.name === 'WeaningMatingBatch' ? (
			this.getWeaningMatingBatchColumnHeader()
		) : (d.name === 'TotalMatings' && this.sectionTypeUseDistriwinLocal(LocationType.Mating)) ||
		  (d.name === 'TotalFarrowings' && this.sectionTypeUseDistriwinLocal(LocationType.Farrowing)) ||
		  (d.name === 'TotalWeanings' && this.sectionTypeUseDistriwinLocal(LocationType.WeanedPiglets)) ? (
			<div
				className={
					'textAlignStart' +
					(this.IndentedFields.includes(d.name) ? ' paddingLeftTwenty' : '') +
					(this.BoldedFields.includes(d.name) ? ' bold' : '')
				}
			>
				<SkioldTouchableOpacity
					itemFromParent={d.name}
					onPress={this.openFeedUsageGraph}
					className={'image-title-row'}
				>
					{localized(d.name)} <SkioldImage width={20} height={20} imageData={feedLog} />
				</SkioldTouchableOpacity>
			</div>
		) : d.name ? (
			<div
				className={
					'textAlignStart' +
					(this.IndentedFields.includes(d.name) ? ' paddingLeftTwenty' : '') +
					(this.BoldedFields.includes(d.name) ? ' bold' : '')
				}
			>
				{localized(d.name)}{' '}
			</div>
		) : (
			' '
		);

	private IndentedFields = [
		'YoungAnimalMatings',
		'ReturnSows',
		'DepartureNonPregnant',
		'ThereOfAbortions',
		'DepartureKilled',
		'DepartureDead',
		'ThereOfFirstParity',
		'NursingSows',
	];
	private BoldedFields = ['TotalMatings', 'TotalFarrowings', 'TotalWeanings'];

	private getFarrowingMatingBatchColumnHeader() {
		return (
			<div className={'flexDirectionRow positionMatingHeader'}>
				<div className="dashboardOverviewTableSettingContainer textAlignStart">{localized('Farrowings')}</div>
				<div className="dashboardOverviewTableSettingContainer positionMatingRightHeader paddingRightTwenty">
					{localized('FromDate')}
				</div>
			</div>
		);
	}

	private getWeaningMatingBatchColumnHeader() {
		return (
			<div className={'flexDirectionRow positionMatingHeader'}>
				<div className="dashboardOverviewTableSettingContainer textAlignStart">{localized('Weanings')}</div>
				<div className="dashboardOverviewTableSettingContainer positionMatingRightHeader paddingRightTwenty">
					<div>{localized('FromDate')}</div>
				</div>
			</div>
		);
	}

	private GetHeaderForDashboardTableModelData(data: DashboardTableModelItem) {
		return (
			<div className="dashboardOverviewTableSettingContainer headerWithRows borderRightDotted">
				<div>{this.getBatchNumber(data)}</div>
				<div>{data.matingBatch && getDateString(data.matingBatch.matingPeriodStart)}</div>
				<div>{data.matingBatch && getDateString(data.matingBatch.matingPeriodEnd)}</div>
				<div>{this.getMatingPeriod(data)}</div>
			</div>
		);
	}

	private getBatchNumber(data: DashboardTableModelItem): React.ReactNode {
		return data.matingBatch && data.matingBatch.batchNumber ? data.matingBatch.batchNumber.toString() : '';
	}

	private getMatingPeriod(data: DashboardTableModelItem): React.ReactNode {
		return (
			data.matingBatch &&
			data.matingBatch.matingPeriodStart &&
			data.matingBatch.matingPeriodEnd &&
			Math.round(
				(data.matingBatch.matingPeriodEnd.getTime() - data.matingBatch.matingPeriodStart.getTime()) /
					(1000 * 3600 * 24),
			)
		);
	}

	private sectionTypeUseDistriwinLocal = (sectionType: LocationType) => {
		return sectionTypeUseDistriwin(sectionType, this.props.sections, this.props.processEquipmentData);
	};
}

export default connect<ReturnType<typeof mapStateToProps>, null, RefType, WebAppState>(mapStateToProps, null, null, {
	forwardRef: true,
})(DashBoardOverviewTable);
