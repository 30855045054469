import { Dispatch } from 'redux';
import {
	ITreatment,
	ITreatmentPlan,
	Treatment,
	TreatmentAndTreatmentPlan,
	TreatmentAndTreatmentPlanList,
} from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { getSiteId, mergeArrays } from 'shared/helpers/reducer-helpers';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import {
	findMultiPenRetentionTimeByTreatment,
	findMultipleStemAnimalRetentionTimesByTreatments,
	findPenRetentionTimeByTreatment,
	findStemAnimalRetentionTimeByTreatment,
} from 'shared/helpers/treatment-helper/treatment-helper';
import { StoreContainer } from 'shared/state/store-container';
import { SetRetentionTimeOnPens } from '../locations/operations';
import * as Action from './actions';
import { SetRetentionTimeOnStemAnimals } from '../stem-animals/operations';
import { createSelector } from '@reduxjs/toolkit';
import { SharedAppState } from 'shared/state/store.shared';

export function GetCompletedTreatmentsInPeriod(siteId: string, utcStart: Date, utcEnd: Date) {
	return AsyncOperationBuilder2(
		Action.getTreatmentsForLog,
		client => client.treatment_GetCompletedBySiteId(siteId, utcStart, utcEnd),
		{ siteId },
	);
}

export function GetTreatmentsByAnimalId(stemAnimalId: string) {
	const state = StoreContainer.getState();
	return AsyncOperationBuilder2(
		Action.getTreatmentsByStemAnimalId,
		client => client.treatment_GetByStemAnimalId(stemAnimalId),
		{ stemAnimalId, siteId: getSiteId(state.profile.active) },
	);
}

export function SaveTreatmentList(treatments: ITreatment[], treatmentPlan: ITreatmentPlan) {
	return async (dispatch: Dispatch<any>) => {
		// Save retention time on pen
		if (!treatmentPlan.isRegistration) {
			SetRetentionTimeOnPens(findMultiPenRetentionTimeByTreatment(treatments, treatmentPlan))(dispatch);
			SetRetentionTimeOnStemAnimals(findMultipleStemAnimalRetentionTimesByTreatments(treatments, treatmentPlan))(
				dispatch,
			);
		}

		await AsyncOperationBuilder2(
			Action.createTreatmentList,
			client =>
				client.treatment_CreateTreatments2({
					treatments: treatments.map(I => Treatment.fromJS(I)),
					treatmentPlan: treatmentPlan,
				} as TreatmentAndTreatmentPlanList),
			treatments.map(I => Treatment.fromJS(I)),
			undefined,
			undefined,
			undefined,
			0,
		)(dispatch);
	};
}

export function SaveManyTreatments(treatments: ITreatment[]) {
	return (dispatch: Dispatch<any>) => {
		const state = StoreContainer.getState();
		const ids = treatments.map(t => t.id);
		const prevEntity = state.treatments.entities.filter(treat => ids.includes(treat.id));

		// Save retention time on pen
		SetRetentionTimeOnPens(findMultiPenRetentionTimeByTreatment(treatments))(dispatch);
		SetRetentionTimeOnStemAnimals(findMultipleStemAnimalRetentionTimesByTreatments(treatments))(dispatch);

		if (treatments && treatments.length > 0) {
			const firstTreatment = treatments[0];
			if (firstTreatment.siteId === '5f8453b80b833f242c2e65f8') {
				const logString = treatments
					.filter(t => t.executedDate)
					.map(t => (t.executedDate ? t.executedDate.toISOString() : '' + ' - ' + t.id))
					.join(',');

				Monitoring.logTrace(
					`User: ${firstTreatment.executedByProfileId}, Syncing at ${new Date().toISOString()}.`,
					{
						treatments: logString,
					},
				);
			}
		}
		AsyncOperationBuilder2(
			Action.saveManyTreatments,
			client => client.treatment_SaveManyTreatments(treatments.map(I => Treatment.fromJS(I))),
			{ prevEntities: prevEntity, treatments },
			prevEntity,
			undefined,
			undefined,
			0,
		)(dispatch);
	};
}

export function SaveTreatment(treatment: ITreatment) {
	const state = StoreContainer.getState();
	const prevEntity = state.treatments.entities.find(treat => treat.id === treatment.id);
	return async (dispatch: Dispatch<any>) => {
		const treatmentPlan = state.treatmentPlans.entities.find(plan => plan.id === treatment.treatmentPlanId);
		// Save retention time on pen
		if (treatmentPlan && !treatmentPlan.isRegistration) {
			SetRetentionTimeOnPens(findPenRetentionTimeByTreatment(treatment, treatmentPlan))(dispatch);
			SetRetentionTimeOnStemAnimals(findStemAnimalRetentionTimeByTreatment(treatment, treatmentPlan))(dispatch);
		}
		if (treatment && treatment.executedDate && treatment.siteId === '5f8453b80b833f242c2e65f8') {
			Monitoring.logTrace(`Treatment - treatmentPlanId: ${treatment.treatmentPlanId}, Id: ${treatment.id}`, {
				executedBy: treatment.executedByProfileId ? treatment.executedByProfileId : '',
				executeDate: treatment.executedDate.toISOString(),
				date: new Date().toISOString(),
			});
		}

		await AsyncOperationBuilder2(
			Action.saveTreatment,
			client =>
				client.treatment_Post2({
					treatment: Treatment.fromJS(treatment),
					treatmentPlan: treatmentPlan,
				} as TreatmentAndTreatmentPlan),
			Treatment.fromJS(treatment),
			prevEntity,
		)(dispatch);
	};
}

export function SaveLogTreatment(treatment: ITreatment) {
	const state = StoreContainer.getState();
	const prevEntity = state.treatments.treatmentsForLog.find(treat => treat.id === treatment.id);

	return async (dispatch: Dispatch<any>) => {
		const treatmentPlan = state.treatmentPlans.entities.find(plan => plan.id === treatment.treatmentPlanId);

		// Save retention time on pen
		if (treatmentPlan && !treatmentPlan.isRegistration) {
			SetRetentionTimeOnPens(findPenRetentionTimeByTreatment(treatment, treatmentPlan))(dispatch);
			SetRetentionTimeOnStemAnimals(findStemAnimalRetentionTimeByTreatment(treatment, treatmentPlan))(dispatch);
		}
		await AsyncOperationBuilder2(
			Action.saveLogTreatment,
			client => client.treatment_Post(Treatment.fromJS(treatment)),
			Treatment.fromJS(treatment),
			prevEntity,
		)(dispatch);
	};
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.treatments.lastSyncDate;
	if (state.treatments.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.treatment_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.treatments.updates;
	const treatmentPlans = state.treatmentPlans.entities;
	let promises = new Array<Promise<void>>();
	return (dispatch: Dispatch<any>) => {
		if (state.treatments.saveSyncInProgress) {
			return Promise.resolve();
		}

		if (updates && updates.length > 0 && updates[0].siteId === '5f8453b80b833f242c2e65f8') {
			Monitoring.logTrace(`Length of treatment update at SaveSyncData ${updates.length}`);
		}

		for (let index = 0; index < updates.length; index++) {
			const update = updates[index];

			if (update && update.siteId === '5f8453b80b833f242c2e65f8') {
				Monitoring.logTrace(`Treatment - treatmentPlanId: ${update.treatmentPlanId}, Id: ${update.id}`, {
					executedBy: update.id ? update.id : '',
					date: getDateString(update.executedDate),
				});
			}

			const plan = treatmentPlans.find(plan => plan.id === update.treatmentPlanId);
			if (plan) {
				if (update.executedDate) {
					promises.push(SaveLogTreatment(update)(dispatch));
				} else {
					promises.push(SaveTreatment(update)(dispatch));
				}
			} else {
				if (update && update.siteId === '5f8453b80b833f242c2e65f8') {
					Monitoring.logTrace(
						`No matching Plan - treatmentPlanId: ${update.treatmentPlanId}, Id: ${update.id}`,
						{ executedBy: update.id ? update.id : '', date: getDateString(update.executedDate) },
					);
				}
			}
		}
		return Promise.all(promises);
	};
}

export function RemoveTreatmentByAnimal(id: string) {
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeTreatmentsByAnimal(id));
	};
}


export const selectTreatments = createSelector(
	(store: SharedAppState) => store.treatments,
	treatments => mergeArrays(treatments.entities, treatments.treatmentsForLog),
);

export const selectTreatmentsByTreatmentPlanIds = createSelector(
	selectTreatments,
	(_: ITreatment[], treatmentPlanIds: string[]) => treatmentPlanIds,
	(treatments: ITreatment[], treatmentPlanIds: string[]) =>
		treatments.filter(t => t.treatmentPlanId && treatmentPlanIds.includes(t.treatmentPlanId)),
);