import React from 'react';
import { FC } from 'react';
import { ViewWeb } from '../../utils/web-view';
import { SkioldTouchableOpacity } from '../skiold-touchable-opacity';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { localized } from 'shared/state/i18n/i18n';
import { WhiteText } from '../../Text/white-text';

interface PropsFromParent {
	icon: any;
	title?: string;
	onClick: () => void;
}

export const SkioldIconButton: FC<PropsFromParent> = React.memo(({ icon, title, onClick }) => {
	return (
		<ViewWeb className="verticalCenter">
			<SkioldTouchableOpacity className={'positionRelative'} onPress={onClick}>
				<SkioldImage width="60" height="60" imageData={icon} />
			</SkioldTouchableOpacity>
			<WhiteText>{title}</WhiteText>
		</ViewWeb>
	);
});
