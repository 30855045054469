import { Option } from 'react-dropdown';
import {
	AnimalType,
	IBuilding,
	IMoveEvent,
	IPen,
	IProcessEquipmentData,
	ISection,
	IStemAnimal,
	LocationType,
	ProductionForm,
	SourceSystem
} from 'shared/api/api';
import { LocationsState } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import { PenFormData } from 'shared/state/models/locations/pen-form-data';
import { StoreContainer } from 'shared/state/store-container';
import { onlyUniqueFilter } from './general-helpers';
import { NaturalSort } from './natural-sort';

// tslint:disable-next-line: interface-name
export interface ILocationModel {
	buildings: IBuilding[];
	sections: ISection[];
	pens: IPen[];
}

export class LocationModel implements ILocationModel {
	public buildings: IBuilding[];
	public sections: ISection[];
	public pens: IPen[];

	constructor(locationState: LocationsState) {
		this.buildings = [...locationState.buildings];
		this.sections = [...locationState.sections];
		this.pens = [...locationState.pens];
	}
}

export function getStemAnimalPenId(stemAnimal: IStemAnimal, moveEvents: IMoveEvent[]) {
	if (stemAnimal) {
		const stemAnimalMoveEvents =
			moveEvents && moveEvents.length > 0
				? moveEvents.find(move => move.stemAnimalId === stemAnimal.id)
				: undefined;
		if (stemAnimalMoveEvents) {
			return stemAnimalMoveEvents.penId;
		}
		return stemAnimal.entrancePenId;
	}
	return undefined;
}

export function getStemAnimalPenIdHashmapMoveEvents(
	stemAnimal: IStemAnimal,
	moveEvents: { [key: string]: IMoveEvent[] },
) {
	if (stemAnimal && stemAnimal.id) {
		const stemAnimalMoveEvents = moveEvents[stemAnimal.id];
		if (
			stemAnimalMoveEvents &&
			stemAnimalMoveEvents.length > 0 &&
			stemAnimalMoveEvents[0] &&
			stemAnimalMoveEvents[0].penId
		) {
			return stemAnimalMoveEvents[0].penId;
		}
		return stemAnimal.entrancePenId;
	}
	return undefined;
}

export function getStemAnimalPenHashMaps(
	stemAnimal: IStemAnimal,
	moveEvents: { [key: string]: IMoveEvent[] },
	pens: { [key: string]: IPen },
) {
	const penId = getStemAnimalPenIdHashmapMoveEvents(stemAnimal, moveEvents);
	return penId ? pens[penId] : undefined;
}

export function getStemAnimalPen(stemAnimal: IStemAnimal, moveEvents: IMoveEvent[], pens: IPen[]) {
	const penId = getStemAnimalPenId(stemAnimal, moveEvents);
	return pens.find(x => x.id === penId);
}

export function getAnimalCountByBuilding(buildingId: string, pens: IPen[]) {
	const penIds = pens.filter(pen => pen.buildingId === buildingId).map(pen => pen.id!);
	return getAnimalCountByPenIds(penIds);
}

export function getAnimalCountBySection(sectionId: string, pens: IPen[]) {
	const penIds = pens.filter(pen => pen.sectionId === sectionId).map(pen => pen.id!);
	return getAnimalCountByPenIds(penIds);
}

export function getAnimalCountByPenIds(penIds: string[]) {
	const state = StoreContainer.getState();
	let animals = state.stemAnimals.entities.filter(animal => !animal.departureDate);
	let moveEvents = state.moveEvents.entities;

	let count = 0;
	if (penIds && penIds.length > 0) {
		const stemAnimalIds = animals.map(stem => stem.id);
		const filteredMoveEvents = moveEvents
			.sort((a: IMoveEvent, b: IMoveEvent) => {
				return b.moveDate!.getTime() - a.moveDate!.getTime();
			})
			.filter(move => stemAnimalIds.includes(move.stemAnimalId));

		const moveEventsGrouped = filteredMoveEvents.groupBy('stemAnimalId');
		let stemAnimalIdsToRemove = new Array<string>();

		moveEventsGrouped.forEach((value: IMoveEvent[], index) => {
			//Index 0 is the lastest moveEvent for animal
			stemAnimalIdsToRemove.push(value[0].stemAnimalId!);
			if (penIds.includes(value[0].penId!)) {
				++count;
			}
		});

		count += animals.filter(
			stem => !stemAnimalIdsToRemove.includes(stem.id!) && penIds.includes(stem.entrancePenId!),
		).length;
	}

	return count;
}

export function formatLocationString(building?: IBuilding, section?: ISection, pen?: IPen) {
	return `${building && building.name}${building && building.useSections ? ` - ${section && section.name}` : ''}${section && section.usePens && pen ? ` ${localized('pen')}: ${pen!.name}` : ''
		}`;
}

export const usesPens = (sectionId: string) => {
	const state = StoreContainer.getState();
	let section = state.locations.sections.find(s => s.id === sectionId);
	return section && section.usePens;
};

export function FindHiddenPenOnSection(sectionId: string, assignedPenId?: string) {
	const state = StoreContainer.getState();
	let pen = state.locations.pens.find(p => p.sectionId === sectionId);
	if (pen && assignedPenId !== pen.id) {
		return pen.id;
	} else {
		return undefined;
	}
}

export function FindHiddenPenObjectOnSection(sectionId: string) {
	const state = StoreContainer.getState();
	let pen = state.locations.pens.find(p => p.sectionId === sectionId);
	if (pen) {
		return pen;
	} else {
		return undefined;
	}
}

export function CheckIfSectionUsesPens(sectionId: string) {
	const state = StoreContainer.getState();
	let section = state.locations.sections.find(s => s.id === sectionId);
	if (section && section.usePens) {
		return true;
	} else {
		return false;
	}
}

export function generatePenFormData(): PenFormData {
	return {
		startNumber: 0,
		numOfPens: 1,
		capacity: undefined,
		letter: undefined,
	};
}

export function CheckSectionForAnimalType(
	section: ISection,
	animalType: AnimalType,
	locationType?: LocationType,
	allowYoungFemale?: boolean,
) {
	if (
		(section.animalType === AnimalType.Sow &&
			section.type === LocationType.YoungFemale &&
			animalType === AnimalType.YoungFemale &&
			allowYoungFemale) ||
		(section.animalType === AnimalType.Sow && animalType === AnimalType.Sow) ||
		(animalType === AnimalType.Weaner && section.animalType === AnimalType.Weaner) ||
		(animalType === AnimalType.Finisher && section.animalType === AnimalType.Finisher) ||
		(allowYoungFemale &&
			animalType === AnimalType.FRATS &&
			locationType &&
			section.animalType === AnimalType.FRATS &&
			section.type &&
			section.type.toString().includes('Finisher') &&
			locationType.toString().includes('Weaners')) ||
		(animalType === AnimalType.FRATS &&
			locationType &&
			section.animalType === animalType &&
			section.type &&
			section.type.toString().includes(locationType)) ||
		(animalType === AnimalType.FRATS &&
			locationType &&
			section.animalType === animalType &&
			section.type &&
			section.type.toString().includes(locationType))
	) {
		return true;
	}
}

export function generateAnimalType(form: ProductionForm | undefined) {
	const standardType = [{ value: '', label: localized('choose') }];
	if (form && form === ProductionForm.PigProduction) {
		return standardType.concat([
			{ value: AnimalType.Sow, label: localized('Sow') },
			{ value: AnimalType.Weaner, label: localized('Weaner') },
			{ value: AnimalType.Finisher, label: localized('Finisher') },
			{ value: AnimalType.FRATS, label: localized('FRATS') },
		]);
	}
	return standardType;
}

//Possible location types change, depending on production form
export function generateLocationType(form: ProductionForm | undefined, animalType: string | undefined) {
	const standardType = [{ value: '', label: localized('choose') }];
	if (form && form === ProductionForm.PigProduction) {
		if (animalType === AnimalType.Sow) {
			return standardType.concat([
				{ value: LocationType.Mating, label: localized('Mating') },
				{ value: LocationType.Pregnant, label: localized('Pregnant') },
				{ value: LocationType.Farrowing, label: localized('Farrowing') },
				{ value: LocationType.YoungFemale, label: localized('YoungFemale') },
				{ value: LocationType.Boars, label: localized('Boars') },
				{ value: LocationType.Relief, label: localized('Relief') },
				{ value: LocationType.Holistic, label: localized('Holistic') },
				{ value: LocationType.PostponeSows, label: localized('PostponeSows') },
				{ value: LocationType.WeanedPiglets, label: localized('WeanedPiglets') },
				{ value: LocationType.YoungAnimal, label: localized('youngAnimals') },
			]);
		}
		if (animalType === AnimalType.Weaner) {
			return standardType.concat([
				{ value: LocationType.Weaners, label: localized('Weaners') },
				{ value: LocationType.Relief, label: localized('Relief') },
			]);
		}
		if (animalType === AnimalType.Finisher) {
			return standardType.concat([
				{ value: LocationType.Finisher, label: localized('Finisher') },
				{ value: LocationType.Relief, label: localized('Relief') },
			]);
		}
		if (animalType === AnimalType.FRATS) {
			return standardType.concat([
				{ value: LocationType.Weaners, label: localized('Weaners') },
				{ value: LocationType.Finisher, label: localized('Finisher') },
				{ value: LocationType.ReliefFinisher, label: localized('ReliefFinisher') },
				{ value: LocationType.ReliefWeaners, label: localized('ReliefWeaners') },
			]);
		}
	} else if (form && form === ProductionForm.Mills) {
		return standardType.concat([{ value: LocationType.Silo, label: localized('Silo') }]);
	}

	return standardType;
}

export function getPenBySow(sow: IStemAnimal | undefined) {
	const state = StoreContainer.getState();
	if (sow) {
		return getStemAnimalPen(
			sow,
			state.moveEvents.entities.concat(state.moveEvents.departuredMoveEvents),
			state.locations.pens,
		);
	} else {
		return undefined;
	}
}

export function getLocationString(sow: IStemAnimal | undefined) {
	const state = StoreContainer.getState();
	let locationString = '';
	if (sow) {
		const pen = getStemAnimalPen(
			sow,
			state.moveEvents.entities.concat(state.moveEvents.departuredMoveEvents),
			state.locations.pens,
		);
		if (!pen) {
			return;
		}
		const section = state.locations.sections.find(x => x.id === pen!.sectionId);
		if (!section) {
			return;
		}
		const building = state.locations.buildings.find(x => x.id === pen!.buildingId);
		if (!building) {
			return;
		}
		locationString = formatLocationString(building, section, pen);
	}

	return locationString;
}

export function getPenNameByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		const pen = dic[penId] as IPen;
		if (pen && pen.sectionId) {
			const section = dic[pen.sectionId] as ISection;
			if (section && section.usePens) {
				return pen.name;
			}
		}
	}

	return '';
}

export const getOrderById = (id: string | undefined, dic: any) => {
	if (id) {
		const locationType = dic[id] as AllLocationType;
		if (locationType) {
			return locationType.order;
		}
	}

	return undefined;
};

export function getSectionBySectionId(sectionId: string | undefined, dic: any) {
	if (sectionId) {
		const section = dic[sectionId] as ISection;
		if (section) {
			return section;
		}
	}

	return undefined;
}

export function getSectionNameByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		const pen = dic[penId] as IPen;
		if (pen && pen.sectionId) {
			const section = dic[pen.sectionId] as ISection;
			if (section && section.buildingId) {
				const building = dic[section.buildingId] as IBuilding;
				if (building && building.useSections) {
					return section.name;
				}
			}
		}
	}

	return '';
}

export function getSectionNameById(sectionId: string | undefined, dic: any) {
	if (sectionId) {
		const section = dic[sectionId] as ISection;
		if (section) {
			if (section && section.buildingId) {
				const building = dic[section.buildingId] as IBuilding;
				if (building && building.useSections) {
					return section.name;
				}
			}
		}
	}

	return '';
}

export function getSectionByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		const pen = dic[penId] as IPen;
		if (pen && pen.sectionId) {
			return dic[pen.sectionId] as ISection;
		}
	}

	return undefined;
}

export function getPenByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		return dic[penId] as IPen;
	}

	return undefined;
}

export function getBuildingByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		const pen = dic[penId] as ISection;
		if (pen && pen.buildingId) {
			const building = dic[pen.buildingId] as IBuilding;
			if (building) {
				return building;
			}
		}
	}

	return undefined;
}

export function getBuildingNameBySectionId(sectionId: string | undefined, dic: any) {
	if (sectionId) {
		const section = dic[sectionId] as IPen;
		if (section && section.buildingId) {
			const building = dic[section.buildingId] as IBuilding;
			if (building) {
				return building.name;
			}
		}
	}

	return undefined;
}

export function getBuildingByBuildingId(buildingId: string | undefined, dic: any) {
	if (buildingId) {
		const building = dic[buildingId] as IBuilding;
		if (building) {
			return building;
		}
	}

	return undefined;
}

export function getBuildingNameByPenId(penId: string | undefined, dic: any) {
	if (penId) {
		const pen = dic[penId] as IPen;
		if (pen && pen.buildingId) {
			const building = dic[pen.buildingId] as IBuilding;
			if (building) {
				return building.name;
			}
		}
	}

	return '';
}

export function getBuildingIdBySectionId(sectionId: string | undefined, hashmapLocations: any) {
	if (sectionId) {
		const section = hashmapLocations[sectionId] as ISection;
		if (!section || !section.buildingId) {
			return;
		}
		const building = hashmapLocations[section.buildingId] as IBuilding;
		return building.id;
	}

	return undefined;
}

export function getBuildingBySectionId(sectionId: string | undefined, hashmapLocations: any) {
	if (sectionId) {
		const section = hashmapLocations[sectionId] as ISection;
		if (!section || !section.buildingId) {
			return;
		}
		return hashmapLocations[section.buildingId] as IBuilding;
	}

	return undefined;
}

export function getLocationStringBySectionId(sectionId: string | undefined, hashmapLocations: any) {
	let locationString = '';
	if (sectionId) {
		const section = hashmapLocations[sectionId] as ISection;
		if (!section || !section.buildingId) {
			return;
		}
		const building = hashmapLocations[section.buildingId] as IBuilding;
		if (!building) {
			return;
		}
		locationString = formatLocationString(building, section, undefined);
	}

	return locationString;
}

export function getLocationStringByPenIdHashmap(penId: string | undefined, hashmapLocations: any) {
	let locationString = '';
	if (penId) {
		const pen = hashmapLocations[penId] as IPen;
		if (pen) {
			const sectionId = pen.sectionId;
			if (sectionId) {
				const section = hashmapLocations[sectionId] as ISection;
				if (!section || !section.buildingId) {
					return;
				}
				const building = hashmapLocations[section.buildingId] as IBuilding;
				if (!building) {
					return;
				}
				locationString = formatLocationString(building, section, undefined);
			}
		}
	}

	return locationString;
}

export function getLocationStringByPenId(penId: string | undefined) {
	const state = StoreContainer.getState();
	let locationString = '';
	if (penId) {
		const pen = state.locations.pens.find(a => a.id === penId);
		if (!pen) {
			return;
		}
		const section = state.locations.sections.find(x => x.id === pen!.sectionId);
		if (!section) {
			return;
		}
		const building = state.locations.buildings.find(x => x.id === pen!.buildingId);
		if (!building) {
			return;
		}
		locationString = formatLocationString(building, section, pen);
	}

	return locationString;
}

export function getStableSectionStringByPenId(penId: string | undefined) {
	const state = StoreContainer.getState();
	let locationString = '';
	if (penId) {
		const pen = state.locations.pens.find(a => a.id === penId);
		if (!pen) {
			return;
		}
		const section = state.locations.sections.find(x => x.id === pen!.sectionId);
		if (!section) {
			return;
		}
		const building = state.locations.buildings.find(x => x.id === pen!.buildingId);
		if (!building) {
			return;
		}
		locationString = formatLocationString(building, section);
	}

	return locationString;
}

export function getStableSectionOptionByPenId(penId: string | undefined) {
	const state = StoreContainer.getState();
	let locationString = '';

	if (penId) {
		const pen = state.locations.pens.find(a => a.id === penId);
		if (!pen) {
			return;
		}
		const section = state.locations.sections.find(x => x.id === pen!.sectionId);
		if (!section) {
			return;
		}
		const building = state.locations.buildings.find(x => x.id === pen!.buildingId);
		if (!building) {
			return;
		}
		locationString = formatLocationString(building, section);
		return { label: locationString, value: section.id } as Option;
	}

	return { label: ' ', value: '' } as Option;
}

export function getCompleteLocationBySow(sow: IStemAnimal, locationState: ILocationModel, moveEvents: IMoveEvent[]) {
	let moveEvent = moveEvents.find(moveEvent => moveEvent.stemAnimalId === sow.id);
	const penId = moveEvent ? moveEvent.penId : sow.entrancePenId;
	const pen = locationState.pens.find(pen => pen.id === penId);

	let building = pen ? locationState.buildings.find(building => building.id === pen.buildingId) : undefined;
	let section = pen ? locationState.sections.find(section => section.id === pen.sectionId) : undefined;

	return { building, section, pen };
}

export function getCurrentBuildingBySow(sow: IStemAnimal, locationState: ILocationModel, moveEvents: IMoveEvent[]) {
	let moveEvent = moveEvents.find(moveEvent => moveEvent.stemAnimalId === sow.id);
	const penId = moveEvent ? moveEvent.penId : sow.entrancePenId;
	const pen = locationState.pens.find(pen => pen.id === penId);

	return pen ? locationState.buildings.find(building => building.id === pen.buildingId) : undefined;
}

export function getCurrentSectionBySow(sow: IStemAnimal, locationState: ILocationModel, moveEvents: IMoveEvent[]) {
	let moveEvent = moveEvents.find(moveEvent => moveEvent.stemAnimalId === sow.id);
	const penId = moveEvent ? moveEvent.penId : sow.entrancePenId;
	const pen = locationState.pens.find(pen => pen.id === penId);
	return pen ? locationState.sections.find(section => section.id === pen.sectionId) : undefined;
}

export function getCurrentPenBySow(sow: IStemAnimal, locationState: ILocationModel, moveEvents: IMoveEvent[]) {
	let moveEvent = moveEvents.find(moveEvent => moveEvent.stemAnimalId === sow.id);
	const penId = moveEvent ? moveEvent.penId : sow.entrancePenId;
	return locationState.pens.find(pen => pen.id === penId);
}

export const getFilteredLocationsForYoungFemale = (locationsState: LocationsState) => {
	const locations = { ...locationsState };
	locations.sections = locations.sections.filter(s => s.type === LocationType.YoungFemale);
	return locations;
};

export const getFilteredLocationsByTypes = (locationTypes: LocationType[]) => {
	const locations = { ...StoreContainer.getState().locations };
	locations.sections = locations.sections.filter(s => s.type && locationTypes.includes(s.type));
	return locations;
};

export const getFilteredLocationsByTypesAndAnimalType = (
	locationsState: LocationsState,
	locationTypes: LocationType[],
	animalTypes: AnimalType[],
) => {
	const locations = { ...locationsState };
	locations.sections = locations.sections.filter(
		s => s.type && locationTypes.includes(s.type) && s.animalType && animalTypes.includes(s.animalType),
	);
	return locations;
};

export const getFilteredLocationsByTypesAndNoPens = (locationTypes: LocationType[]) => {
	const locations = { ...StoreContainer.getState().locations };
	locations.sections = locations.sections.filter(s => !s.usePens && s.type && locationTypes.includes(s.type));
	return locations;
};

export const getLocationStrinByPenId = (penId: string) => {
	const state = StoreContainer.getState();
	let locationString = '';

	const pen = state.locations.pens.find(x => x.id === penId);
	if (!pen) {
		return;
	}
	const section = state.locations.sections.find(x => x.id === pen!.sectionId);
	if (!section) {
		return;
	}
	const building = state.locations.buildings.find(x => x.id === pen!.buildingId);
	if (!building) {
		return;
	}
	locationString = formatLocationString(building, section, pen);

	return locationString;
};

export const getLocationPlaceholder = () => {
	return { label: localized('Location'), value: '' } as Option;
};

export const getPenPlaceholder = () => {
	return { label: '', value: '' } as Option;
};

type AllLocationType = IBuilding | ISection | IPen;

export const sortPensByName = (a: IPen, b: IPen) => {
	// sort by order then by name
	if (a.order! > b.order!) {
		return 1;
	} else if (b.order! > a.order!) {
		return -1;
	} else {
		if (a.name && b.name) {
			return a.name.localeCompare(b.name);
		} else if (a.name) {
			return -1; // a comes first if b.name is undefined
		} else if (b.name) {
			return 1; // b comes first if a.name is undefined
		} else {
			return 0; // both names are undefined, they are equal
		}
	}
}

export function sortByOrder(locationToSort: AllLocationType[]) {
	// sort by order then by name

	return locationToSort.slice().sort((a, b) => {
		if (a.order! > b.order!) {
			return 1;
		} else if (b.order! > a.order!) {
			return -1;
		} else {
			if (a.name && b.name) {
				return a.name.localeCompare(b.name);
			} else if (a.name) {
				return -1; // a comes first if b.name is undefined
			} else if (b.name) {
				return 1; // b comes first if a.name is undefined
			} else {
				return 0; // both names are undefined, they are equal
			}
		}
	});
}

export function getLocationStableSectionOptionsBySectionIds(
	sectionIds: string[],
	hashmapLocations: any,
	usePlaceholder?: boolean,
) {
	let options: Option[] = usePlaceholder ? [getLocationPlaceholder()] : [];
	sectionIds.forEach(sectionId => {
		let locationString = '';
		if (sectionId) {
			const section = hashmapLocations[sectionId] as ISection;
			if (!section || !section.buildingId) {
				return;
			}
			const building = hashmapLocations[section.buildingId] as IBuilding;
			if (!building) {
				return;
			}
			locationString = formatLocationString(building, section, undefined);
		}

		options.push({ label: locationString, value: sectionId });
	});
	return options;
}

export const getPenRetentionTime = (penId: string | undefined) => {
	const pens = StoreContainer.getState().locations.pens;
	const penFound = pens.find(p => p.id === penId);

	return penFound && penFound.retentionTime && penFound.retentionTime.withoutTime() >= new Date().withoutTime()
		? penFound.retentionTime
		: undefined;
};

export const getSectionRetentionTime = (sectionId: string | undefined) => {
	const pens = StoreContainer.getState().locations.pens;
	const pensForSection = pens.filter(p => p.sectionId === sectionId && p.retentionTime);
	let mostRecentDate = new Date(
		Math.max.apply(
			null,
			pensForSection.map(e => {
				return new Date(e.retentionTime!).getTime();
			}),
		),
	);
	return mostRecentDate && mostRecentDate.withoutTime() >= new Date().withoutTime() ? mostRecentDate : undefined;
};

export const NaturalSortPenOrder = (data1: any, data2: any) => {
	return NaturalSort(data1.penOrder, data2.penOrder);
};

export const NaturalSortBuildSection = (data1: any, data2: any) => {
	if (data1.buildingOrder !== data2.buildingOrder) {
		return NaturalSort(data1.buildingOrder, data2.buildingOrder);
	} else if (data1.sectionOrder !== data2.sectionOrder) {
		return NaturalSort(data1.sectionOrder, data2.sectionOrder);
	} else if (data1.penOrder !== data2.penOrder) {
		return NaturalSort(data1.penOrder, data2.penOrder);
	} else {
		return 0;
	}
};

export const NaturalSortSection = (data1: any, data2: any) => {
	if (data1.sectionOrder !== data2.sectionOrder) {
		return NaturalSort(data1.sectionOrder, data2.sectionOrder);
	} else if (data1.penOrder !== data2.penOrder) {
		return NaturalSort(data1.penOrder, data2.penOrder);
	} else {
		return 0;
	}
};

export const NaturalSortPens = (data1: any, data2: any) => {
	if (data1.penOrder !== data2.penOrder) {
		return NaturalSort(data1.penOrder, data2.penOrder);
	} else {
		return 0;
	}
};

export function getStableSectionOptionAnimalType(hashmapLocations: any, animalType: AnimalType) {
	const state = StoreContainer.getState();
	let sections = state.locations.sections.filter(sec => sec.animalType === animalType);
	sections = sections.sort((a, b) => NaturalSort(a.order, b.order));
	return getLocationStableSectionOptionsBySectionIds(
		sections.map(s => s.id!),
		hashmapLocations,
		true,
	);
}

export function getStableSectionOptionByLocationTypes(hashmapLocations: any, locationTypes?: LocationType[]) {
	const state = StoreContainer.getState();
	let sections = state.locations.sections.filter(s => !locationTypes || (s.type && locationTypes.includes(s.type)));
	sections = sections.sort((a, b) => NaturalSort(a.order, b.order));
	return getLocationStableSectionOptionsBySectionIds(
		sections.map(s => s.id!),
		hashmapLocations,
		true,
	);
}

export function getBuildingByAnimalType(hashmapLocations: any, animalType: AnimalType) {
	const state = StoreContainer.getState();
	let sections = state.locations.sections.filter(sec => sec.animalType === animalType);
	let buildingIds = sections.map(s => s.buildingId).filter(onlyUniqueFilter);
	return buildingIds.map(b => hashmapLocations[b!]);
}

export function getPenOptionsBySectionId(hashmapLocations: any, sectionId: string) {
	const state = StoreContainer.getState();
	let pens = state.locations.pens.filter(pen => pen.sectionId === sectionId);
	pens = pens.sort((a, b) => NaturalSort(a.order, b.order));
	return pens.map(pen => {
		return { label: pen.name ? pen.name : '', value: pen.id! };
	});
}

export const sectionTypeUseDistriwin = (sectionType: LocationType, sections: ISection[], processEquipmentData: IProcessEquipmentData[]) => {
	// Check if any sections with section type is in use by Distriwin
	const sectionsWithSecetionType = new Set(sections.filter(s => s.type === sectionType).map(s => s.id));
	return processEquipmentData.some(p => p.sectionId && sectionsWithSecetionType.has(p.sectionId));
}


export const getLocationStringByLocationCode = (code: string, deviceType: SourceSystem) => {
	const locations = StoreContainer.getState().locations;
	const esfStations = StoreContainer.getState().station.data;
	const unitGroupId = deviceType === SourceSystem.Distriwin ? locations.valves.find(v => v.locationCode === code)?.valveGroupId : esfStations.find(v => v.code?.includes(code))?.unitGroupId;

	const utp = StoreContainer.getState().unitToPenData.data.find(u => u.unitGroupId === unitGroupId);
	const pen = locations.pens.find(p => p.id === utp?.penId);
	const section = locations.sections.find(s => s.id === pen?.sectionId);
	const building = locations.buildings.find(b => b.id === pen?.buildingId);
	return formatLocationString(building, section, pen);

}
