import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AdjustStorageIcon from 'shared/assets/src-assets/png/juster_lager_grey2.png';
import FeedingLogIcon from 'shared/assets/src-assets/png/FoderLog_Grey.png';
import AddFeedingUsageIcon from 'shared/assets/src-assets/png/OpretForbrug_Grey.png';
import AddFeeding from 'shared/assets/src-assets/png/OpretFoder_Grey.png';
import CreateRelationIcon from 'shared/assets/src-assets/png/icon_feed_light.svg';
import { GetSyncData } from 'shared/state/ducks/feeding-categories/operations';
import * as feedingSubjectsOperations from 'shared/state/ducks/feeding-subjects/operations';
import { GetFeedings } from 'shared/state/ducks/feeding/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import AddFood from 'web/view/components/feeding/add-food';
import CreateFoodUsage from 'web/view/components/feeding/create-food-usage';
import FeedingLog from 'web/view/components/feeding/feeding-log';
import { FeedingAdjustmentTableModel } from 'web/view/components/feeding/feeding-table-model';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import 'web/view/styles/stock-styles/stock-styles.scss';
import { FeedingAmountType, FeedingAdjustment, IUserProfile, DistriwinFeedUnitType, Equipment } from 'shared/api/api';
import { GetFeedingSubject, GetFeedingCategory, GetFeedingName } from 'web/view/components/feeding/food-helper';
import { ViewWeb } from 'web/view/components/utils/web-view';
import { GetLocations } from 'shared/state/ducks/locations/operations';
import { CreateDistriwinFeedingRelation } from 'web/view/components/feeding/create-distriwin-feeding-relation/create-distriwin-feeding-relation';
import { getFeedUnitType } from 'shared/helpers/feeding/feeding-helper';
import { RecipeOverview } from 'web/view/components/feeding/recipes/recipe-overview';

interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		navigation: state.navigation,
		userProfile: state.profile.active!,
		feedingSubjects: state.feedingSubject.entities,
		feedingCategory: state.feedingCategory.entities,
		feedings: state.feeding.feedings.filter(d => d && !d.isDeleted),
		feedUnitType: getFeedUnitType(state),
		hasDistriwin: state.processEquipments.entities.find(e => e.equipment === Equipment.Distriwin),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getfeedings: (siteId: string, userProfile: IUserProfile) => GetFeedings(siteId, userProfile)(dispatch),
	getFeedingCategories: profile => GetSyncData(profile)(dispatch),
	getFeedingSubjects: (siteId: string) => feedingSubjectsOperations.GetSyncData(siteId)(dispatch),
	GetLocations: () => GetLocations()(dispatch),
});

interface State {
	createOrPurchaseModalOpen: boolean;
	feedingLogModalOpen: boolean;
	feedingSubjectIdToEdit?: string;
	createUsageModalOpen: boolean;
	columnExte: any[];
	columns: any[];
	animalTypesToInclude: any[];
	createRelationModalOpen: boolean;
	recipeModal: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class FeedingStockOverview extends React.PureComponent<Props, State> {
	private generateData = memoize(feedings => {
		return this.getData();
	});

	constructor(props: Props) {
		super(props);
		this.state = {
			createOrPurchaseModalOpen: false,
			createUsageModalOpen: false,
			feedingLogModalOpen: false,
			createRelationModalOpen: false,
			feedingSubjectIdToEdit: undefined,
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			animalTypesToInclude: this.props.navigation.payload.type,
			recipeModal: false,
		};

		if (this.props.siteId) {
			this.getFeedings();
			this.props.getFeedingCategories(this.props.userProfile);
			this.props.getFeedingSubjects(this.props.siteId);
			this.props.GetLocations();
		}
	}

	private defaultSorting = [{ columnName: 'Energy1PerKg', direction: 'asc' }] as Sorting[];
	public generateColumnsExtensions() {
		let columnExts = [
			{
				columnName: 'feedingSubject',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'feedingCategory',
				width: 160,
			},
			{
				columnName: 'Energy1PerKg',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'pricePer100Kg',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'updateCurrentTotal',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'purchase',
				width: SowListConstants.animalNrWidth,
			},
		];
		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columnExts.splice(3, 0, {
				columnName: 'Energy2PerKg',
				width: SowListConstants.animalNrWidth,
			});
		}

		return columnExts;
	}
	public render() {
		const data = this.generateData(this.props.feedings);
		return (
			<PageContainer>
				<Heading text={localized('FeedingStock')} />
				<ViewWeb className="stock-overview">
					<ViewWeb className="headerViewStyle">
						<ViewWeb className="stock-start stock-sizing verticalCenter buttonStyling">
							{this.renderButtonsForStock()}
						</ViewWeb>
					</ViewWeb>

					<ViewWeb className="outer-div">
						<SkioldTableGrid
							tableKey={'feedingStockOverviewListTable'}
							columns={this.state.columns}
							ColumnExtensions={this.state.columnExte}
							data={data}
							resizeAbleEnabled={true}
							ignoreSetCount={true}
							sortHeaderId={this.defaultSorting}
						/>
					</ViewWeb>

					<SkioldModal padding="0" isOpen={this.state.createOrPurchaseModalOpen} close={this.closeModal}>
						<AddFood closeModal={this.closeModal} feedingSubjectId={this.state.feedingSubjectIdToEdit} />
					</SkioldModal>
					<SkioldModal padding="10" isOpen={this.state.feedingLogModalOpen} close={this.closeModal}>
						<FeedingLog closeModal={this.closeModal} />
					</SkioldModal>
					<SkioldModal padding="0" isOpen={this.state.createUsageModalOpen} close={this.closeModal}>
						<CreateFoodUsage closeModal={this.closeModal} />
					</SkioldModal>
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.createRelationModalOpen}
						close={this.closeModal}
					>
						<CreateDistriwinFeedingRelation closeModal={this.closeModal} />
					</SkioldModal>
					<SkioldModal padding="0" isOpen={this.state.recipeModal} close={this.closeModal}>
						<RecipeOverview closeModal={this.closeModal} />
					</SkioldModal>
				</ViewWeb>
			</PageContainer>
		);
	}

	private getFeedings = () => {
		if (this.props.siteId) {
			this.props.getfeedings(this.props.siteId, this.props.userProfile);
		}
	};

	private OpenRecipeModal = () => {
		this.setState({ recipeModal: true });
	};

	private OpenFeedingLogModal = () => {
		this.setState({ feedingLogModalOpen: true });
	};
	private OpenCreateUsageModal = () => {
		this.setState({ createUsageModalOpen: true });
	};
	private renderButtonsForStock() {
		return (
			<ViewWeb className="buttons-stock">
				{/* <ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={this.OpenRecipeModal}>
						<SkioldImage width="60" height="60" imageData={FeedingLogIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('Recipes')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="dotted-headerE" /> */}
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={this.OpenCreateUsageModal}>
						<SkioldImage width="60" height="60" imageData={AddFeedingUsageIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('CreateUsage')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="dotted-header" />
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={this.OpenFeedingLogModal}>
						<SkioldImage width="60" height="60" imageData={FeedingLogIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('FeedingLog')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="dotted-header" />
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={this.openCreateModal}>
						<SkioldImage width="60" height="60" imageData={AddFeeding} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('CreateFood')}</WhiteText>
				</ViewWeb>
				{this.props.hasDistriwin && (
					<>
						<ViewWeb className="dotted-header" />
						<ViewWeb className="verticalCenter">
							<SkioldTouchableOpacity
								className={'positionRelative'}
								onPress={this.openCreateRelationModal}
							>
								<SkioldImage width="60" height="60" imageData={CreateRelationIcon} />
							</SkioldTouchableOpacity>
							<WhiteText>{localized('createRelation')}</WhiteText>
						</ViewWeb>
					</>
				)}
			</ViewWeb>
		);
	}

	private getData() {
		let feedingAdjustmentTableModels: FeedingAdjustmentTableModel[] = [];
		this.props.feedings.forEach(feeding => {
			let FeedingAdjustmentTableModel = {
				FeedingSubjectId: feeding.feedingSubjectId,
			} as FeedingAdjustmentTableModel;
			const feedingSubject = GetFeedingSubject(this.props.feedingSubjects, feeding.feedingSubjectId!);
			if (feedingSubject) {
				FeedingAdjustmentTableModel.FeedingSubject = GetFeedingName(feedingSubject, this.props.userProfile);
				const feedingCategory = GetFeedingCategory(
					this.props.feedingCategory,
					feedingSubject!.feedingCategoryId!,
				);
				if (feedingCategory) {
					FeedingAdjustmentTableModel.FeedingCategory = GetFeedingName(
						feedingCategory,
						this.props.userProfile,
					);
				}
			}
			const totalAmountInFeedingUsages = feeding
				.adjustments!.filter(a => a.type === FeedingAmountType.FeedingUsage && !a.isDeleted)
				.reduce((a, b) => a + (b.amount ? b.amount : 0), 0); // Finds the total amount used
			const FeedingAdjustments = feeding.adjustments!.filter(
				a => a.type === FeedingAmountType.FeedingAdjustment && !a.isDeleted,
			);

			let energy1Accumulator = 0;
			let priceAccumulator = 0;
			let energy2Accumulator = 0;
			for (let index = 0; index < FeedingAdjustments.length; index++) {
				const adjustment = FeedingAdjustments![index] as FeedingAdjustment;
				FeedingAdjustmentTableModel.amount =
					adjustment.amount! + (FeedingAdjustmentTableModel.amount ? FeedingAdjustmentTableModel.amount : 0); // sums all adjustments amounts up

				let amountLeft = FeedingAdjustmentTableModel.amount - totalAmountInFeedingUsages;
				if (amountLeft > 0) {
					if (amountLeft > adjustment.amount!) {
						energy1Accumulator += adjustment.amount! * adjustment.energy1PerKg!;
						priceAccumulator += adjustment.amount! * adjustment.pricePer100Kg!;
						energy2Accumulator += adjustment.amount! * adjustment.energy2PerKg!;
					} else {
						energy1Accumulator += amountLeft * adjustment.energy1PerKg!;
						priceAccumulator += amountLeft * adjustment.pricePer100Kg!;
						energy2Accumulator += amountLeft * adjustment.energy2PerKg!;
					}
				}
			}
			FeedingAdjustmentTableModel.amount =
				(FeedingAdjustmentTableModel.amount ? FeedingAdjustmentTableModel.amount : 0) -
				totalAmountInFeedingUsages;
			FeedingAdjustmentTableModel.energy1PerKg =
				energy1Accumulator / (FeedingAdjustmentTableModel.amount ? FeedingAdjustmentTableModel.amount : 1);
			FeedingAdjustmentTableModel.pricePer100Kg =
				priceAccumulator / (FeedingAdjustmentTableModel.amount ? FeedingAdjustmentTableModel.amount : 1);
			FeedingAdjustmentTableModel.energy2PerKg =
				energy2Accumulator / (FeedingAdjustmentTableModel.amount ? FeedingAdjustmentTableModel.amount : 1);
			feedingAdjustmentTableModels.push(FeedingAdjustmentTableModel);
		});
		return feedingAdjustmentTableModels;
	}

	private generateColumns(): any {
		let columns = [
			{
				name: 'feedingSubject',
				title: localized('FeedingSubject'),
				getCellValue: (feeding: FeedingAdjustmentTableModel) => feeding.FeedingSubject,
			},
			{
				name: 'feedingCategory',
				title: localized('Type'),
				getCellValue: (feeding: FeedingAdjustmentTableModel) => feeding.FeedingCategory,
			},
			{
				name: 'Energy1PerKg',
				title: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy1'),
				getCellValue: (feeding: FeedingAdjustmentTableModel) =>
					feeding.energy1PerKg !== undefined ? feeding.energy1PerKg.toFixed(2) : '',
			},

			{
				name: 'pricePer100Kg',
				title: localized('PricePer100Kgs'),
				getCellValue: (feeding: FeedingAdjustmentTableModel) =>
					feeding.pricePer100Kg !== undefined ? feeding.pricePer100Kg.toFixed(2) : '',
			},
			{
				name: 'updateCurrentTotal',
				title: localized('UpdateInventory'),
				filterable: false,
				getCellValue: (feeding: FeedingAdjustmentTableModel) =>
					feeding.amount !== undefined ? feeding.amount.toFixed(1) : '',
			},
			{
				name: 'purchase',
				title: localized('purchase'),
				filterable: false,
				getCellValue: (feeding: FeedingAdjustmentTableModel) => (
					<ViewWeb className="verticalCenter">
						<SkioldTouchableOpacity onPress={() => this.openPurchaseModal(feeding.FeedingSubjectId)}>
							<SkioldImage
								width={SowListConstants.iconSVGWidth}
								height={SowListConstants.iconSVGWidth}
								imageData={AdjustStorageIcon}
							/>
						</SkioldTouchableOpacity>
					</ViewWeb>
				),
			},
		];

		if (this.props.feedUnitType === DistriwinFeedUnitType.FEsoFEsv) {
			columns.splice(3, 0, {
				name: 'Energy2PerKg',
				title: localizedInterpolation('EnergyKg', this.props.feedUnitType + 'Energy2'),
				getCellValue: (feeding: FeedingAdjustmentTableModel) =>
					feeding.energy2PerKg !== undefined ? feeding.energy2PerKg.toFixed(2) : '',
			});
		}
		return columns;
	}

	private openPurchaseModal(feedingSubjectId: string | undefined) {
		this.setState({ createOrPurchaseModalOpen: true, feedingSubjectIdToEdit: feedingSubjectId });
	}

	private openCreateModal = () => {
		this.setState({ createOrPurchaseModalOpen: true, feedingSubjectIdToEdit: undefined });
	};

	private closeModal = () => {
		this.setState({
			createOrPurchaseModalOpen: false,
			createUsageModalOpen: false,
			feedingLogModalOpen: false,
			createRelationModalOpen: false,
			recipeModal: false,
		});
		if (this.props.siteId) {
			this.props.getfeedings(this.props.siteId, this.props.userProfile);
		}
	};

	private openCreateRelationModal = () => {
		this.setState({ createRelationModalOpen: true, feedingSubjectIdToEdit: undefined });
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedingStockOverview);
