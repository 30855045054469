import ObjectID from 'bson-objectid';
import { Dispatch } from 'redux';
import {
	FarrowingTaskTreatmentColumn,
	TreatmentFrequency,
	Gender,
	ITasksInFarrowingBase,
	ITreatmentDefinition,
	ITreatmentPlan,
	IVaccineListSetting,
	Treatment,
	TreatmentPlan,
	WorkListToTreatmentPlan,
	IDiagnose,
	AnimalType,
	IWorkListToTreatmentPlan,
	ITreatment,
	VaccinePregnancyType,
} from 'shared/api/api';
import { Monitoring } from 'shared/helpers/monitoring-service';
import { AsyncOperationBuilder2 } from 'shared/helpers/redux-helpers';
import { TasksInFarrowingStableItem } from 'shared/helpers/work-list-helpers/tasks-in-farrowing-stable-helper/tasks-in-farrowing-stable-item';
import { VaccineWorkListItem } from 'shared/helpers/work-list-helpers/vaccine-list-helpers/vaccine-worklist-items';
import { StoreContainer } from 'shared/state/store-container';
import { RemoveTreatmentByAnimal, SaveManyTreatments, SaveTreatmentList } from '../treatment/operations';
import { SaveWorkListToTreatmentPlan } from '../work-list-to-treatment-plan/operations';
import * as Action from './actions';
import { getInterval } from 'shared/helpers/treatment-helper/treatment-helper';
import { createSelector } from '@reduxjs/toolkit';
import { mergeArrays } from 'shared/helpers/reducer-helpers';
import { SharedAppState } from 'shared/state/store.shared';

export function GetTreatmentPlansForLog(siteId: string, utcStart: Date, utcEnd: Date) {
	return AsyncOperationBuilder2(
		Action.getTreatmentPlansWithCompleted,
		client => client.treatmentPlan_GetTreatmentPlansWithCompletedBySiteId(siteId, utcStart, utcEnd),
		{ siteId },
	);
}

const createRegistration = (
	treatmentPlan: ITreatmentPlan,
	startDate: Date,
	profileId: string,
	animalType?: AnimalType,
	sectionId?: string,
	penId?: string,
	severity?: number,
	amount?: number,
	pigletCount?: number,
) => {
	let genDate = new Date(startDate);
	let treatment = Treatment.fromJS({});
	treatment.id = new ObjectID().toHexString();
	treatment.plannedDate = genDate;
	treatment.treatmentPlanId = treatmentPlan.id;
	treatment.siteId = treatmentPlan.siteId;
	treatment.animalWeight = 0;
	treatment.amount = amount;
	treatment.feedWeight = 0;
	treatment.pigletAmount = pigletCount;
	treatment.treatmentNumber = 1;
	treatment.sectionId = sectionId;
	treatment.penId = penId;
	treatment.animalType = animalType ?? AnimalType.Unknown;
	treatment.severity = severity;
	treatment.executedDate = treatment.plannedDate;
	treatment.executedByProfileId = profileId;
	treatment.isRegistration = true;

	return treatment;
};

export function SaveTreatmentPlan(
	treatmentPlan: ITreatmentPlan,
	treatmentDefinition: ITreatmentDefinition,
	startDate: Date,
	isTreated: boolean,
	profileId: string,
	feedWeight?: number,
	amount?: number,
	animalWeight?: number,
	pigletCount?: number,
	animalType?: AnimalType,
	sectionId?: string,
	penId?: string,
	severity?: number,
) {
	if (!treatmentPlan.id) {
		treatmentPlan.id = new ObjectID().toHexString();
	}

	//Generate treatments:
	let treatments = new Array<Treatment>();

	if (treatmentPlan.isRegistration) {
		treatments = [
			createRegistration(
				treatmentPlan,
				startDate,
				profileId,
				animalType,
				sectionId,
				penId,
				severity,
				amount,
				pigletCount,
			),
		];
	} else {
		for (let i = 0; i < treatmentDefinition.numberOfTreatments!; i++) {
			let genDate = new Date(startDate);
			let interval = getInterval(treatmentDefinition.frequency!);
			genDate.setDate(startDate.getDate() + interval * i);

			let treatment = Treatment.fromJS({});
			treatment.id = new ObjectID().toHexString();
			treatment.plannedDate = genDate;
			treatment.treatmentPlanId = treatmentPlan.id;
			treatment.siteId = treatmentPlan.siteId;
			treatment.animalWeight = animalWeight;
			treatment.amount = amount;
			treatment.feedWeight = feedWeight;
			treatment.pigletAmount = pigletCount;
			treatment.treatmentNumber = i + 1;
			treatment.sectionId = sectionId;
			treatment.penId = penId;
		treatment.animalType = animalType!;
			treatment.severity = severity;

			if (i === 0 && isTreated) {
				treatment.executedDate = treatment.plannedDate;
				treatment.executedByProfileId = profileId;
			}

			treatments.push(treatment);
		}
	}

	return async (dispatch: Dispatch<any>) => {
		postTreatmentPlan(treatmentPlan)(dispatch)
			.then(() => {
				SaveTreatmentList(treatments, treatmentPlan)(dispatch);
			})
			.catch(() => {
				SaveTreatmentList(treatments, treatmentPlan)(dispatch);
			});
	};
}

export function SaveMultipleVaccinesByWorkList(setting: IVaccineListSetting, items: VaccineWorkListItem[]) {
	const state = StoreContainer.getState();
	const profileId = state.profile.active!.id;
	const siteId = state.profile.active!.siteId;
	const treatDefsIds = setting.vaccineSubTasks!.map(subTask => subTask.vaccineDefinitionId);
	const treatmentDefs = state.treatmentDefinitions.entities.filter(def => treatDefsIds.includes(def.id));
	const animalType = setting.vaccinePregnancyType !== VaccinePregnancyType.AgeYoungFemale ? AnimalType.Sow : AnimalType.YoungFemale;
	return async (dispatch: Dispatch<any>) => {
		let promises = new Array<Promise<void>>();
		items.forEach(async item => {
			item.vaccineSubColumns!.forEach(column => {
				const treatmentDefinition = treatmentDefs.find(def => def.id === column.treatmentDefinitionId);
				if (treatmentDefinition) {
					const treatplan = TreatmentPlan.fromJS({
						stemAnimalId: item.stemAnimalId,
						gender: Gender.Female,
						isSowActive: true,
						siteId,
						treatmentDefinitionId: column.treatmentDefinitionId,
						id: new ObjectID().toHexString(),
					} as ITreatmentPlan);
					let workListToTreatmentPlan = WorkListToTreatmentPlan.fromJS({
						treatmentPlanId: treatplan.id,
						workListSettingId: setting.id,
						subTaskIdentifier: item.identifier! + column.subTaskNumber!,
						diagnoseIdentifier: treatmentDefinition.diagnoseId,
						siteId,
					} as IWorkListToTreatmentPlan);

					promises.push(
						SaveTreatmentPlan(
							treatplan,
							treatmentDefinition,
							new Date(),
							true,
							profileId!,
							0,
							0,
							0,
							0,
							animalType
						)(dispatch),
					);

					promises.push(SaveWorkListToTreatmentPlan(workListToTreatmentPlan)(dispatch));
				}
			});
		});
		return await Promise.all(promises);
	};
}

export function SaveMultipleTreatmentPlansByWorkList(
	setting: ITasksInFarrowingBase,
	items: TasksInFarrowingStableItem[],
) {
	const state = StoreContainer.getState();
	const profileId = state.profile.active!.id;
	const siteId = state.profile.active!.siteId;
	return async (dispatch: Dispatch<any>) => {
		let promises = new Array<Promise<void>>();
		items.forEach(async item => {
			item.farrowingTaskTreatments!.forEach(subtask => {
				if (!subtask.used) {
					const treatDef = state.treatmentDefinitions.entities.find(
						def =>
							def.id ===
							setting.farrowingTaskTreatments!.find(
								subTask => subTask.subTaskNumber === subtask.subTaskNumber,
							)!.treatmentDefinitionId,
					);

					if (treatDef) {
						const diagnose = state.diagnose.entities.find(diag => diag.id === treatDef.diagnoseId);
						if (diagnose) {
							promises.push(SaveItem(item, siteId!, profileId!, setting, treatDef, diagnose)(dispatch));
						}
					}
				}
			});
		});
		return await Promise.all(promises);
	};
}

function SaveItem(
	task: TasksInFarrowingStableItem,
	siteId: string,
	profileId: string,
	setting: ITasksInFarrowingBase,
	treatDef: ITreatmentDefinition,
	diagnose: IDiagnose,
) {
	return async (dispatch: Dispatch<any>) => {
		if (treatDef) {
			const treatplan = createTreatmentPlan(treatDef.id);
			let workListToTreatmentPlan = WorkListToTreatmentPlan.fromJS({
				treatmentPlanId: treatplan.id,
				workListSettingId: setting.id,
				diagnoseIdentifier: task.stemAnimalId + diagnose.id! + diagnose.diagnoseCategoryId,
				subTaskIdentifier: task.stemAnimalId + treatDef.id!,
				siteId,
			} as IWorkListToTreatmentPlan);
			saveCreatedTreatmentPlan(
				treatplan,
				treatDef,
				task.farrowingTaskTreatments!.find(subTask => subTask.treatmentDefinitionId === treatDef.id),
			)(dispatch);
			SaveWorkListToTreatmentPlan(workListToTreatmentPlan)(dispatch);
		}
	};

	function saveCreatedTreatmentPlan(
		treatplan: TreatmentPlan,
		treatDef: ITreatmentDefinition,
		farrowingTaskTreatment: FarrowingTaskTreatmentColumn | undefined | any,
	) {
		return SaveTreatmentPlan(
			treatplan,
			treatDef,
			new Date(),
			true,
			profileId,
			0,
			0,
			0,
			farrowingTaskTreatment!.pcs!
		);
	}

	function createTreatmentPlan(treatmentDefinitionId: string | undefined) {
		return TreatmentPlan.fromJS({
			stemAnimalId: task.stemAnimalId,
			gender: Gender.Female,
			id: new ObjectID().toHexString(),
			isSowActive: true,
			siteId,
			treatmentDefinitionId,
		} as ITreatmentPlan);
	}
}

function postTreatmentPlan(treatmentPlan: ITreatmentPlan) {
	const state = StoreContainer.getState();
	const prevEntity = state.treatmentPlans.entities.find(treatPlan => treatPlan.id === treatmentPlan.id);

	return AsyncOperationBuilder2(
		Action.saveTreatmentPlan,
		client => client.treatmentPlan_Post(TreatmentPlan.fromJS(treatmentPlan)),
		TreatmentPlan.fromJS(treatmentPlan),
		prevEntity,
		undefined,
		undefined,
		0,
	);
}

export function UpdateTreatmentPlansForDepartedSow(stemAnimalId: string, isSowActive: boolean) {
	const state = StoreContainer.getState();
	return async (dispatch: Dispatch<any>) => {
		for (let index = 0; index < state.treatmentPlans.entities.length; index++) {
			const treatmentPlan = state.treatmentPlans.entities[index];
			if (treatmentPlan.stemAnimalId === stemAnimalId) {
				treatmentPlan.isSowActive = isSowActive;
				UpdateTreatmentPlan(treatmentPlan)(dispatch);
				if (!isSowActive) {
					RemoveTreatmentPlansByAnimal(stemAnimalId)(dispatch);
				}
			}
		}
	};
}

export function UpdateTreatmentPlan(treatmentPlan: ITreatmentPlan) {
	const state = StoreContainer.getState();
	const prevEntity = state.treatmentPlans.entities.find(treatPlan => treatPlan.id === treatmentPlan.id);

	return AsyncOperationBuilder2(
		Action.saveTreatmentPlan,
		client => client.treatmentPlan_Put(TreatmentPlan.fromJS(treatmentPlan)),
		TreatmentPlan.fromJS(treatmentPlan),
		prevEntity,
	);
}

export function GetSyncData() {
	const state = StoreContainer.getState();
	const siteId = state.profile.active!.siteId!;
	const lastSyncDate = state.treatmentPlans.lastSyncDate;
	if (state.treatmentPlans.syncInProgress) {
		return (dispatch: Dispatch<any>) => {
			return Promise.resolve();
		};
	}
	return AsyncOperationBuilder2(Action.getSyncData, client => client.treatmentPlan_Sync(siteId, lastSyncDate), {
		siteId,
		lastSyncDate,
	});
}

export function SaveSyncData() {
	const state = StoreContainer.getState();
	const updates = state.treatmentPlans.updates;
	let promises = new Array<Promise<void>>();

	return async (dispatch: Dispatch<any>) => {
		if (state.treatmentPlans.saveSyncInProgress) {
			return Promise.resolve();
		}
		for (const update of updates) {
			try {
				promises.push(postTreatmentPlan(update)(dispatch));
			} catch (error) {
				Monitoring.logException(error as Error);
			}
		}
		return await Promise.all(promises);
	};
}

export function GetTreatmentPlansByStemAnimalId(stemAnimalId: string) {
	return AsyncOperationBuilder2(
		Action.getTreatmentPlansByStemAnimalId,
		client => client.treatmentPlan_GetTreatmentPlansByStemAnimalId(stemAnimalId),
		stemAnimalId,
	);
}

export function RemoveTreatmentPlansByAnimal(id: string) {
	const state = StoreContainer.getState();
	const treatmentPlanIds = state.treatmentPlans.entities
		.filter((treatmentPlan: ITreatmentPlan) => treatmentPlan.stemAnimalId === id)
		.map(e => e.id);
	return (dispatch: Dispatch<any>) => {
		dispatch(Action.removeTreatmentPlansByAnimal(id));
		treatmentPlanIds.forEach(treatmentPlanId => {
			RemoveTreatmentByAnimal(treatmentPlanId!)(dispatch);
		});
	};
}

export function saveManyTreatmentPlans(treatmentPlans: ITreatmentPlan[], treatments: ITreatment[]) {
	return AsyncOperationBuilder2(
		Action.saveManyTreatmentPlans,
		client => client.treatmentPlan_CreateManyTreatmentPlans(treatmentPlans.map(tp => TreatmentPlan.fromJS(tp))),
		treatmentPlans,
		[],
		undefined,
		(result: any, d: Dispatch<any>) => {
			SaveManyTreatments(treatments)(d);
		},
		0,
	);
}

export const selectTreatmentPlans = createSelector(
	(store: SharedAppState) => store.treatmentPlans,
	treatments => mergeArrays(treatments.entities, treatments.treatmentPlansForLog),
);

export const selectTreatmentPlansBySowId = createSelector(
	selectTreatmentPlans,
	(_: ITreatmentPlan[], sowId: string | undefined) => sowId,
	(treatmentPlans: ITreatmentPlan[], sowId: string | undefined) =>
		treatmentPlans.filter(t => t.stemAnimalId === sowId),
);

