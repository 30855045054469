import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { SharedAppState } from 'shared/state/store.shared';
import { TreatmentDefinitionState } from './types';
import { SyncableInitialState } from 'shared/state/models/syncable';
import { mergeArrays, upsertValueInArray, removeValueFromArray } from 'shared/helpers/reducer-helpers';
import { saveTreatmentDefinition, getSyncData, getArchivedTreatmentDefinitions, archiveTreatmentDefinition, deArchiveTreatmentDefinition } from './actions';
import { siteChange } from '../profile/actions';
import { TreatmentDefinition, ITreatmentDefinition } from 'shared/api/api';
import { getSyncModelData } from '../sync/actions';

export const initialState: TreatmentDefinitionState = {
	...SyncableInitialState,
	arhivedTreatmentDefinitions: []
};

const treatmentDefinitionReducer = (
	state: TreatmentDefinitionState = initialState,
	action: Action,
): TreatmentDefinitionState => {
	if (isType(action, getSyncData.started)) {
		state = { ...state, syncInProgress: true };
	}

	if (isType(action, getSyncData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (action.payload.result.datas!.length) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.datas!),
				};
			}

			state = {
				...state,
				lastSyncDate: action.payload.result.syncTime!,
				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncModelData.done)) {
		if (action.payload.result && action.payload.params.siteId === action.payload.params.activeSiteId) {
			if (
				action.payload.result.treatmentDefinitions &&
				action.payload.result.treatmentDefinitions.datas!.length
			) {
				state = {
					...state,
					entities: mergeArrays(state.entities, action.payload.result.treatmentDefinitions.datas!),
					lastSyncDate: action.payload.result.treatmentDefinitions.syncTime!,
				};
			}

			state = {
				...state,

				syncInProgress: false,
			};
		} else {
			state = {
				...state,
				syncInProgress: false,
			};
		}
	}

	if (isType(action, getSyncData.failed)) {
		state = { ...state, syncInProgress: false };
	}

	if (isType(action, saveTreatmentDefinition.started)) {
		state = {
			...state,
			updates: upsertValueInArray(state.updates, action.payload),
			entities: upsertValueInArray(state.entities, action.payload),
			saveSyncInProgress: true,
		};

		return state;
	}

	if (isType(action, saveTreatmentDefinition.failed)) {
		if (action.payload.error.code === 500 && action.payload.params) {
			if (action.payload.error.prevEntity) {
				state = { ...state, entities: upsertValueInArray(state.entities, action.payload.error.prevEntity) };
			} else {
				state = { ...state, entities: removeValueFromArray(state.entities, action.payload.params.id!) };
			}
			state = { ...state, updates: removeValueFromArray(state.updates, action.payload.params.id!) };
		}
		state = { ...state, saveSyncInProgress: false };
		return state;
	}

	if (isType(action, saveTreatmentDefinition.done)) {
		if (action.payload.params) {
			state = {
				...state,
				entities: upsertValueInArray(state.entities, action.payload.params),
			};
			state = {
				...state,
				updates: removeValueFromArray(state.updates, action.payload.params.id!),
				saveSyncInProgress: false,
			};
		}

		return state;
	}

	if (isType(action, siteChange.done)) {
		state = initialState;
	}

	if (isType(action, getArchivedTreatmentDefinitions.done)) {
		state = { ...state, arhivedTreatmentDefinitions: action.payload.result };
	}

	if (isType(action, archiveTreatmentDefinition.done)) {
		state = {
			...state,
			entities: upsertValueInArray(state.entities, action.payload.result)
		};
	}

	if (isType(action, deArchiveTreatmentDefinition.done)) {
		state = {
			...state,
			entities: upsertValueInArray(state.entities, action.payload.result)
		};
	}

	//Ensure Date objects are in fact date and not strings - redux-persist serializes dates to string but dont' deserialize to dates again
	if (action.type === 'persist/REHYDRATE') {
		let a = (action as any) as { payload: SharedAppState; key: string };

		if (a.key === 'root') {
			let entities = new Array<TreatmentDefinition>();
			let updates = new Array<TreatmentDefinition>();
			let lastSyncDate = new Date(-8640000000000000);

			if (a.payload && a.payload.treatmentDefinitions && a.payload.treatmentDefinitions.entities) {
				entities = a.payload.treatmentDefinitions.entities.map((dt: ITreatmentDefinition) => {
					let ndt = TreatmentDefinition.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.treatmentDefinitions && a.payload.treatmentDefinitions.updates) {
				updates = a.payload.treatmentDefinitions.updates.map((dt: ITreatmentDefinition) => {
					let ndt = TreatmentDefinition.fromJS({});
					ndt.init(dt);
					return ndt;
				});
			}

			if (a.payload && a.payload.treatmentDefinitions && a.payload.treatmentDefinitions.lastSyncDate) {
				lastSyncDate = new Date(a.payload.treatmentDefinitions.lastSyncDate);
			}

			state = { ...state, entities, updates, lastSyncDate };
		}
	}

	return state;
};

export default treatmentDefinitionReducer;
