import React, { FC, useMemo, useState } from 'react';
import { Option } from 'react-dropdown';
import { useSelector } from 'react-redux';
import { AnimalType, IStemAnimal } from 'shared/api/api';
import { generateTreatmentDataSowCard } from 'shared/helpers/sow-card-helper/sow-card-helper';
import { selectTreatmentPlansBySowId } from 'shared/state/ducks/treatment-plan/operations';
import { selectTreatmentsByTreatmentPlanIds } from 'shared/state/ducks/treatment/operations';
import { localized } from 'shared/state/i18n/i18n';
import { SharedAppState } from 'shared/state/store.shared';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldDropdown } from '../../skiold-components/skiold-dropdown/skiold-dropdown';
import SkioldTableGrid from '../../skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { Heading } from '../../utils/heading';
import { ViewWeb } from '../../utils/web-view';
import './sow-card-modals.scss';

interface PropsFromParent {
	sow: IStemAnimal | undefined;
	closeModal?: () => void;
}

export const SowCardTreatment: FC<PropsFromParent> = React.memo(({ sow, closeModal }) => {
	// Setup state
	const [animalTypeOptions] = useState<Option[]>([
		{ value: AnimalType.Sow, label: localized("SowGilt") },
		{ value: AnimalType.Piglet, label: localized(AnimalType.Piglet) },
	]);
	const [selectedAnimalType, setSelectedAnimalType] = useState<Option>({
		value: AnimalType.Sow,
		label: localized("SowGilt"),
	});

	// Get data from store
	const diagnoses = useSelector((store: SharedAppState) => store.diagnose.entities);
	const treatmentDefinitions = useSelector((store: SharedAppState) => store.treatmentDefinitions.entities);
	const selectedTreatmentPlans = useSelector((store: SharedAppState) =>
		selectTreatmentPlansBySowId(store as any, sow?.id),
	);
	const selectedTreatments = useSelector((store: SharedAppState) =>
		selectTreatmentsByTreatmentPlanIds(
			store as any,
			selectedTreatmentPlans.map(t => t.id!),
		),
	);

	// Generate data
	const data = useMemo(() => {
		return generateTreatmentDataSowCard(
			selectedTreatmentPlans,
			selectedTreatments,
			selectedAnimalType.value as AnimalType,
			sow,
			treatmentDefinitions,
			diagnoses,
		);
	}, [selectedTreatmentPlans, selectedTreatments, selectedAnimalType, sow, treatmentDefinitions, diagnoses]);

	// Generate columns
	const columns = useMemo(() => {
		let col: any[] = [];

		col.push({
			name: 'litter',
			title: localized('Litters'),
			sortable: false,
			filterable: false,
		});
		col.push({
			name: 'treatmentDate',
			title: localized('Date'),
			sortable: false,
			filterable: false,
		});
		if (selectedAnimalType.value === AnimalType.Piglet) {
			col.push({
				name: 'numberOfPiglets',
				title: localized('numberOfPiglets'),
				sortable: false,
				filterable: false,
			});
		}
		col.push({
			name: 'diagnoseName',
			title: localized('Diagnose'),
			sortable: false,
			filterable: false,
		});
		return col;
	}, [selectedAnimalType]);

	// Generate column extensions
	const columnExtensions = useMemo(() => {
		let colExt: any[] = [];

		colExt.push({
			columnName: 'litter',
			width: 80,
		});
		colExt.push({
			columnName: 'treatmentDate',
			width: 105,
		});
		if (selectedAnimalType.value === AnimalType.Piglet) {
			colExt.push({
				columnName: 'numberOfPiglets',
				width: 80,
			});
		}
		colExt.push({
			columnName: 'diagnoseName',
			width: 275,
		});
		return colExt;
	}, [selectedAnimalType]);

	return (
		<ViewWeb>
			<Heading text={localized('Treatments')} />
			<ViewWeb className="sow-card-modals">
				<SkioldDropdown
					items={animalTypeOptions}
					selectedValue={selectedAnimalType}
					className="basic-dropdown"
					onValueChanged={setSelectedAnimalType}
				/>

				<SkioldTableGrid
					data={data}
					columns={columns}
					ColumnExtensions={columnExtensions}
					filterable={false}
					tableKey={'sowCardTreatments'}
				/>

				{closeModal && (
					<SkioldButton
						className="btn-container"
						title={localized('Close')}
						onPress={closeModal}
						theme="grey"
					/>
				)}
			</ViewWeb>
		</ViewWeb>
	);
});
