import React, { FC, useEffect, useState } from 'react';
import { localized } from 'shared/state/i18n/i18n';
import PageContainer from '../../page-container/page-container';
import { Heading } from '../../utils/heading';
import { SkioldFormsWrapper } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from '../../skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { Option } from 'react-dropdown';
import { AnimalType, IRecipeDto } from 'shared/api/api';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { ViewWeb } from '../../utils/web-view';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { useDispatch, useSelector } from 'react-redux';
import { getRecipes, upsertRecipe } from 'shared/state/ducks/recipe/operation';
import { showAlert } from '../../skiold-alert/skiold-alert';
import { selectSiteId } from 'shared/state/ducks/site/selectors';

interface PropsFromParent {
	recipe?: IRecipeDto;
	closeModal?: () => void;
}

export const CreateRecipe: FC<PropsFromParent> = React.memo(({ recipe, closeModal }) => {
	// init
	const dispatch = useDispatch();
	const siteId = useSelector(selectSiteId);
	const [recipeName, setRecipeName] = useState<string>(recipe?.name ?? '');

	// Setup animalType options
	const animalTypeOptions = [
		{ label: ' ', value: '' } as Option,
		{ label: localized(AnimalType.Sow), value: AnimalType.Sow } as Option,
		{ label: localized(AnimalType.YoungFemale), value: AnimalType.YoungFemale } as Option,
		{ label: localized(AnimalType.Weaner), value: AnimalType.Weaner } as Option,
		{ label: localized(AnimalType.Finisher), value: AnimalType.Finisher } as Option,
	];

	const [selectedOption, setSelectedOption] = useState<Option | undefined>(
		recipe?.animalType
			? { label: localized(recipe?.animalType), value: recipe?.animalType }
			: ({ label: ' ', value: '' } as Option),
	);

	// Form components
	const getWorkListHeader = (): FormRow => {
		return {
			header: localized('Weanings'),
		};
	};

	const getAnimalTypeRow = (): FormRow => {
		return {
			name: localized('production'),
			component: (
				<SkioldFormDropdown
					items={animalTypeOptions}
					selectedValue={selectedOption}
					onValueChanged={setSelectedOption}
				/>
			),
		};
	};

	const getFeedingSubjectInputRow = (): FormRow => {
		return {
			name: localized('RecipeName'),
			component: <SkioldFormInput onChangeText={setRecipeName} text={recipeName} />,
		};
	};

	const getFormRows = () => {
		let formRows = new Array<FormRow>();

		formRows.push(getWorkListHeader());
		formRows.push(getAnimalTypeRow());
		formRows.push(getFeedingSubjectInputRow());
		return formRows;
	};

	const saveRecipe = () => {
		if (selectedOption?.value === undefined) {
			showAlert(localized('selectAnimalKind'));
			return;
		}

		dispatch(
			upsertRecipe({
				id: recipe?.id,
				name: recipeName,
				animalType: selectedOption?.value as AnimalType,
				isSkioldDigitalRecipe: true,
				siteId,
			} as IRecipeDto),
		);
		closeModal && closeModal();
	};

	return (
		<ViewWeb className="create-recipe">
			<SkioldFormsWrapper formRows={getFormRows()} containerClassName="wrapper-container" />
			<div>
				<SkioldButton title={localized('Save')} onPress={saveRecipe} />
				<SkioldButton theme="grey" title={localized('Close')} onPress={closeModal} />
			</div>
		</ViewWeb>
	);
});
