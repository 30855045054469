import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalType, IDiagnose, ITreatmentDefinition } from 'shared/api/api';
import TreatmentDefinitionOn from 'shared/assets/src-assets/png/behandlingsdefinitioner.png';
import PenIcon from 'shared/assets/src-assets/png/pen_icon.png';
import { getAnimalKindString } from 'shared/helpers/animal-kinds-helpers';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { getDrugsText } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { getAnimalTypesForTreatments } from 'shared/helpers/treatment-helper/treatment-helper';
import { GetSyncData as DiagnoseCategoriesGetSyncData } from 'shared/state/ducks/diagnoseCategories/operations';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetDrugs } from 'shared/state/ducks/drug/operations';
import { GetSyncData as DrugTypeGetSyncData } from 'shared/state/ducks/drugTypes/operations';
import { GetSyncData as TreatmentDefinitionsGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import { Row, SkioldTable } from 'web/view/components/skiold-components/skiold-table/skiold-table';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { WhiteText } from 'web/view/components/Text/white-text';
import AddTreatmentDefinitionWrapper from 'web/view/components/treatments/treatment-definition/add-treatment-definition-wrapper';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import './treatment-definitions.scss';
import { ClipLoader } from 'react-spinners';
import { TreatmentDefinitionArchived } from './treatment-definitions-archived';
import MedicinArchive from 'shared/assets/src-assets/medicine-archive.svg';
export interface TreatmentDefinitionTableModel {
	categoryId: string;
	categoryName: string;
	treatmentDefinition: ITreatmentDefinition;
	diagnose: IDiagnose;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		treatmentDefinitions: state.treatmentDefinitions.entities,
		categories: state.diagnoseCategory.entities,
		diagnoses: state.diagnose.entities,
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		language: state.profile.active!.language,
		drugTypes: state.drugType.entities,
		isFetching: state.drugs.isFetching && state.drugs.drugs.length <= 0,
		animalTypes:
			state.navigation.query && state.navigation.query.type
				? (state.navigation.query.type as AnimalType)
				: AnimalType.Sow,
	};
};
const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
		getDiagnoseCategories: () => DiagnoseCategoriesGetSyncData()(dispatch),
		getTreatmentDefinitions: () => TreatmentDefinitionsGetSyncData()(dispatch),
		getDrugTypes: () => DrugTypeGetSyncData()(dispatch),
		getDrugs: (siteId: string, startDate: Date, endDate: Date) => GetDrugs(siteId, startDate, endDate)(dispatch),
	};
};

export interface TreatmentDefinitionsState {
	modalIsOpen: boolean;
	diagnose: IDiagnose | undefined;
	definitionToEdit?: ITreatmentDefinition;
	archiveModalOpen: boolean;
}

type TreatmentDefinitionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class TreatmentDefinitions extends React.PureComponent<TreatmentDefinitionsProps, TreatmentDefinitionsState> {
	constructor(props: TreatmentDefinitionsProps) {
		super(props);

		this.state = {
			modalIsOpen: false,
			diagnose: undefined,
			definitionToEdit: undefined,
			archiveModalOpen: false,
		};
		this.props.getDiagnoses();
		this.props.getDiagnoseCategories();
		this.getDefinitions();
		let tempFromDate = new Date();

		let tempToDate = new Date();

		this.props.getDrugs(this.props.siteId!, tempFromDate, tempToDate);
		this.props.getTreatmentDefinitions();
		this.props.getDrugTypes();
	}

	public render() {
		const columns = this.generateColumns();

		return (
			<PageContainer>
				<Heading text={localized('TreatmentDefinitions')} />
				{this.props.isFetching ? (
					<ViewWeb className="spinner-container-treatment-definition">
						<ClipLoader color="#f2ac40" size={70} />
					</ViewWeb>
				) : (
					<ViewWeb className="treatment-definitions">
						<ViewWeb className="inner-container">
							<ViewWeb className="add-treatment-def-container">
								<SkioldTouchableOpacity onPress={this.createTreatmentDef}>
									<SkioldImage width="60" height="60" imageData={TreatmentDefinitionOn} />
								</SkioldTouchableOpacity>
								<WhiteText>{localized('addTreatDef')}</WhiteText>
							</ViewWeb>
							<ViewWeb className="add-treatment-def-container">
								<SkioldTouchableOpacity onPress={this.openArchiveModal}>
									<SkioldImage width="60" height="60" imageData={MedicinArchive} />
								</SkioldTouchableOpacity>
								<WhiteText>{localized('Archive')}</WhiteText>
							</ViewWeb>
						</ViewWeb>
						<ViewWeb className="unique-def-container">
							<SkioldTable
								columns={columns}
								data={
									this.props.isFetching
										? []
										: this.getUniqueDefinitions(getAnimalTypesForTreatments(this.props.animalTypes))
								}
								sortHeaderId={'Code'}
							/>
						</ViewWeb>
						<SkioldModal padding="0" close={this.closeModal} isOpen={this.state.modalIsOpen}>
							<AddTreatmentDefinitionWrapper
								close={this.closeModal}
								definition={this.state.definitionToEdit}
							/>
						</SkioldModal>
						<SkioldModal padding="0" close={this.closeArchiveModal} isOpen={this.state.archiveModalOpen}>
							<TreatmentDefinitionArchived closeModal={this.closeArchiveModal} />
						</SkioldModal>
					</ViewWeb>
				)}
			</PageContainer>
		);
	}

	private getUniqueDefinitions(animalTypes: AnimalType[]) {
		let ArrayOfTreatmentIds = new Array<TreatmentDefinitionTableModel>();
		let definitions = new Array<ITreatmentDefinition>();
		definitions = definitions.concat(
			this.props.treatmentDefinitions.filter(
				treatmentDefinition => !treatmentDefinition.isArchived && treatmentDefinition.isActive === true,
			),
		);
		this.props.categories.forEach(category => {
			definitions.forEach(definition => {
				let defDiagnose = this.getDiagnoseFromId(definition.diagnoseId!);
				if (
					defDiagnose &&
					definition.animalKinds &&
					definition.animalKinds.find(
						ak => ak && animalTypes.find(at => at.toString() === ak.toString()) !== undefined,
					) &&
					defDiagnose.diagnoseCategoryId === category.id!
				) {
					let treatmentIds: TreatmentDefinitionTableModel = {
						categoryId: category.id!,
						treatmentDefinition: definition,
						diagnose: defDiagnose,
						categoryName: this.getCategoryNameById(category.id!),
					};
					ArrayOfTreatmentIds.push(treatmentIds);
				}
			});
		});
		return ArrayOfTreatmentIds;
	}

	private edit(definition: TreatmentDefinitionTableModel) {
		let diagnose = this.getDiagnoseFromId(definition.treatmentDefinition.diagnoseId!);

		if (!diagnose) {
			throw Error(
				"This should never happen. If we ended up here you have a Defintion with a diagnoseId that doesn't exist. That's not possible",
			);
		}
		this.setState({
			definitionToEdit: definition.treatmentDefinition,
			modalIsOpen: true,
		});
	}

	private createTreatmentDef = () => {
		this.setState({
			modalIsOpen: true,
			definitionToEdit: undefined,
			diagnose: undefined,
		});
	};

	private async getDefinitions() {
		this.props.getTreatmentDefinitions();
	}

	private closeModal = () => {
		this.setState({ modalIsOpen: false, definitionToEdit: undefined }, async () => {
			await this.getDefinitions();
		});
	};

	private closeArchiveModal = () => {
		this.setState({ archiveModalOpen: false });
	};

	private openArchiveModal = () => {
		this.setState({ archiveModalOpen: true });
	};

	private generateColumns() {
		return [
			{
				id: 'edit',
				Header: '',
				width: SowListConstants.iconWidth,
				sortable: false,
				filterable: false,
				Cell: (row: Row<TreatmentDefinitionTableModel>) => (
					<SkioldTouchableOpacity onPress={() => this.edit(row.original)}>
						<SkioldImage
							width={SowListConstants.iconSVGWidth}
							height={SowListConstants.iconSVGWidth}
							imageData={PenIcon}
						/>
					</SkioldTouchableOpacity>
				),
			},
			{
				id: 'category',
				Header: localized('category'),
				accessor: (definition: TreatmentDefinitionTableModel) => definition.categoryName,
			},
			{
				id: 'name',
				Header: localized('diagnoseName'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					return getDiagnoseName(definition.diagnose, this.props.profile.language);
				},
			},
			{
				id: 'type',
				Header: localized('drug'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					if (!definition.diagnose) {
						return '';
					}

					let treatDef = definition.treatmentDefinition;
					return getDrugsText(treatDef, this.props.drugTypes,);
				},
			},
			{
				id: 'animalkind',
				Header: localized('Production'),
				accessor: (definition: TreatmentDefinitionTableModel) => {
					if (!definition.diagnose) {
						return '';
					}

					let treatDef = definition.treatmentDefinition;
					return getAnimalKindString(treatDef.animalKinds);
				},
			},
		];
	}

	private getDiagnoseFromId(diagnoseId: string): IDiagnose | undefined {
		return this.props.diagnoses.find(diag => diag.id === diagnoseId);
	}

	private getCategoryNameById(categoryId: string): string {
		const cat = this.props.categories.find(c => c.id === categoryId);
		if (cat) {
			return cat.name![this.props.language!];
		}
		return '';
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentDefinitions);
