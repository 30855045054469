import React from 'react';
import { Dispatch } from 'redux';
import { WebAppState } from 'web/state/store.web';
import { connect } from 'react-redux';

import { TreatmentDefinition, ITreatmentDefinition, AnimalKind, IDiagnose } from 'shared/api/api';
import { GetSyncData as DiagnoseGetSyncData } from 'shared/state/ducks/diagnoses/operations';
import { GetSyncData as TreatmentDefinitionGetSyncData } from 'shared/state/ducks/treatment-definitions/operations';
import { Option } from 'react-dropdown';
import { SkioldFormDropdown } from '../../skiold-components/skiold-dropdown/skiold-form-dropdown';
import { getDiagnoseName } from 'shared/helpers/diagnose-helper/diagnose-helper';
import { IsDrugArchived } from 'shared/helpers/drug-type-helper/drug-type-helper';
import { getDrugs } from 'shared/state/ducks/drug/actions';
import { GetDrugs } from 'shared/state/ducks/drug/operations';

interface PropsFromParent {
	treatmentDefinition?: TreatmentDefinition;
	onValueChanged: (treatmentDefinition?: ITreatmentDefinition) => void;
	pigType: AnimalKind;
	allowVaccines?: boolean;
	allowMedicine?: boolean;
	treatmentDefinitionId?: string;
	treatmentDefinitionsFromParent?: ITreatmentDefinition[];
	allowMultipleSubTreatments?: boolean;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		profile: state.profile.active,
		treatmentDefinitions: state.treatmentDefinitions.entities,
		diagnoses: state.diagnose.entities,
		drug: state.drugs.drugs,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getDiagnoses: () => DiagnoseGetSyncData()(dispatch),
	getDrugs: (siteId: string) => GetDrugs(siteId, new Date(), new Date())(dispatch),
	treatmentDefinitionGetSyncData: () => TreatmentDefinitionGetSyncData()(dispatch),
});

const DefaultDiagnoseOption: Option = { value: ' ', label: '' };

interface State {}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class TreatmentDefinitionPicker extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {
		allowVaccines: false,
		allowMedicine: true,
		allowMultipleSubTreatments: true,
	};

	private diagnoseOptions: Option[] = [];
	constructor(props: Props) {
		super(props);

		this.state = {};

		this.props.getDiagnoses();
		this.props.treatmentDefinitionGetSyncData();
		this.props.getDrugs(this.props.profile!.siteId!);
	}

	public render() {
		this.getDiagnoses();

		return (
			<SkioldFormDropdown
				items={this.diagnoseOptions}
				selectedValue={this.findSelectedDiagnoseOption()}
				onValueChanged={(newTreatmentDefIdOption: Option) =>
					this.treatmentDefinitionChanged(newTreatmentDefIdOption)
				}
			/>
		);
	}

	private getDiagnoses() {
		let pickerItems: Option[] = [DefaultDiagnoseOption];
		const treatDefs = this.props.treatmentDefinitionsFromParent
			? this.props.treatmentDefinitionsFromParent
			: this.props.treatmentDefinitions;
		//Create the items for the diagnose/TreatmentDef picker
		treatDefs
			.filter(
				treatDef =>
					treatDef.animalKinds &&
					treatDef.animalKinds!.includes(this.props.pigType) &&
					(this.props.allowMultipleSubTreatments || treatDef.subTreatments?.length === 1) &&
					treatDef.subTreatments &&
					!treatDef.subTreatments.some(
						subTreat => subTreat.drugTypeId && IsDrugArchived(subTreat.drugTypeId, this.props.pigType),
					),
			)
			.forEach(treatDef => {		
				if (treatDef.isActive && !treatDef.isArchived) {
					let diagnose = this.props.diagnoses.find(
						diag => diag.id === treatDef.diagnoseId && this.sortAllowed(diag),
					);
					if (diagnose) {
						pickerItems.push({
							value: treatDef.id!,
							label: getDiagnoseName(diagnose, this.props.profile!.language),
						});
					}
				}
			});
		this.diagnoseOptions = pickerItems.sort((a, b) => {
			let textA = a.label!.toUpperCase();
			let textB = b.label!.toUpperCase();
			return textA < textB ? -1 : textA > textB ? 1 : 0;
		});
	}

	private sortAllowed = (diagnose: IDiagnose) => {
		if (this.props.allowMedicine && this.props.allowVaccines) {
			return true;
		}
		if (!this.props.allowMedicine && this.props.allowVaccines) {
			if (diagnose.isVaccination) {
				return true;
			}
		}
		if (this.props.allowMedicine && !this.props.allowVaccines) {
			if (!diagnose.isVaccination) {
				return true;
			}
		}
		return false;
	};

	private findSelectedDiagnoseOption() {
		let selectedDiagnoseOption: Option | undefined;
		if (this.props.treatmentDefinition) {
			selectedDiagnoseOption = this.diagnoseOptions.find(
				option => option.value === this.props.treatmentDefinition!.id,
			);
		}
		if (this.props.treatmentDefinitionId) {
			selectedDiagnoseOption = this.diagnoseOptions.find(
				option => option.value === this.props.treatmentDefinitionId,
			);
		}

		return selectedDiagnoseOption ? selectedDiagnoseOption : DefaultDiagnoseOption;
	}

	private treatmentDefinitionChanged(newTreatmentDefIdOption: Option | undefined): void {
		if (!newTreatmentDefIdOption) {
			this.props.onValueChanged(undefined);
		}

		let treatmentDef = this.props.treatmentDefinitions.find(td => td.id === newTreatmentDefIdOption!.value);

		this.props.onValueChanged(treatmentDef);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentDefinitionPicker);
