import {
	Averted,
	Farrowing,
	IAverted,
	IDeadPigletsEvent,
	IFarrowing,
	IMated,
	IPregnancyEvent,
	IPregnancyTableModel,
	IScanned,
	IStemAnimal,
	IValidationSetup,
	Mated,
	PregnancyState,
	PregnancyTableModel,
	PregnancyValidationType,
	Scanned,
	ScanResult,
	AnimalKind,
	AnimalType,
	ITreatment,
	ITreatmentPlan,
	ITreatmentDefinition,
	IDiagnose,
} from 'shared/api/api';
import { localized } from 'shared/state/i18n/i18n';
import { StoreContainer } from 'shared/state/store-container';
import { getDateString } from '../date-helpers';
import { calculateDays, isNullOrUndefined } from '../general-helpers';
import { sortPregnanciesByDate } from '../pregnancy-helper/sort-pregnancies';
import { findAnimalById, getBoars } from '../stemanimal-helper/stem-animal-input-helper';
import { SowCardTreatmentModel } from './sow-card-table-model';
import { calculateLitter } from '../stemanimal-helper/stemanimal-helper';
import { NaturalSort, NaturalSortDates } from '../natural-sort';
import { EntranceTypes } from 'shared/state/models/entrance-types';

//TODO: implement, move
export function generateSowCardData(
	sow: IStemAnimal,
	pregnancyEvents: IPregnancyEvent[],
	validationSetup: IValidationSetup,
	deadPiglets: IDeadPigletsEvent[],
) {
	let boars = getBoars();
	let sowCardDataList: PregnancyTableModel[] = [];
	if (sow && sow.id) {
		const sorted = sortPregnanciesByDate(pregnancyEvents).reverse();
		const groupedPregnancies = sorted.groupBy('pregnancyId');
		let litter = 0; // sow.entranceLitterNumber ? sow.entranceLitterNumber : 0;
		groupedPregnancies.forEach((value: IPregnancyEvent[], index) => {
			const sortedPregs = sortPregnanciesByDate(value);
			//Setup pregnancy events
			const mated = sortedPregs.find(x => x.state === PregnancyState.Mated) as Mated;

			if (litter === 0) {
				if (
					(sow.entranceLitterNumber &&
						sow.entranceLitterNumber >= 1 &&
						mated.date!.withoutTime() > sow.entranceDate!.withoutTime()) ||
					sow.entranceKind === AnimalKind.YoungFemale
				) {
					litter = 0;
				} else {
					litter = sow.entranceLitterNumber ? sow.entranceLitterNumber : 0;
				}
			}

			if (
				!sow.entranceLitterNumber ||
				new Date(new Date(mated.date!).setHours(0, 0, 0, 0)) >=
				new Date(new Date(sow.entranceDate!).setHours(0, 0, 0, 0))
			) {
				++litter;
			}

			const scanned = Scanned.fromJS(sortedPregs.find(x => x.state === PregnancyState.Scanned)) as Scanned;
			const farrowing = sortedPregs.find(x => x.state === PregnancyState.Farrowing) as Farrowing;
			const weanings = sortedPregs.filter(x => x.state === PregnancyState.Averted);
			let sowCardRow = new PregnancyTableModel();

			let wastedays = calculateWasteDays(index, groupedPregnancies, mated, weanings as IAverted[]);

			//Apply data
			applyMatedData(sowCardRow, litter, mated, scanned, boars);
			applyFarrowingData(
				sowCardRow,
				mated,
				scanned,
				farrowing,
				weanings as IAverted[],
				validationSetup,
				deadPiglets,
				sow,
			);
			if (scanned && scanned.result === ScanResult.False) {
				litter = litter - 1;
			} else if (farrowing && farrowing.abortion) {
				litter = litter - 1;
			}
			let weaningList = applyWeaningData(sowCardRow, weanings as IAverted[], farrowing, wastedays);
			sowCardDataList.push(sowCardRow);

			Array.prototype.push.apply(sowCardDataList, weaningList);
		});
	}
	return sowCardDataList;
}

function calculateWasteDays(
	currentIndex: number,
	groupedPregnancies: IPregnancyEvent[][],
	mated: IMated,
	weaning: IAverted[],
) {
	//Waste days is wasted days between (last weaning to next mated) + (mated => Not pregnant to mated => pregnant)
	let wastedays: undefined | number;
	if (currentIndex + 1 < groupedPregnancies.length && weaning.length > 0) {
		let nextMated = groupedPregnancies[currentIndex + 1].find(x => x.state === PregnancyState.Mated);
		if (nextMated) {
			wastedays = calculateDays(weaning[0].date!, nextMated.date);
			if (wastedays <= 0) {
				wastedays = 0;
			}
		}
	} else if (currentIndex + 1 < groupedPregnancies.length) {
		let scanned = groupedPregnancies[currentIndex].find(x => x.state === PregnancyState.Scanned) as IScanned;
		let nextMated = groupedPregnancies[currentIndex + 1].find(x => x.state === PregnancyState.Mated);
		if (scanned && scanned.result === ScanResult.False) {
			if (nextMated) {
				wastedays = calculateDays(mated.date!, nextMated.date);
			}
		}

		let farrowing = groupedPregnancies[currentIndex].find(x => x.state === PregnancyState.Farrowing) as IFarrowing;

		if (farrowing && farrowing.abortion) {
			if (nextMated) {
				wastedays = calculateDays(mated.date!, nextMated.date);
			}
		}
	}

	return wastedays;
}

function applyMatedData(
	sowCardRow: PregnancyTableModel,
	litter: number,
	mated: IMated,
	scanned: IScanned,
	boars: IStemAnimal[],
) {
	//Mated Section
	if (mated) {
		sowCardRow.matedId = mated.id;
		sowCardRow.litter = litter.toString();
		sowCardRow.matingDate = getDateString(mated!.date);
		const boar = findAnimalById(mated.boarId);
		sowCardRow.boar = boar ? boar.animalNumber : '';
		sowCardRow.k = mated.grade || '';
		if (scanned) {
			sowCardRow.scannedId = scanned.id;
			sowCardRow.pregnant = localized(scanned.result!);
		}
	}
}

function applyFarrowingData(
	sowCardRow: PregnancyTableModel,
	mated: IMated,
	scanned: IScanned,
	farrowing: IFarrowing,
	weanings: IAverted[],
	validationSetup: IValidationSetup,
	deadPiglets: IDeadPigletsEvent[],
	sow: IStemAnimal,
) {
	//Farrowing section
	if (farrowing) {
		sowCardRow.farrowingId = farrowing.id;
		sowCardRow.pregnancyDays = calculateDays(mated.date!, farrowing.date);
		sowCardRow.farrowingDate = getDateString(farrowing.date);
		sowCardRow.numAlive = farrowing.numAlive;
		sowCardRow.numDead = farrowing.numDead;
		sowCardRow.abortion = farrowing.abortion;
		sowCardRow.hasFarrowing = true;
		if (weanings.length === 1) {
			const weaning = weanings[0] as Averted;
			sowCardRow.nursingDays = calculateDays(farrowing.date!, weaning.date).toString();
		}
		let totalDeadPiglets = deadPiglets
			.filter(item => item.pregnancyId === farrowing.id)
			.map(item => item.deadPiglets!)
			.reduce((totalDead, dead) => {
				let d1 = dead ? dead : 0;
				let d2 = totalDead ? totalDead : 0;
				return d2 + d1;
			}, 0);
		sowCardRow.totalDeadPiglets = totalDeadPiglets ? totalDeadPiglets : undefined;
	} else {
		//If scan isn't false, calculate estimated farrowing
		if (mated && (!scanned || scanned.result !== ScanResult.False)) {
			const date = new Date(mated.date!);
			sowCardRow.hasFarrowing = false;
			let matedToFarrowingPlan = 116;
			if (validationSetup && validationSetup.validationIntervals) {
				const matingToFarrowing = validationSetup.validationIntervals!.find(
					setup => setup.type === PregnancyValidationType.MatingToFarrowing,
				);
				matedToFarrowingPlan =
					matingToFarrowing && matingToFarrowing.plan ? matingToFarrowing.plan : matedToFarrowingPlan;
			}
			if (!sow.departureDate || sow.departureDate > new Date()) {
				const pregnantDays = calculateDays(mated.date!, new Date());
				sowCardRow.pregnancyDays = pregnantDays >= 0 ? pregnantDays : undefined;
				sowCardRow.farrowingDate = getDateString(new Date(date.setDate(date.getDate() + matedToFarrowingPlan)));
			}
		}
	}
}

function applyWeaningData(
	sowCardRow: PregnancyTableModel,
	weanings: IAverted[],
	farrowing: IFarrowing,
	wasteDays: number | undefined,
) {
	let weaningList = [] as PregnancyTableModel[];
	if (weanings.length > 0 && farrowing) {
		let isFirst = true;
		weanings
			.slice()
			.reverse()
			.forEach((weaning: IAverted, index: number) => {
				//Handle when sow is nursing sow
				//Makes sure the first weaning is on same line as the main pregnancy line
				if (isFirst) {
					sowCardRow.weaningId = weaning.id;
					sowCardRow.weaningDate = getDateString(weaning!.date);
					sowCardRow.pcs = weaning.numAlive;
					sowCardRow.hasFarrowing = true;
					sowCardRow.weight =
						weaning.totalWeight && weaning.numAlive
							? Math.round((weaning.totalWeight / weaning.numAlive) * 10) / 10
							: undefined;
					sowCardRow.wasteDays = weanings.length > 1 ? undefined : wasteDays;
					sowCardRow.isNursingSow = weaning.isNursingSow;
					if (index !== weanings.length - 1) {
						sowCardRow.isNursingSow = true;
					}
					isFirst = false;
				} else {
					let weaningCard = new PregnancyTableModel();
					weaningCard.weaningId = weaning.id;

					weaningCard.weaningDate = getDateString(weaning!.date);
					weaningCard.pcs = weaning.numAlive;
					weaningCard.hasFarrowing = true;
					weaningCard.weight =
						weaning.totalWeight && weaning.numAlive
							? Math.round((weaning.totalWeight / weaning.numAlive) * 10) / 10
							: undefined;

					weaningCard.isNursingSow = weaning.isNursingSow;
					if (index !== weanings.length - 1) {
						weaningCard.isNursingSow = true;
					}
					if (!weaningCard.isNursingSow) {
						weaningCard.nursingDays = calculateDays(farrowing.date!, weaning.date).toString();
						weaningCard.wasteDays = wasteDays;
					}
					weaningList.push(weaningCard);
				}
			});
	} else {
		sowCardRow.wasteDays = wasteDays;
	}
	return weaningList;
}

export function calculateSowCardAvg(key: keyof IPregnancyTableModel, sowCardDatas: IPregnancyTableModel[]) {
	let count = sowCardDatas.length;

	return (
		Math.round(
			(sowCardDatas.reduce((totalValue, sowData) => {
				const value = sowData[key];

				// 0 isn't a valid value in the average calculation
				if (!isNullOrUndefined(value) && value !== 0) {
					let numb = parseFloat(value!.toString());
					if ((!isNaN(numb) && sowData.hasFarrowing) || (sowData.hasFarrowing && value === 0)) {
						// A nursing sow weaning doesn't count as a new weaning but its piglet count counts in the average calculation
						if (key === 'pcs' && sowData.isNursingSow) {
							count--;
						}
						return totalValue + numb;
					}
				}
				count--;
				return totalValue;
			}, 0) /
				count) *
			10,
		) / 10
	);
}

export function generateTreatmentData(stemAnimal: IStemAnimal | undefined) {
	const state = StoreContainer.getState();

	let treatmentModel = new Array<SowCardTreatmentModel>();
	if (stemAnimal) {
		const animalTreatmentPlans = state.treatmentPlans.entities.filter(plan => plan.stemAnimalId === stemAnimal.id);

		animalTreatmentPlans.forEach(plan => {
			const animalTreatment = state.treatments.entities.find(
				treatment => treatment.treatmentPlanId === plan.id && treatment.executedDate && !treatment.pigletAmount,
			);
			if (animalTreatment) {
				const treatmentDefinition = state.treatmentDefinitions.entities.find(
					definition => definition.id === plan.treatmentDefinitionId,
				);
				if (treatmentDefinition) {
					const diagnose = state.diagnose.entities.find(diag => diag.id === treatmentDefinition!.diagnoseId);
					if (diagnose && !diagnose.isVaccination) {
						let model = new SowCardTreatmentModel();

						model.treatmentDate = getDateString(animalTreatment.executedDate);
						model.litter = calculateLitter(stemAnimal, animalTreatment.executedDate!);
						model.diagnoseName = diagnose!.name!.da;
						treatmentModel.push(model);
					}
				}
			}
		});
	}
	treatmentModel = treatmentModel.sort((a, b) => (a.treatmentDate! > b.treatmentDate! ? 1 : 0));

	return treatmentModel.sort((a, b) => NaturalSort(a.litter, b.litter));
}

export function generateTreatmentDataSowCard(
	selectedTreatmentPlans: ITreatmentPlan[],
	selectedTreatments: ITreatment[],
	selectedAnimalType: AnimalType,
	sow: IStemAnimal | undefined,
	treatmentDefinitions: ITreatmentDefinition[],
	diagnoses: IDiagnose[]) {


	let treatmentModel = new Array<SowCardTreatmentModel>();
	if (sow) {
		selectedTreatmentPlans.forEach(plan => {
			const animalTreatment = selectedTreatments.slice().sort((a, b) => a.treatmentNumber - b.treatmentNumber).find(
				treatment => treatment.treatmentPlanId === plan.id && treatment.executedDate && (!treatment.pigletAmount && selectedAnimalType === AnimalType.Sow || treatment.pigletAmount && selectedAnimalType === AnimalType.Piglet),
			);

			if (animalTreatment) {
				const treatmentDefinition = treatmentDefinitions.find(
					definition => definition.id === plan.treatmentDefinitionId,
				);

				if (treatmentDefinition) {
					const diagnose = diagnoses.find(diag => diag.id === treatmentDefinition!.diagnoseId);


					if (diagnose && !diagnose.isVaccination) {
						let model = new SowCardTreatmentModel();
						model.treatmentDate = getDateString(animalTreatment.executedDate);
						model.litter = calculateLitter(sow, animalTreatment.executedDate!);
						model.diagnoseName = diagnose!.name!.da;
						model.numberOfPiglets = animalTreatment.pigletAmount
						model.executedDate = animalTreatment.executedDate;
						treatmentModel.push(model);
					}
				}
			}

		});
	}
	treatmentModel = treatmentModel.sort((a, b) => NaturalSortDates(a.executedDate, b.executedDate));

	return treatmentModel.sort((a, b) => NaturalSort(a.litter, b.litter));
}


//Pig/Yearsow is days from (entranceDate - last weaning date(Not Nursing))/365 * avg weaning Pigs
export function getPigYearSow(
	sow: IStemAnimal,
	pregnancyEvents: IPregnancyEvent[],
	sowCardDatas: IPregnancyTableModel[],
) {
	if (sow && sow.id) {
		const sorted = sortPregnanciesByDate(pregnancyEvents);

		const firstPregnancyEvent = sorted[sorted.length - 1];
		const lastWeaning = sorted.find(
			weaning => weaning.state === PregnancyState.Averted && !(weaning as IAverted).isNursingSow,
		);
		if (lastWeaning) {
			const feedingDays = calculateDays(firstPregnancyEvent.date!, lastWeaning.date!);
			const wasteDays = sowCardDatas.reduce(
				(acc, wasteDay) => (wasteDay.wasteDays ? acc + wasteDay.wasteDays! : acc),
				0,
			);
			const avgPregnancyDays = calculateSowCardAvg('pregnancyDays', sowCardDatas);
			const avgNursingDays = calculateSowCardAvg('nursingDays', sowCardDatas);
			const avgweaningPigs = calculateSowCardAvg('pcs', sowCardDatas);
			const litter = (feedingDays - wasteDays) / (avgPregnancyDays + avgNursingDays);
			const yearSow = feedingDays / 365;
			return Math.round((litter / yearSow) * avgweaningPigs * 10) / 10;
		}
	}
	return undefined;
}

export function calculateSowCardWeightAvg(key: keyof IPregnancyTableModel, sowCardDatas: IPregnancyTableModel[]) {
	let count = 0;
	return (
		Math.round(
			(sowCardDatas.reduce((totalWeigth, sowData) => {
				const value = sowData[key];
				if ((typeof value === 'number' && value && sowData.hasFarrowing) || value === 0) {
					count = count + sowData.pcs!;
					return totalWeigth + value * sowData.pcs!;
				}
				return totalWeigth;
			}, 0) /
				count) *
			10,
		) / 10
	);
}

export function isEventEditable(
	pregnancyEvents: IPregnancyEvent[],
	pregnancyEventId: string,
	useKernestyring?: string | undefined,
) {
	const sorted = sortPregnanciesByDate(pregnancyEvents);
	const lastFarrowing = sorted.find(pregEvent => pregEvent.state === PregnancyState.Farrowing);
	// Always allow to edit the last Farrowing
	if (useKernestyring && lastFarrowing && lastFarrowing.id === pregnancyEventId) {
		return true;
	}
	const pregnancyEvent = pregnancyEvents.find(x => x.id === pregnancyEventId);
	if (pregnancyEvent) {
		let mating = sorted.find(pregEvent => pregEvent.state === PregnancyState.Mated);

		if (mating!.pregnancyId === pregnancyEvent!.pregnancyId) {
			return true;
		} else {
			return sorted[0].id === pregnancyEvent!.id;
		}
	}

	return false;
}
