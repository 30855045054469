import React from 'react';
import { Dispatch } from 'redux';

import { connect } from 'react-redux';
import { Gender, IStemAnimal } from 'shared/api/api';
import { GetSyncData as StemAnimalGetSyncData } from 'shared/state/ducks/stem-animals/operations';
import { WebAppState } from 'web/state/store.web';
import { SkioldFormInput } from '../../skiold-components/skiold-input/skiold-form-input';
import { SkioldUnderlineInput } from '../../skiold-components/skiold-input/skiold-underline-input';
import { findActiveAnimal } from './stem-animal-input-helper';
import { RefType } from 'shared/helpers/ref-type';
import { shouldUseLetters } from 'shared/helpers/animal-kinds-helpers';
import { getWebAnimalColorStyleTable } from 'web/web-helpers/general-web-helpers';

const mapStateToProps = (state: WebAppState) => {
	return {
		generalSetting: state.generalSettings.entity,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	stemAnimalGetSyncData: () => StemAnimalGetSyncData()(dispatch),
});

export interface PropsFromParent {
	onAnimalFound: (animal?: IStemAnimal) => void;
	onAnimalNumberChanged: (animalNumber?: string) => void;
	autoFocus?: boolean;
	//Animal number is needed to be able to reset from outside and validate on input when no animal is found
	animalNumber: string | undefined;
	gender: Gender | undefined;
	className?: string;
	inputType: 'form' | 'underline';
	containerClassName?: string;

	toggleFocus?: boolean;
}

interface State {
	animalFound: boolean;
	animalColorStyle: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class StemAnimalInput extends React.PureComponent<Props, State> {
	public static defaultProps: Partial<Props> = {
		toggleFocus: true,
	};
	private animalNumInputRef: SkioldFormInput | null | undefined;
	constructor(props: Props) {
		super(props);

		this.state = {
			animalFound: false,
			animalColorStyle: '',
		};
	}
	public componentDidUpdate(prevProps: Props) {
		if (prevProps.gender !== this.props.gender) {
			this.animalNumberChanged(this.props.animalNumber);
		}
	}

	public render() {
		return this.props.inputType === 'form' ? this.renderFormInput() : this.renderUnderlineInput();
	}

	public renderFormInput() {
		return (
			<SkioldFormInput
				onChangeText={newText => this.animalNumberChanged(newText)}
				text={this.props.animalNumber || ''}
				ref={ref => {
					this.animalNumInputRef = ref;
				}}
				className={this.props.className+ ' ' + this.state.animalColorStyle}
				autoFocus={this.props.autoFocus !== undefined ? this.props.autoFocus : true}
				useAsInterger={!shouldUseLetters(this.props.gender, this.props.generalSetting)}
			/>
		);
	}

	public renderUnderlineInput() {
		return (
			<SkioldUnderlineInput
				onChangeText={newText => this.animalNumberChanged(newText)}
				text={this.props.animalNumber || ''}
				className={this.props.className+ ' ' + this.state.animalColorStyle}
				selectTextOnFocus={true}
				containerClassName={this.props.containerClassName}
				autoFocus={this.props.autoFocus !== undefined ? this.props.autoFocus : true}
			/>
		);
	}

	public focus() {
		if (this.animalNumInputRef) {
			this.animalNumInputRef.focus();
		}
	}

	private animalNumberChanged(animalNumber: string | undefined): void {
		if (!animalNumber) {
			this.animalNotFound(animalNumber);
			return;
		}

		let foundAnimal = findActiveAnimal(animalNumber, this.props.gender!);
		//Fire the event, if we found an animal
		if (foundAnimal) {
			this.props.onAnimalFound(foundAnimal);
			this.props.onAnimalNumberChanged(animalNumber);
			this.setState({ animalFound: true, animalColorStyle: getWebAnimalColorStyleTable(foundAnimal.id) });
		} else {
			this.animalNotFound(animalNumber);
			this.setState({ animalFound: true, animalColorStyle:'' });
		}
	}

	private animalNotFound(newText: string | undefined) {
		this.props.onAnimalFound(undefined);
		this.props.onAnimalNumberChanged(newText);
		this.setState({ animalFound: false });
	}
}

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, RefType, WebAppState>(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(StemAnimalInput);
