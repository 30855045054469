import { NativeAppState } from 'native/state/store.native';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { SkioldOneClient, ApiException } from 'shared/api/api';
import { SkioldDigitalApiBaseUrl } from 'shared/constants';
import { StoreContainer } from 'shared/state/store-container';
import * as fsa from 'typescript-fsa';
import { AnyAction } from 'typescript-fsa';
import { WebAppState } from 'web/state/store.web';
import { isAuthenticated } from './authentication-helper.shared';
import { Monitoring } from './monitoring-service';
import { localized } from '../state/i18n/i18n';

export interface AppError {
	code: number | string;
	message: string;
}

export const AppPrefix = 'skiold_ui';
export const StartedSuffix = '_STARTED';
export const FailedSuffix = '_FAILED';
export const SuccessSuffix = '_DONE';

export const GenActionFactoryName = (k: keyof WebAppState | keyof NativeAppState) => {
	return `${AppPrefix}/${k}`;
};

export const GetActionsReducer = (actionType: string): keyof WebAppState | keyof NativeAppState | undefined => {
	const frags = actionType.split('/');
	if (frags.length < 3) {
		return undefined;
	}
	if (frags[0] !== AppPrefix) {
		return undefined;
	}
	return frags[1] as keyof WebAppState | keyof NativeAppState;
};

export const isAppFailure = (action: AnyAction) => {
	if ((action as any).error) {
		return true;
	}
	if (!action.type) {
		return false;
	}
	return action.type.startsWith(AppPrefix) && action.type.endsWith(FailedSuffix);
};

export const isAppLoad = (action: AnyAction) => {
	if (!action.type) {
		return false;
	}
	return action.type.startsWith(AppPrefix) && action.type.endsWith(StartedSuffix);
};

export const isAppSuccess = (action: AnyAction) => {
	if (!action.type) {
		return false;
	}
	return action.type.startsWith(AppPrefix) && action.type.endsWith(SuccessSuffix);
};

const RETRY_DELAY = 3000;
const MAX_RETRIES = 1;

export const AsyncOperationBuilder2 = <TApiInput, TApiReturn, TApiError>(
	action: fsa.AsyncActionCreators<TApiInput, TApiReturn, any>,
	apiCall: (cl: SkioldOneClient) => Promise<TApiReturn>,
	apiInput: TApiInput,
	prevEntity?: TApiError,
	promiseToAwait?: Promise<any>,
	apiCallback?: (result: TApiReturn, dispatch: Dispatch<any>) => void,
	maxRetries: number = MAX_RETRIES,
	apiErrorCallback?: (errorCode: number, message: string) => void,

) => async (dispatch: Dispatch<any>) => {
	let resolvePromise: (success: any) => void;
	let promiseToResolve = new Promise<any>(resolve => {
		resolvePromise = resolve;
	});

	const client = new SkioldOneClient(SkioldDigitalApiBaseUrl);
	const state = StoreContainer.getState();
	const oldSiteId = state.profile.active ? state.profile.active.siteId : undefined;
	dispatch(action.started(apiInput!));
	if (promiseToAwait) {
		await promiseToAwait;
	}

	let tries = 0;

	const failed = (errorCode: number, message: string) => {
		if (tries <= maxRetries && errorCode !== 500 && errorCode !== 401) {
			setTimeout(() => execute(), RETRY_DELAY * tries);
		} else {
			let handleArray: any = apiInput;

			if (errorCode === 500 || errorCode === 401) {
				let errorMessage = `Api request failed: ${action.type} - Error code: ${errorCode}`;
				Monitoring.logTrace(`${errorMessage}`);
			}
			if (apiErrorCallback) {
				// Remove " quotes
				apiErrorCallback(errorCode, message.replace(/"/g, ''));
			}

			dispatch(
				action.failed({
					params:
						handleArray && handleArray.length
							? apiInput
							: {
								...(typeof apiInput === 'string'
									? { apiInput: apiInput }
									: handleArray && handleArray.length
										? { apiInput }
										: apiInput),
							},
					error: { code: errorCode, message, prevEntity },
				} as any),
			);
			resolvePromise(false);
		}
	};

	const execute = async () => {
		tries++;

		if (!StoreContainer.getState().offline.isConnected) {
			failed(-1, 'Offline');
			return;
		}

		if (!(await isAuthenticated())) {
			failed(-1, 'Refreshing token');
			return;
		}
		let handleArray: any = apiInput;
		apiCall(client)
			.then((apiReturnValue: TApiReturn) => {
				const currentState = StoreContainer.getState();
				dispatch(
					action.done({
						params:
							handleArray && handleArray.length
								? apiInput
								: {
									...(typeof apiInput === 'string' ? { apiInput: apiInput } : apiInput),
									activeSiteId: currentState.profile.active
										? currentState.profile.active.siteId
										: undefined,
									siteId: oldSiteId,
									prevEntity,
								},
						result: apiReturnValue,
					} as any),
				);

				if (apiCallback) {
					apiCallback(apiReturnValue, dispatch);
				}

				resolvePromise(true);
			})
			.catch((reject: ApiException) => {
				Monitoring.logException(reject);
				failed(reject.status, reject.response);
			});
	};

	execute();
	return promiseToResolve;
};

export const usePrevious = <T>(value: T): T | undefined => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
export const AsyncOperationHandler = async <TApiReturn>(
	apiCall: (cl: SkioldOneClient) => Promise<TApiReturn>,
	apiErrorCallback?: (errorCode: number, message: string) => void,
) => {
	const client = new SkioldOneClient(SkioldDigitalApiBaseUrl);

	try {
		return await apiCall(client);
	} catch (error) {
		const apiError = error as ApiException;
		if (apiError.status === 500 || apiError.status === 401) {
			let errorMessage = `Api request failed - Error code: ${apiError.status}`;
			Monitoring.logTrace(`${errorMessage}`);
		}
		Monitoring.logException(apiError);
		if (apiErrorCallback) {
			// Remove " quotes
			apiErrorCallback(apiError.status, JSON.parse(apiError.response).message);
		}
		throw error;
	}
};
