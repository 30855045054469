import {
	IntegratedFiltering,
	IntegratedSorting,
	IntegratedSummary,
	Sorting,
	SortingState,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	TableBandHeader,
	TableFilterRow,
	TableGroupRow,
	TableHeaderRow,
	TableSummaryRow,
	VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { NaturalSort } from 'shared/helpers/natural-sort';
import { ViewWeb } from 'web/view/components/utils/web-view';

export const GroupCellContent = rest => {
	return (
		<TableGroupRow.Content {...rest} className="summaryItem">
			{rest.value}
		</TableGroupRow.Content>
	);
};

export const virtualTableCell = rest => {
	const restCopy = { ...rest };
	restCopy.className += ' ' + rest.column.className + ' tableCell ';
	return <VirtualTable.Cell {...restCopy} />;
};

export const rootComponent = style => rest => {
	return <Grid.Root className={'root-skiold-table-grouped-grid'} {...rest} />;
};

export const TableHeaderRowCell = rest => {
	const restCopy = { ...rest };
	restCopy.className += ' tableHeaderCell';
	return <TableHeaderRow.Cell {...restCopy} />;
};
export const BandedTableHeaderRowCell = rest => {
	const restCopy = { ...rest };
	restCopy.className += ' tableBandedHeaderCell';
	return <TableBandHeader.Cell {...restCopy} />;
};

export const groupRowComponent = value => <TableGroupRow.Row {...value} className="summaryItem" />;

export const groupSummaryRowComponent = value => <TableSummaryRow.GroupRow {...value} className="groupSummrayRow" />;
export const totalSummaryRowComponent = value => <TableSummaryRow.TotalRow {...value} className="totalSummrayRow" />;
export const nameGroupCriteria = value => {
	return {
		value: value,
		key: value,
	};
};

export interface SkioldTableGroupGridColumnType {
	name: string;
	filteringDisabled?: boolean;
	sortingDisabled?: boolean;
	columnName: string;
	width: number;
	title: string;
	filterFunction?: (a, b, c) => boolean;
	sortFunction?: (a, b) => number;
	summaryMethod?: (items: any[]) => any;
	onSummaryClicked?: (rowKey: any, data: any[]) => any;
	summaryComponent?: JSX.Element;
	className?: string;
	getCellValue?: (row: any) => any;
}
export interface SkioldTableGroupGridPropsFromParent {
	data: any[];
	columns: SkioldTableGroupGridColumnType[];
	groupedHeaders?: SkioldTableGroupedColumns[];
	summaryItems?: SummaryItem[];
	totalSummrayItems?: SummaryItem[];
	groupBy: string;
	rowsToRender?: number;
	tableKey?: string;
	defaultSortHeaderId?: Sorting;
	filteringDisabled?: boolean;
	sortingDisabled?: boolean;
	summaryMessages?: any;
	formatlessSummaryTypes?: string[];
	containerClass?: string;
	tableItemsChange?: (summaryItems: SummaryRowItem[]) => void;
	totalSummaryChanged?: (summaryItems: TableKeyValue[]) => void;
	groupCellContent?: (rest: TableGroupRow.ContentProps) => JSX.Element;
	groupCritiria?: (value: any) => { key: string | number; value?: any };
	keepAllExpanded?: boolean;
	useSelection?: boolean;
	selectedChanged?: (selected: any[]) => void;
	selections?: any[];
	selectionHeader?: JSX.Element;
	onHeaderSelectChange?: (filteredRows: any[], selectedRow: string[]) => void;
	getRowId?: (row: any) => any;
}

export interface SummaryRowItem {
	key: string;
	summaryValues: TableKeyValue[];
	groupedValues: any[];
	isExpanded: boolean;
}

export interface TableKeyValue {
	columnKey: string;
	columnValue: any;
}

interface SummaryItem {
	columnName: string;
	type?: string;
}
interface SkioldTableGroupedColumns {
	key: string;
	groupColumnNames: string[];
}

export const SkioldGroupedTableFilterRowCellComponent = (props: any) => (
	<TableFilterRow.Cell {...props} className={'filter-grid'} />
);
export const SkioldGroupedTableFilterRowRowComponent = (props: any) => (
	<TableFilterRow.Row {...props} className={'filter-row'} />
);

export const SkioldGroupedGenerateIntegratedFilter = (columns: SkioldTableGroupGridColumnType[]) => {
	const integratedFilter: IntegratedFilteringColumnExtensionExtended[] = [];

	columns.forEach(column => {
		if (!column.filteringDisabled && column.filterFunction) {
			integratedFilter.push({
				columnName: column.name,
				filteringEnabled: true,
				predicate: (value: any, filter: any, row: any) => {
					return column.filterFunction
						? column.filterFunction(value, filter,row)
						: value
						? value.toString().toLowerCase().includes(filter.value.toString().toLowerCase())
						: false;
				},
			});
		}
	});
	return integratedFilter;
};

export const SkioldGroupedGenerateIntegratedSorting = (columns: SkioldTableGroupGridColumnType[]) => {
	const integratedSorting: IntegratedSorting.ColumnExtension[] = [];
	columns.forEach(column => {
		if (!column.sortingDisabled) {
			integratedSorting.push({
				columnName: column.name,
				compare: (a: any, b: any) => {
					return column.sortFunction ? column.sortFunction(a, b) : NaturalSort(a, b);
				},
			});
		}
	});
	return integratedSorting;
};

export const SkioldGroupedGenerateSortingEnabled = (columns: SkioldTableGroupGridColumnType[]) => {
	const integratedSorting: SortingState.ColumnExtension[] = [];
	columns.forEach(column => {
		integratedSorting.push({
			columnName: column.name,
			sortingEnabled: column.sortingDisabled ? false : true,
		});
	});
	return integratedSorting;
};

interface IntegratedFilteringColumnExtensionExtended extends IntegratedFiltering.ColumnExtension {
	filteringEnabled: boolean;
}

export const SkioldGroupedSortLabel =
	(propsFromParent: SkioldTableGroupGridPropsFromParent) => (props: TableHeaderRow.SortLabelProps) => {
		if (!propsFromParent.sortingDisabled) {
			let className = props.direction
				? props.direction === 'asc'
					? 'sortlabel header-font asc'
					: 'sortlabel header-font dsc'
				: 'sortlabel header-font';
			if (
				propsFromParent.groupedHeaders &&
				propsFromParent.groupedHeaders.find(
					gh => gh.groupColumnNames.find(gcn => gcn === props.column.name) !== undefined,
				) === undefined
			) {
				className = className + ' sortLabelHeight';
			}
			return (
				<TableHeaderRow.SortLabel {...props} className={className}>
					{props.children}
				</TableHeaderRow.SortLabel>
			);
		} else {
			let className = 'header-font text-header';
			return <ViewWeb className={className}>{props.children}</ViewWeb>;
		}
	};

export const SkioldGroupedSummaryCalculator =
	(summaryCalculatorColumns: SkioldTableGroupGridColumnType[]) => (type, rows, getValue) => {
		if (rows) {
			if (summaryCalculatorColumns) {
				let column = summaryCalculatorColumns.find(ex => ex.columnName === type);
				if (column && column.summaryMethod) {
					return column.summaryMethod(rows);
				}
			}
		}
		return IntegratedSummary.defaultCalculator(type, rows, getValue);
	};
