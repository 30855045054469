import { Sorting } from '@devexpress/dx-react-grid';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { CloudToDeviceStatusEnum, EsfCloudToDeviceMessage, SourceSystem } from 'shared/api/api';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import sendDataGrey from 'shared/assets/src-assets/png/send_data_grey.png';
import { getDateString, getTimeDateString } from 'shared/helpers/date-helpers';
import { exactFilterMethodGrid } from 'shared/helpers/general-helpers';
import { getLocationStringByLocationCode } from 'shared/helpers/location-helper';
import { NaturalSortDates } from 'shared/helpers/natural-sort';
import { findRetentionTime } from 'shared/helpers/treatment-helper/treatment-helper';
import {
	EsfCloudToDeviceMessageRequest,
	GetEsfCloudToDeviceMessageByIds,
	GetEsfCloudToDeviceMessages,
	ResendEsfC2M,
} from 'shared/state/ducks/esf-cloud-to-device-messages/operations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import { SkioldTableGroupGrid } from '../../skiold-components/skiold-table/skiold-table-grouped-grid/Skiold-table-grouped-grid';
import { SkioldTableGroupGridColumnType } from '../../skiold-components/skiold-table/skiold-table-grouped-grid/skiold-table-grouped-grid-helper';
import { SkioldTouchableOpacity } from '../../skiold-components/skiold-touchable-opacity';
import { WhiteText } from '../../Text/white-text';
import { SkioldImage } from '../../utils/svg/skiold-image';
import { ViewWeb } from '../../utils/web-view';
import './resend-esf-list-table.scss';
import { TextWeb } from 'web/web-helpers/styled-text-components';

interface PropsFromParent {
	dateFrom: Date;
	dateTo: Date;
	onSummaryChanged: (element: JSX.Element) => void;
}

class ResendTableItem extends EsfCloudToDeviceMessage {
	public disabled?: boolean;
}

const hasRetentionTime = (tableItem: EsfCloudToDeviceMessage) => {
	return tableItem && tableItem.animalId && findRetentionTime(tableItem.animalId) !== undefined
		? 'retention-time-list-color'
		: '';
};

const getDateCell = (item: ResendTableItem) => getTimeDateString(item.createdOn);

const getMessageType = (item: ResendTableItem) => localized(item.messageType);

const getSequenceNumber = (item: ResendTableItem) => item.sequenceNumber;

const getAnimalNumberCell = (item: ResendTableItem) => item.animalNumber;

const getTransponderCell = (item: ResendTableItem) => item.transponder;

const getStatusColor = (status: CloudToDeviceStatusEnum) => {
	switch (status) {
		case CloudToDeviceStatusEnum.Ok:
			return 'status-ok';
		case CloudToDeviceStatusEnum.Error:
			return 'status-error';
		case CloudToDeviceStatusEnum.Notified:
			return 'status-notified';
		case CloudToDeviceStatusEnum.Created:
			return 'status-created';
		default:
			return '';
	}
};

const getStatusCell = (item: ResendTableItem) => (
	<ViewWeb className="flex-row default-gap justify-content-center">
		<TextWeb className={getStatusColor(item.status)}>{localized('Esf' + item.status)}</TextWeb>
		{item.status === CloudToDeviceStatusEnum.Pending && <ClipLoader color="#f2ac40" size={25} />}
	</ViewWeb>
);

const getMessage = (item: ResendTableItem) => {
	if (!item.message) {
		return;
	}
	const message = JSON.parse(item.message);
	return Object.keys(message).reduce((acc, key) => {
		if (key === 'MessageId') {
			return acc;
		}
		let value = message[key];
		if (key === 'Location' || key === 'FromLocationCode') {
			value = getLocationStringByLocationCode(value, SourceSystem.ESF);
		}
		return acc + `${localized(key)}: ${value} \n`;
	}, '');
};

const sorting = {
	columnName: 'animalNumber',
	direction: 'asc',
} as Sorting;
export const ResendEsfCloudToMessagesOverview: FC<PropsFromParent> = React.memo(
	({ dateFrom, dateTo, onSummaryChanged }) => {
		const dispatch = useDispatch();

		// State
		const [selected, setSelected] = useState<string[]>([]);

		// redux selectors
		const siteId = useSelector((state: WebAppState) => state.profile.active && state.profile.active.siteId);
		const EsfCloudToDeviceMessages = useSelector((state: WebAppState) => state.esfCloudToDeviceMessages.messages);
		const stemAnimals = useSelector((state: WebAppState) => state.stemAnimals.entities);

		const getActionCell = useCallback((item: ResendTableItem) => {
			const deleteC2D = () => {
				dispatch(ResendEsfC2M({ id: item.id, status: CloudToDeviceStatusEnum.Deleted }));
			};

			if (item.status === CloudToDeviceStatusEnum.Ok) {
				return null;
			}

			return (
				<ViewWeb className="buttons-gap">
					<SkioldTouchableOpacity onPress={deleteC2D}>
						<SkioldImage width="25" height="25" imageData={DeleteIcon} />
					</SkioldTouchableOpacity>
				</ViewWeb>
			);
		}, []);

		// Fetch data
		useEffect(() => {
			dispatch(
				GetEsfCloudToDeviceMessages({
					siteId,
					fromDate: dateFrom,
					toDate: dateTo,
				} as EsfCloudToDeviceMessageRequest),
			);
		}, [siteId, dateFrom, dateTo]);

		// Set reesend
		useEffect(() => {
			const resend = () => {
				selected.forEach(item => {
					dispatch(ResendEsfC2M({ id: item, status: CloudToDeviceStatusEnum.Resend }));
				});
			};

			onSummaryChanged(
				<ViewWeb className={'centerResendIcon'}>
					<SkioldTouchableOpacity onPress={resend}>
						<SkioldImage width="60" height="60" imageData={sendDataGrey} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('sendMarked')}</WhiteText>
				</ViewWeb>,
			);
		}, [selected]);

		// Polling handler
		// TODO: Temp disabled
		useEffect(() => {
			const fetchData = () => {
				dispatch(GetEsfCloudToDeviceMessageByIds(EsfCloudToDeviceMessages.map(message => message.id!)));
			};

			const intervalId = setInterval(fetchData, 10000);

			// Clear the interval on unmount
			return () => clearInterval(intervalId);
		}, [EsfCloudToDeviceMessages]);

		// Data
		const stemAnimalDictionary = useMemo(() => {
			return stemAnimals.keyValuePairSingle('id');
		}, [stemAnimals]);

		const data = useMemo(() => {
			let esfData: ResendTableItem[] = [];
			EsfCloudToDeviceMessages.forEach(message => {
				if (!message.animalId) {
					return;
				}

				const animal = stemAnimalDictionary[message.animalId];
				if (!animal) {
					return;
				}
				const tableItem = {
					...message,
					animalNumber: animal.animalNumber,
					transponder: animal.transponder,
					disabled: message.status === CloudToDeviceStatusEnum.Created,
				} as ResendTableItem;
				esfData.push(tableItem);
			});
			return esfData;
		}, [EsfCloudToDeviceMessages, stemAnimalDictionary]);

		const selectionChanged = useCallback(
			(selections: string[]) => {
				const newSelections = selections.filter(n => !selected.includes(n));
				const selectionsToKeep = selected.filter(n => selections.includes(n));
				const newItems = data.filter(d => newSelections.includes(d.id!));
				const prevItems = data.filter(d => selectionsToKeep.includes(d.id!));

				const singleSelections = {};
				newItems.forEach(item => {
					if (!item || !item.animalId) {
						return;
					}
					singleSelections[item.animalId] = item.id;
				});

				prevItems.forEach(item => {
					if (!item || !item.animalId || singleSelections[item.animalId]) {
						return;
					}
					singleSelections[item.animalId] = item.id;
				});
				setSelected(Object.values(singleSelections));
			},
			[selected, data],
		);

		const onHeaderSelectChange = useCallback((filteredRows: ResendTableItem[], selectedRow: string[]) => {
			const currentSelected: string[] = [];
			const currentNotSelected: string[] = [];
			filteredRows.forEach(item => {
				if (item.status !== CloudToDeviceStatusEnum.Error && item.status !== CloudToDeviceStatusEnum.Notified) {
					return;
				}
				if (selectedRow.includes(item.id!)) {
					currentSelected.push(item.id!);
				} else {
					currentNotSelected.push(item.id!);
				}
			});
			if (currentNotSelected.length <= 0) {
				setSelected(currentNotSelected);
			} else if (currentSelected.length <= 0) {
				setSelected(currentNotSelected);
			} else {
				setSelected(currentSelected.concat(currentNotSelected));
			}
		}, []);

		const statusFilter = (value: any, filterValue: any, row: ResendTableItem) => {
			return (
				row.status &&
				String(localized('Esf' + row.status).toLocaleLowerCase()).startsWith(
					filterValue.value.toLocaleLowerCase(),
				)
			);
		};

		const columns = useMemo(() => {
			return [
				{
					columnName: 'createdOn',
					name: 'createdOn',
					title: localized('Date'),
					sortFunction: NaturalSortDates,
					getCellValue: getDateCell,
					width: 80,
				},
				{
					columnName: 'animalNumber',
					name: 'animalNumber',

					title: localized('animalNumber'),
					className: hasRetentionTime,
					filterFunction: exactFilterMethodGrid,
					getCellValue: getAnimalNumberCell,
					width: 80,
				},
				{
					columnName: 'transponder',
					name: 'transponder',
					title: localized('transponder'),
					filterFunction: exactFilterMethodGrid,
					getCellValue: getTransponderCell,
					width: 80,
				},
				{
					columnName: 'sequenceNumber',
					name: 'sequenceNumber',
					title: localized('EsfResendSequenceNumber'),
					getCellValue: getSequenceNumber,
					width: 80,
				},
				{
					columnName: 'messageType',
					name: 'messageType',
					title: localized('EsfResendMessageType'),
					getCellValue: getMessageType,
					width: 80,
				},
				{
					columnName: 'status',
					name: 'status',
					title: localized('Status'),
					getCellValue: getStatusCell,
					filterFunction: statusFilter,
					width: 80,
				},
				{
					columnName: 'message',
					name: 'message',
					title: localized('EsfResendMessage'),
					getCellValue: getMessage,
					className: 'message-cell',
					width: 80,
				},
				{
					columnName: 'delete',
					name: 'delete',
					title: localized('delete'),
					getCellValue: getActionCell,
					width: 80,
				},
			] as SkioldTableGroupGridColumnType[];
		}, []);

		const resendHeader = useMemo(() => {
			return (
				<th className="tableHeaderCell font-weight-200">
					<WhiteText>{localized('Resend')}</WhiteText>
				</th>
			);
		}, []);

		return (
			<SkioldTableGroupGrid
				containerClass="align-self-center"
				tableKey={'resendEsfCloudToMessagesOverview'}
				columns={columns}
				data={data}
				groupBy="animalNumber"
				useSelection={true}
				keepAllExpanded={true}
				selectedChanged={selectionChanged}
				selections={selected}
				defaultSortHeaderId={sorting}
				//selectionHeader={resendHeader}
				onHeaderSelectChange={onHeaderSelectChange}
			/>
		);
	},
);
