import ObjectID from 'bson-objectid';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	AnimalKind,
	AnimalType,
	Feeding,
	FeedingAmountType,
	FeedingSubject,
	FeedingUsage,
	IFeeding,
	IFeedingSubject,
	IFeedingUsage,
	LocationType,
} from 'shared/api/api';
import { getDateString } from 'shared/helpers/date-helpers';
import { ExceptionMessage } from 'shared/helpers/exception-message';
import { getAnimalTypesInUse } from 'shared/helpers/general-helpers';
import { getBuildingByBuildingId, getBuildingBySectionId, getSectionBySectionId } from 'shared/helpers/location-helper';
import { memoizeHashmapLocation } from 'shared/helpers/memoize-getters/memoize-getters';
import { SaveFeedingSubject } from 'shared/state/ducks/feeding-subjects/operations';
import { saveFeeding } from 'shared/state/ducks/feeding/operations';
import { LocationsState, Section } from 'shared/state/ducks/locations';
import { localized } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import SkioldStableSectionPicker from 'web/view/components/location/location-picker/skiold-stable-section-picker';
import PageContainer from 'web/view/components/page-container/page-container';
import { showAlert, ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { SkioldButton } from 'web/view/components/skiold-components/skiold-button/skiold-button';
import { SkioldFormDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-form-dropdown';
import { SkioldFormsWrapper } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper';
import { FormRow } from 'web/view/components/skiold-components/skiold-forms-wrapper/skiold-forms-wrapper-types';
import { Heading } from 'web/view/components/utils/heading';
import 'web/view/styles/stock-styles/add-item.scss';
import { SkioldDatePicker } from '../skiold-components/skiold-date-picker/skiold-date-picker';
import { SkioldFormDecimalInput } from '../skiold-components/skiold-decimal-input/skiold-form-decimal-input';
import { SkioldFormTextField } from '../skiold-components/skiold-forms-wrapper/skiold-form-text';
import { ViewWeb } from '../utils/web-view';
import { FeedingUsageTableModel, GetFeedingName, GetFeedingSubject, GetPointOfChangeFeeding } from './food-helper';
import { RecipePicker } from '../recipe/recipe-picker';

interface PropsFromParent {
	adjustmentToEdit?: string;
	feedingToEdit?: string;
	closeModal: () => void;
}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		feedings: state.feeding.feedings,
		feedingSubjects: state.feedingSubject.entities,
		profile: state.profile.active!,
		locations: state.locations,
		userProfile: state.profile.active!,
		feedingCategory: state.feedingCategory.entities,
		locationsMemoized: memoizeHashmapLocation(
			state.locations.buildings,
			state.locations.sections,
			state.locations.pens,
		),
		attachRecipeToUsage: state.generalSettings.entity.feedAttactedToRecipe,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	saveFeeding: (feeding: Feeding) => saveFeeding(feeding)(dispatch),
	saveFeedingSubject: (feeding: FeedingSubject) => SaveFeedingSubject(feeding)(dispatch),
});

interface State {
	amount?: number;
	selectedFeedingSubjectOption?: Option;
	selectedAnimalTypeOption: Option;
	feeding?: IFeeding;
	feedingSubjectName?: string;
	filteredFeedingSubjects?: IFeedingSubject[];
	lowestAdjustmentDate: Date | undefined;
	adjustment: FeedingUsageTableModel;
	selectedProductionType?: Option;
	selectedBuilding?: Option;
	selectedSectionId?: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

export class CreateFoodUsage extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		let lowestAdjustmentDate: Date | undefined;
		let { feeding, feedingSubjectName, adjustment } = this.getInitialData();
		if (feeding) {
			({ feedingSubjectName, adjustment, lowestAdjustmentDate } = this.setInitialDataForFeedingUsage(
				feeding,
				feedingSubjectName,
				adjustment,
			));
		}
		const section = getSectionBySectionId(adjustment.sectionId, props.locationsMemoized);
		const building = getBuildingByBuildingId(adjustment.buildingId, props.locationsMemoized);
		let selectedBuilding = { label: '', value: '' };
		let selectedSectionId: string | undefined;
		if (building && building.name && building.id && section && section.name && section.id) {
			selectedBuilding = { label: building.name, value: building.id };
			selectedSectionId = section.id;
		}
		this.state = {
			amount: adjustment.amount,
			lowestAdjustmentDate,
			feeding: feeding,
			adjustment,
			feedingSubjectName,
			selectedFeedingSubjectOption: { label: '', value: '' },
			selectedAnimalTypeOption: {
				label: adjustment.animalType ? localized(adjustment.animalType) : '',
				value: adjustment.animalType ? adjustment.animalType : '',
			},
			selectedProductionType: {
				label:
					section && section.animalType
						? localized(section.animalType)
						: adjustment.productionType
						? localized(adjustment.productionType)
						: '',
				value:
					section && section.animalType
						? section.animalType
						: adjustment.productionType
						? adjustment.productionType
						: '',
			},
			selectedSectionId,
			selectedBuilding,
		};
	}

	public render() {
		return (
			<PageContainer>
				<Heading text={this.props.adjustmentToEdit ? localized('EditFoodUsage') : localized('CreateUsage')} />
				<ViewWeb className="add-item">
					<SkioldFormsWrapper formRows={this.getFormRows()} />
					<ViewWeb className="add-item-button-container">
						<SkioldButton theme="grey" title={localized('Close')} onPress={this.props.closeModal} />
						<SkioldButton title={localized('Save')} onPress={this.save} />
					</ViewWeb>
				</ViewWeb>
			</PageContainer>
		);
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			!isEqual(prevState.selectedAnimalTypeOption, this.state.selectedAnimalTypeOption) &&
			this.state.selectedAnimalTypeOption &&
			this.checkIfBuildingIsShown(this.state.selectedAnimalTypeOption.value as AnimalType)
		) {
			const state = { ...this.state };

			const animalTypes = this.props.locations.sections
				.filter(
					section =>
						section.animalType === this.state.selectedAnimalTypeOption.value ||
						(section.animalType === AnimalType.FRATS &&
							(((section.type === LocationType.Weaners || section.type === LocationType.ReliefWeaners) &&
								this.state.selectedAnimalTypeOption.value === AnimalType.Weaner) ||
								((section.type === LocationType.Finisher ||
									section.type === LocationType.ReliefFinisher) &&
									this.state.selectedAnimalTypeOption.value === AnimalType.Finisher))),
				)
				.map(e => e.animalType);

			const fratsAnimalType = animalTypes.find(e => e === AnimalType.FRATS);
			const defaultAnimalType = animalTypes.find(e => e === this.state.selectedAnimalTypeOption.value);

			state.selectedProductionType = {
				label: localized(
					fratsAnimalType && fratsAnimalType ? fratsAnimalType : defaultAnimalType ? defaultAnimalType : '',
				),
				value:
					fratsAnimalType && fratsAnimalType ? fratsAnimalType! : defaultAnimalType ? defaultAnimalType : '',
			} as Option;
			this.setState(state);
		} else if (
			!isEqual(prevState.selectedAnimalTypeOption, this.state.selectedAnimalTypeOption) &&
			this.state.selectedAnimalTypeOption &&
			!this.checkIfBuildingIsShown(this.state.selectedAnimalTypeOption.value as AnimalType)
		) {
			this.setState({
				selectedProductionType: {
					label: localized(AnimalType.Sow),
					value: AnimalType.Sow,
				},
			});
		}
	}

	private save = async () => {
		if ((await this.validate()) && this.state.feeding) {
			let feeding = { ...this.state.feeding } as Feeding;

			if (this.state.feeding.adjustments) {
				feeding.adjustments = [...this.state.feeding.adjustments];
			}
			const date = new Date();
			if (!this.props.adjustmentToEdit) {
				this.createFeedingUsage(date, feeding);
			} else {
				this.editFeedingUsage(date, feeding);
			}
			if (
				feeding.animalTypes &&
				this.state.selectedProductionType &&
				this.state.selectedProductionType.value !== '' &&
				feeding.animalTypes.find(
					at => this.state.selectedProductionType && at === this.state.selectedProductionType.value,
				) === undefined
			) {
				feeding.animalTypes.push(this.state.selectedProductionType.value as AnimalType);
			}
			this.props.saveFeeding(feeding);
			this.resetPropertiesOnFeeding(feeding);
			if (this.props.adjustmentToEdit) {
				this.props.closeModal();
			}
		}
	};

	private editFeedingUsage(date: Date, feeding: Feeding) {
		let adjustment = { ...this.state.adjustment };
		const periodFrom = new Date(
			adjustment.periodFrom!.getFullYear(),
			adjustment.periodFrom!.getMonth(),
			adjustment.periodFrom!.getDate(),
		).withoutTime();
		adjustment.periodFrom = periodFrom;
		adjustment.date = periodFrom;
		adjustment.periodTo = new Date(
			adjustment.periodTo!.getFullYear(),
			adjustment.periodTo!.getMonth(),
			adjustment.periodTo!.getDate(),
		).withoutTime();

		const building = getBuildingBySectionId(this.state.selectedSectionId, this.props.locationsMemoized);
		const feedingUsage = {
			...adjustment,
			amount: this.state.amount,
			sectionId: this.state.selectedSectionId ? this.state.selectedSectionId : undefined,
			buildingId: building ? building.id : undefined,
			productionType: this.state.selectedProductionType
				? this.state.selectedProductionType.value
				: this.state.selectedAnimalTypeOption && (this.state.selectedAnimalTypeOption.value as AnimalType),
			animalType:
				this.state.selectedAnimalTypeOption && (this.state.selectedAnimalTypeOption.value as AnimalType),
			defaultBuildingName:
				this.state.selectedAnimalTypeOption!.value !== AnimalType.Weaner &&
				this.state.selectedAnimalTypeOption!.value !== AnimalType.Finisher
					? 'Sows'
					: this.state.selectedAnimalTypeOption!.value,
		} as FeedingUsage;
		if (feeding.adjustments) {
			feeding.adjustments[feeding.adjustments.findIndex(a => a.id === feedingUsage.id)] = feedingUsage;
		}
	}

	private createFeedingUsage(date: Date, feeding: Feeding) {
		let adjustment = { ...this.state.adjustment };
		const periodFrom = new Date(
			adjustment.periodFrom!.getFullYear(),
			adjustment.periodFrom!.getMonth(),
			adjustment.periodFrom!.getDate(),
		).withoutTime();
		adjustment.periodFrom = periodFrom;
		adjustment.date = periodFrom;
		adjustment.periodTo = new Date(
			adjustment.periodTo!.getFullYear(),
			adjustment.periodTo!.getMonth(),
			adjustment.periodTo!.getDate(),
		).withoutTime();
		const building = getBuildingBySectionId(this.state.selectedSectionId, this.props.locationsMemoized);
		const feedingUsage = {
			...adjustment,
			createdOn: date,
			modifiedOn: date,
			modifiedBy: this.props.profile.id,
			createdBy: this.props.profile.id,
			id: new ObjectID().toHexString(),
			amount: this.state.amount,
			sectionId: this.state.selectedSectionId ? this.state.selectedSectionId : undefined,
			buildingId: building ? building.id : undefined,
			productionType: this.state.selectedProductionType
				? this.state.selectedProductionType.value
				: this.state.selectedAnimalTypeOption && (this.state.selectedAnimalTypeOption.value as AnimalType),
			defaultBuildingName:
				this.state.selectedAnimalTypeOption!.value !== AnimalType.Weaner &&
				this.state.selectedAnimalTypeOption!.value !== AnimalType.Finisher
					? 'Sows'
					: this.state.selectedAnimalTypeOption!.value,
			animalType:
				this.state.selectedAnimalTypeOption && (this.state.selectedAnimalTypeOption.value as AnimalType),
		} as IFeedingUsage;
		if (feeding.adjustments) {
			const copyOfAdjustments = [...feeding.adjustments];
			copyOfAdjustments.push({ ...feedingUsage } as FeedingUsage);
			feeding.adjustments = copyOfAdjustments;
		}
	}

	private async validate() {
		if (this.state.selectedAnimalTypeOption && this.state.selectedAnimalTypeOption.value === '') {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NoFeedingAnimalTypeChoosen));
			return false;
		}
		if (
			!this.props.adjustmentToEdit &&
			this.state.selectedFeedingSubjectOption &&
			this.state.selectedFeedingSubjectOption.value === ''
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NoFeedingSubjectChoosen));
			return false;
		}
		if (!this.state.amount) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_DRUGSTOCK_AMOUNT_NOT_SET));
			return false;
		}

		// Don't allow usages before first adjustment
		if (
			!(
				this.state.feeding &&
				this.state.feeding.adjustments &&
				this.state.feeding.adjustments.find(
					adj =>
						adj.type === FeedingAmountType.FeedingAdjustment &&
						adj.date &&
						this.state.adjustment.periodFrom &&
						adj.date <= this.state.adjustment.periodFrom,
				)
			)
		) {
			showAlert(localized(ExceptionMessage.VALIDATION_ERROR_NO_FEEDING_ADJUSTMENT));
			return false;
		}

		if (this.state.feeding && this.state.feeding.adjustments) {
			const overlappingFeedingAdjustment = this.state.feeding.adjustments.find(
				a =>
					this.state.adjustment &&
					!a.isDeleted &&
					this.state.selectedAnimalTypeOption &&
					(a as FeedingUsage).animalType === this.state.selectedAnimalTypeOption.value &&
					((this.props.adjustmentToEdit && a.id !== this.props.adjustmentToEdit) ||
						!this.props.adjustmentToEdit) &&
					this.checkPeriods(a as FeedingUsage),
			);

			if (
				overlappingFeedingAdjustment &&
				!(await ShowConfirmAlert(
					localized(
						ExceptionMessage.VALIDATION_WARNING_YOU_ARE_CREATING_A_USAGE_THAT_IS_IN_ANOTHER_USAGE_PERIOD,
					) +
						getDateString((overlappingFeedingAdjustment as FeedingUsage).periodFrom) +
						' - ' +
						getDateString((overlappingFeedingAdjustment as FeedingUsage).periodTo) +
						' ' +
						localized('WillYouContinue'),
				))
			) {
				return false;
			}
		}

		return true;
	}

	private checkPeriods = (feedingUsage: IFeedingUsage) =>
		feedingUsage.periodFrom &&
		this.state.adjustment.periodFrom &&
		this.state.adjustment.periodTo &&
		feedingUsage.periodTo &&
		((this.state.adjustment.periodFrom.getTime() >= feedingUsage.periodFrom!.getTime() &&
			new Date(this.state.adjustment.periodTo.withoutTime()).getTime() <= feedingUsage.periodTo!.getTime()) ||
			(this.state.adjustment.periodFrom.getTime() < feedingUsage.periodFrom!.getTime() &&
				new Date(this.state.adjustment.periodTo.withoutTime()).getTime() >=
					feedingUsage.periodFrom!.getTime()) ||
			(this.state.adjustment.periodFrom.getTime() > feedingUsage.periodFrom!.getTime() &&
				new Date(this.state.adjustment.periodTo.withoutTime()).getTime() > feedingUsage.periodTo!.getTime() &&
				!(
					this.state.adjustment.periodFrom.getTime() > feedingUsage.periodTo!.getTime() &&
					new Date(this.state.adjustment.periodTo.withoutTime()).getTime() > feedingUsage.periodTo!.getTime()
				)));

	private getFormRows() {
		const formRows = new Array<FormRow>();
		formRows.push(
			this.getPeriodRow(localized('PeriodFrom'), this.state.adjustment!.periodFrom!, this.onPeriodFromChanged),
		);
		formRows.push(
			this.getPeriodRow(localized('PeriodTo'), this.state.adjustment!.periodTo!, this.onPeriodToChanged),
		);

		formRows.push(this.getAnimalTypeRow());
		if (this.props.attachRecipeToUsage) {
			formRows.push(this.getRecipeRow());
		}
		if (
			!this.props.feedingToEdit &&
			this.state.selectedAnimalTypeOption &&
			this.state.selectedAnimalTypeOption.value !== ''
		) {
			formRows.push(this.getFeedingTypeRow());
		} else {
			formRows.push(this.getTextRow(localized('FeedingSubject'), this.state.feedingSubjectName));
		}

		formRows.push(this.getAmountInKgRow(this.state.amount));
		// formRows.push(
		// 	this.getTextRow(
		// 		localized('price'),
		// 		this.state.adjustment!.pricePer100Kg ? this.state.adjustment!.pricePer100Kg!.toFixed(2) : ''
		// 	)
		// ); // Removed temp
		const animalType = this.state.selectedAnimalTypeOption!.value as AnimalType;
		formRows.push(this.getProductionDropdown(animalType));

		if (
			(animalType === AnimalType.Weaner || animalType === AnimalType.Finisher) &&
			this.state.selectedProductionType &&
			this.checkIfBuildingIsShown(this.state.selectedProductionType.value as AnimalType)
		) {
			formRows.push(this.getBuildingDropdown(this.state.selectedAnimalTypeOption.value as AnimalType));
		}
		return formRows;
	}

	private checkIfBuildingIsShown(animalType?: AnimalType) {
		return (
			animalType &&
			(animalType === AnimalType.Weaner || animalType === AnimalType.Finisher || animalType === AnimalType.FRATS)
		);
	}

	private getTextRow(name: string, value: any): FormRow {
		return {
			name: name,
			component: <SkioldFormTextField>{value}</SkioldFormTextField>,
		};
	}
	private getAmountInKgRow(amount?: number): FormRow {
		return {
			name: localized('UsageKg'),
			component: (
				<SkioldFormDecimalInput
					onChangeNumber={this.onAmountInKgChanged}
					text={amount !== undefined ? amount : undefined}
				/>
			),
		};
	}
	private getPeriodRow(name: string, selectedDate: Date, onDateChanged: (newDate: Date) => void): FormRow {
		return {
			name: name,
			component: (
				<SkioldDatePicker
					selectedDate={selectedDate}
					onDateChanged={onDateChanged}
					theme={'dark'}
					color={'grey'}
				/>
			),
		};
	}
	private getAnimalTypeRow(): FormRow {
		return {
			name: localized('pigType'),
			component: (
				<SkioldFormDropdown
					items={
						!this.props.feedingToEdit
							? this.getAnimalTypeOptions()
							: this.getAnimalTypeOptions(this.state.feeding)
					}
					selectedValue={this.state.selectedAnimalTypeOption}
					onValueChanged={this.onAnimalTypeChanged}
				/>
			),
		};
	}

	private getRecipeRow(): FormRow {
		return {
			name: localized('Recipe'),
			component: (
				<RecipePicker
					useInForm={true}
					animalType={this.state.selectedAnimalTypeOption!.value as AnimalType}
					recipeId={this.state.adjustment.recipeId}
					onChosenRecipe={this.onRecipeChange}
				/>
			),
		};
	}
	private getAnimalTypeOptions(feeding?: IFeeding) {
		let animalTypeOptions: Option[] = [];
		const animalTypes = getAnimalTypesInUse(this.props.userProfile);

		if (
			(!feeding && animalTypes.includes(AnimalType.Sow)) ||
			this.checkIfFeedingHasAnimalType(AnimalType.Sow, feeding)
		) {
			animalTypeOptions.push({ value: AnimalType.Sow, label: localized('SowBoarGlit') });
		}

		if (
			(!feeding && animalTypes.includes(AnimalKind.YoungFemale)) ||
			this.checkIfFeedingHasAnimalType(AnimalType.YoungFemale, feeding)
		) {
			animalTypeOptions.push({ value: AnimalKind.YoungFemale, label: localized(AnimalKind.YoungFemale) });
		}
		if (
			(!feeding && animalTypes.includes(AnimalKind.Piglet)) ||
			this.checkIfFeedingHasAnimalType(AnimalType.Piglet, feeding)
		) {
			animalTypeOptions.push({ value: AnimalKind.Piglet, label: localized(AnimalKind.Piglet) });
		}
		if (
			(!feeding && animalTypes.includes(AnimalType.Weaner)) ||
			this.checkIfFeedingHasAnimalType(AnimalType.Weaner, feeding)
		) {
			animalTypeOptions.push({ value: AnimalType.Weaner, label: localized(AnimalType.Weaner) });
		}
		if (
			(!feeding && animalTypes.includes(AnimalType.Finisher)) ||
			this.checkIfFeedingHasAnimalType(AnimalType.Finisher, feeding)
		) {
			animalTypeOptions.push({ value: AnimalType.Finisher, label: localized(AnimalType.Finisher) });
		}

		return animalTypeOptions;
	}

	private checkIfFeedingHasAnimalType = (animalType: AnimalType, feeding?: IFeeding) =>
		feeding && feeding.animalTypes && feeding.animalTypes.includes(animalType);
	private getFeedingTypeRow(): FormRow {
		return {
			name: localized('FeedingSubject'),
			component: (
				<SkioldFormDropdown
					items={this.getFilteredFeedingSubjects(this.state.selectedAnimalTypeOption)}
					selectedValue={this.state.selectedFeedingSubjectOption}
					onValueChanged={this.onFeedingTypeChanged}
				/>
			),
		};
	}

	private getProductionDropdown(animalType: AnimalType): FormRow {
		if (animalType === AnimalType.Weaner || animalType === AnimalType.Finisher) {
			const animalTypes = this.props.locations.sections
				.filter(
					section =>
						section.animalType === animalType ||
						(section.animalType === AnimalType.FRATS &&
							(((section.type === LocationType.Weaners || section.type === LocationType.ReliefWeaners) &&
								animalType === AnimalType.Weaner) ||
								((section.type === LocationType.Finisher ||
									section.type === LocationType.ReliefFinisher) &&
									animalType === AnimalType.Finisher))),
				)
				.map(section => section.animalType!)
				.filter((value, index, self) => self.indexOf(value) === index);

			const productionTypes = animalTypes.map(at => ({ label: localized(at), value: at } as Option));
			// productionTypes.unshift({ label: localized(animalType), value: animalType });
			return {
				name: localized('production'),
				component: (
					<SkioldFormDropdown
						items={productionTypes}
						itemFromParent={'selectedProductionType'}
						selectedValue={this.state.selectedProductionType}
						onValueChanged={this.onOptionChanged}
					/>
				),
			};
		} else {
			return {
				name: localized('production'),
				component: <SkioldFormTextField>{animalType ? localized('Sows') : ''}</SkioldFormTextField>,
			};
		}
	}

	private getBuildingDropdown(animalType: AnimalType): FormRow {
		if (
			(this.state.selectedProductionType && animalType === AnimalType.Weaner) ||
			animalType === AnimalType.Finisher
		) {
			const filteredLocations = { ...this.props.locations } as LocationsState;
			filteredLocations.sections = filteredLocations.sections.filter(
				s =>
					(s.animalType === animalType &&
						this.state.selectedProductionType &&
						this.state.selectedProductionType.value !== AnimalType.FRATS) ||
					(this.state.selectedProductionType &&
						s.animalType === AnimalType.FRATS &&
						this.state.selectedProductionType.value === AnimalType.FRATS &&
						((animalType === AnimalType.Finisher &&
							(s.type === LocationType.ReliefFinisher || s.type === LocationType.Finisher)) ||
							(animalType === AnimalType.Weaner &&
								(s.type === LocationType.ReliefWeaners || s.type === LocationType.Weaners)))),
			);
			return {
				name: localized('Location'),
				component: (
					<SkioldStableSectionPicker
						usedInsideForm={true}
						onStableSectionSelected={this.sectionSelected}
						sectionId={this.state.selectedSectionId}
						filteredLocations={filteredLocations}
						isPigProduction={true}
					/>
				),
			};
		} else {
			return {
				name: localized('production'),
				component: (
					<SkioldFormTextField>
						{this.state.selectedBuilding && this.state.selectedBuilding.label}
					</SkioldFormTextField>
				),
			};
		}
	}

	private checkSectionAnimalType(section: Section, animalType: AnimalType): unknown {
		return (
			this.state.selectedProductionType &&
			((this.state.selectedProductionType.value === AnimalType.FRATS &&
				section.animalType === AnimalType.FRATS &&
				((section.type === LocationType.Weaners && animalType === AnimalType.Weaner) ||
					(section.type === LocationType.Finisher && animalType === AnimalType.Finisher))) ||
				section.animalType === this.state.selectedProductionType.value)
		);
	}

	private getSectionDropdown(animalType: AnimalType) {
		return {
			name: localized('section'),
			component: (
				<SkioldStableSectionPicker
					usedInsideForm={true}
					onStableSectionSelected={this.sectionSelected}
					sectionId={this.state.selectedSectionId}
					AnimalType={animalType}
					isPigProduction={true}
				/>
			),
		};
	}

	private sectionSelected = (selectedSectionId: string) => {
		this.setState({ selectedSectionId });
	};

	private onOptionChanged = (option: Option, itemFromParent) => {
		const state = { ...this.state };
		state[itemFromParent] = option;

		this.setState({ ...state });
	};

	private onAmountInKgChanged = (number: number | undefined) => {
		let numberToUse = number !== undefined ? Number(number.toFixed(1)) : 0;
		this.calculateFeedingUsage(numberToUse, this.props, this.state);
		this.setState({ amount: numberToUse });
	};
	private calculateFeedingUsage = (numberToUse: number | undefined, props: Props, state: State) => {
		if (state.feeding && numberToUse && state.feeding.adjustments) {
			let totalAmountOfKgUsed = 0;
			let gotTheEditedAdjustment = false;
			state.feeding.adjustments!.forEach(a => {
				if (
					!gotTheEditedAdjustment &&
					!a.isDeleted &&
					((a.id !== props.adjustmentToEdit && props.adjustmentToEdit) || !props.adjustmentToEdit) &&
					a.type === FeedingAmountType.FeedingUsage
				) {
					totalAmountOfKgUsed = totalAmountOfKgUsed + a.amount!;
				} else if (a.id === props.adjustmentToEdit && a.type === FeedingAmountType.FeedingUsage) {
					gotTheEditedAdjustment = true;
				}
			});
			let adjustCopy = { ...state.adjustment, amount: numberToUse };

			let data = GetPointOfChangeFeeding(state.feeding);

			let pointOfChangeToUse = data.find(
				a =>
					adjustCopy.periodFrom &&
					a.date &&
					adjustCopy.periodTo &&
					a.date.withoutTime() >= adjustCopy.periodFrom.withoutTime(),
			);

			if (pointOfChangeToUse && pointOfChangeToUse.pricePer100kg) {
				adjustCopy.pricePer100Kg = numberToUse ? (numberToUse / 100) * pointOfChangeToUse.pricePer100kg : 0;
				adjustCopy.energy1PerKg = pointOfChangeToUse.energy1Kg;
				adjustCopy.energy2PerKg = pointOfChangeToUse.energy2Kg;
			}

			this.setState({
				adjustment: adjustCopy as FeedingUsage,
			});
		}
	};
	private onAnimalTypeChanged = (option: Option) => {
		const state = { ...this.state };
		if (
			this.getFilteredFeedingSubjects(option).find(
				a => a.value === this.state.selectedFeedingSubjectOption!.value,
			) === undefined
		) {
			state.selectedFeedingSubjectOption = { label: '', value: '' };
		}

		state.selectedBuilding = { label: '', value: '' };
		state.selectedSectionId = undefined;
		state.selectedAnimalTypeOption = option;
		state.adjustment = { ...state.adjustment, recipeId: undefined } as FeedingUsageTableModel;
		this.setState(state);
	};
	private onFeedingTypeChanged = (option: Option) => {
		if (option.value !== this.state.selectedFeedingSubjectOption!.value) {
			this.setState({ selectedFeedingSubjectOption: { label: '', value: '' } });
		}
		const feeding = this.props.feedings.find(a => a.id === option!.value);
		const stateToSet = { ...this.state, selectedFeedingSubjectOption: option, feeding: feeding };
		this.setState(stateToSet);
		this.calculateFeedingUsage(stateToSet.amount, this.props, stateToSet);
	};

	private getFilteredFeedingSubjects(selectedType: Option) {
		let feedings =
			selectedType.value === AnimalKind.YoungFemale
				? this.props.feedings
				: this.props.feedings.filter(
						a => a.animalTypes && a.animalTypes.includes(selectedType.value as AnimalType),
				  );
		let feedingOptions: Option[] = [];
		feedings.forEach(f => {
			let feedingSubject = GetFeedingSubject(this.props.feedingSubjects, f.feedingSubjectId!);
			if (feedingSubject) {
				let option: Option = { value: f.id!, label: GetFeedingName(feedingSubject, this.props.profile) };
				feedingOptions.push(option);
			}
		});
		return feedingOptions;
	}

	private onPeriodFromChanged = (newDate: Date) => {
		let feedingUsage = { ...this.state.adjustment } as FeedingUsage;
		feedingUsage.date = newDate;
		feedingUsage.periodFrom = newDate;
		this.setState({
			adjustment: feedingUsage,
		});
	};

	private onPeriodToChanged = (newDate: Date) => {
		this.feedingUsagePropertyChanged(newDate, 'periodTo');
	};

	private onRecipeChange = (recipeId: string) => {
		this.setState(prevState => ({
			adjustment: { ...prevState.adjustment, recipeId } as FeedingUsage,
		}));
	};

	private feedingUsagePropertyChanged(newValue: any, propertyName: keyof IFeedingUsage) {
		let feedingUsage = { ...this.state.adjustment } as FeedingUsage;
		// @ts-ignore
		feedingUsage[propertyName] = newValue;
		if (propertyName === 'periodFrom' && feedingUsage['periodTo']! < feedingUsage[propertyName]!) {
			feedingUsage['periodTo'] = newValue;
		}
		this.setState({
			adjustment: feedingUsage,
		});
	}
	private resetPropertiesOnFeeding(feedingStartState: IFeeding | undefined) {
		let feedingUsage = {
			...this.state.adjustment,
			energy1PerKg: undefined,
			energy2PerKg: undefined,
			fosforGPerKg: undefined,
			pricePer100Kg: undefined,
			amount: undefined,
		} as unknown as FeedingUsageTableModel;
		this.setState({ adjustment: feedingUsage, amount: undefined, feeding: feedingStartState });
	}

	private setInitialDataForFeedingUsage(
		feeding: IFeeding,
		feedingSubjectName: string | undefined,
		adjustment: FeedingUsageTableModel,
	) {
		let AdjustmentIndex = feeding.adjustments!.findIndex(a => a.id === this.props.adjustmentToEdit);
		let subject = GetFeedingSubject(this.props.feedingSubjects, feeding.feedingSubjectId!);
		let lowestAdjustmentDate = new Date();

		if (subject) {
			feedingSubjectName = GetFeedingName(subject, this.props.profile);
		}
		if (AdjustmentIndex > 0) {
			const tmpFeedingUsage = feeding.adjustments![AdjustmentIndex] as FeedingUsage;
			let totalUsedBeforeTheEditedAdjustment = 0;
			for (let index = 0; index < AdjustmentIndex; index++) {
				if (feeding.adjustments![index].type === FeedingAmountType.FeedingUsage) {
					totalUsedBeforeTheEditedAdjustment =
						totalUsedBeforeTheEditedAdjustment + feeding.adjustments![index].amount!;
				}
			}
			lowestAdjustmentDate = feeding
				.adjustments!.filter(a => a.type === FeedingAmountType.FeedingAdjustment)
				.reduce((min, p) => (p.date! < min ? p.date! : min), lowestAdjustmentDate);

			let data = GetPointOfChangeFeeding(feeding);

			let pointOfChangeToUse = data.find(
				a =>
					tmpFeedingUsage.periodFrom &&
					a.date &&
					tmpFeedingUsage.periodTo &&
					a.date.withoutTime() >= tmpFeedingUsage.periodFrom.withoutTime(),
			);
			adjustment.id = tmpFeedingUsage.id;
			adjustment.amount = tmpFeedingUsage.amount;
			adjustment.periodFrom = tmpFeedingUsage.periodFrom;
			adjustment.buildingId = tmpFeedingUsage.buildingId;
			adjustment.sectionId = tmpFeedingUsage.sectionId;
			adjustment.periodTo = tmpFeedingUsage.periodTo;
			adjustment.animalType = tmpFeedingUsage.animalType;
			adjustment.productionType = tmpFeedingUsage.productionType;
			adjustment.defaultBuildingName = tmpFeedingUsage.defaultBuildingName;
			if (pointOfChangeToUse && pointOfChangeToUse.pricePer100kg) {
				adjustment.pricePer100Kg = tmpFeedingUsage.amount
					? (tmpFeedingUsage.amount / 100) * pointOfChangeToUse.pricePer100kg
					: 0;
				adjustment.energy1PerKg = pointOfChangeToUse.energy1Kg;
				adjustment.energy2PerKg = pointOfChangeToUse.energy2Kg;
				adjustment.type = tmpFeedingUsage.type;
			}
		}
		return { feedingSubjectName, adjustment, lowestAdjustmentDate };
	}

	private getInitialData() {
		const adjustmentDate = new Date();
		const feeding = this.props.feedings.find(a => a.id === this.props.feedingToEdit!);
		let feedingSubjectName: string | undefined;
		let adjustment = {
			date: adjustmentDate,
			periodFrom: adjustmentDate,
			periodTo: adjustmentDate,
			type: FeedingAmountType.FeedingUsage,
		} as FeedingUsageTableModel;
		return { feeding, feedingSubjectName, adjustment };
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFoodUsage);
