import { Syncable } from "shared/state/models/syncable";
import { ITreatmentDefinition } from "shared/api/api";


export const SAVE_TREATMENT_DEFINITION = 'SAVE_TREATMENT_DEFINITION';
export const SYNC_DATA_TREATMENT_DEFINITION = 'SYNC_DATA_TREATMENT_DEFINITION';
export const GET_ARCHIVED_TREATMENT_DEFINITIONS = 'GET_ARCHIVED_TREATMENT_DEFINITIONS';
export const ARCHIVE_TREATMENT_DEFINITION = 'ARCHIVE_TREATMENT_DEFINITION';
export const DE_ARCHIVE_TREATMENT_DEFINITION = 'DE_ARCHIVE_TREATMENT_DEFINITION';

export interface TreatmentDefinitionState extends Syncable<ITreatmentDefinition> {
    arhivedTreatmentDefinitions: ITreatmentDefinition[];
}

