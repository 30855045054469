import { Sorting } from '@devexpress/dx-react-grid';
import memoize from 'memoize-one';
import React from 'react';
import { Option } from 'react-dropdown';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnimalKind, IDrug } from 'shared/api/api';
import BuyMedicineIcon from 'shared/assets/src-assets/png/indkoeb_medicin_lightgrey.png';
import AdjustStorageIcon from 'shared/assets/src-assets/png/juster_lager_grey2.png';
import MedicineLogIcon from 'shared/assets/src-assets/png/medicin_log.png';
import AddMedicinIcon from 'shared/assets/src-assets/png/opret_medicin.png';
import { getDateString, setCorrectTimeStamp } from 'shared/helpers/date-helpers';
import { getTreatmentAnimalKindsOptions } from 'shared/helpers/general-helpers';
import { ArchiveDrug, GetDrugs, RemoveDrug, Sync } from 'shared/state/ducks/drug/operations';
import { DrugUpdate } from 'shared/state/ducks/drug/types';
import { localized, localizedInterpolation } from 'shared/state/i18n/i18n';
import { WebAppState } from 'web/state/store.web';
import AddDrug from 'web/view/components/drugs/drug-stock/add-drug';
import AddMedicine from 'web/view/components/drugs/drug-stock/add-medicine';
import AddToCurrentDrugStock from 'web/view/components/drugs/drug-stock/add-to-current-drug-stock';
import MedicineLog from 'web/view/components/drugs/medicine-log';
import PageContainer from 'web/view/components/page-container/page-container';
import { SkioldDropdown } from 'web/view/components/skiold-components/skiold-dropdown/skiold-dropdown';
import { SkioldFetch } from 'web/view/components/skiold-components/skiold-fetch/skiold-fetch';
import { SkioldModal } from 'web/view/components/skiold-components/skiold-modal/skiold-modal';
import SkioldTableGrid from 'web/view/components/skiold-components/skiold-table/skiold-table-grid/skiold-table-grid';
import { SkioldTouchableOpacity } from 'web/view/components/skiold-components/skiold-touchable-opacity';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { WhiteText } from 'web/view/components/Text/white-text';
import { Heading } from 'web/view/components/utils/heading';
import { SkioldImage } from 'web/view/components/utils/svg/skiold-image';
import { ViewWeb } from 'web/view/components/utils/web-view';
import 'web/view/styles/stock-styles/stock-styles.scss';
import DeleteIcon from 'shared/assets/src-assets/png/delete_icon.png';
import sendToArchive from 'shared/assets/src-assets/Icon_Arkiv_Medicin.svg';
import { showAlert, ShowConfirmAlert } from 'web/view/components/skiold-alert/skiold-alert';
import { DrugStockArchived } from './drug-stock-archive';
import MedicinArchive from 'shared/assets/src-assets/medicine-archive.svg';
interface PropsFromParent {}

const mapStateToProps = (state: WebAppState) => {
	return {
		siteId: state.profile.active!.siteId,
		profile: state.profile.active!,
		drugTypes: state.drugType.entities,
		drugs: state.drugs.drugs.filter(d => !d.isDeleted),
		showSpinner: state.drugs.isFetching,
		navigation: state.navigation,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getDrugs: (siteId: string, startDate: Date, endDate: Date) => GetDrugs(siteId, startDate, endDate)(dispatch),
	Sync: (drugs: DrugUpdate[]) => Sync(drugs)(dispatch),
	removeDrug: (id: string) => RemoveDrug(id, showAlert)(dispatch),
	archiveDrug: (drug: IDrug) => ArchiveDrug(drug, showAlert)(dispatch),
});

interface State {
	fromDate: Date;
	toDate: Date;
	createModalOpen: boolean;
	editModalOpen: boolean;
	editWasteModalOpen: boolean;
	selectedAnimalKindOption?: Option;
	selectedAnimalKinds: AnimalKind[];
	drugToEdit: string;
	medicineLogOpen: boolean;
	columnExte: any[];
	columns: any[];
	archiveModalOpen: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class DrugStockOverview extends React.PureComponent<Props, State> {
	private generateData = memoize((drugs, drugTypes, selectedAnimalKindOption) => this.getFilteredData());
	constructor(props: Props) {
		super(props);
		let tempFromDate = setCorrectTimeStamp(new Date(), false, false)!;
		tempFromDate.setMonth(new Date().getMonth() - 1);
		let tempToDate = setCorrectTimeStamp(new Date(), false, true)!;
		tempToDate.setMonth(new Date().getMonth());
		const animalType = this.props.navigation.query && (this.props.navigation.query['type'] as AnimalKind);
		this.state = {
			fromDate: tempFromDate,
			toDate: tempToDate,
			createModalOpen: false,
			editModalOpen: false,
			editWasteModalOpen: false,
			medicineLogOpen: false,
			selectedAnimalKindOption:
				animalType === AnimalKind.Sow || !animalType
					? {
							label: localized('SowBoarGlit'),
							value: AnimalKind.Sow,
					  }
					: {
							label: localized(animalType),
							value: animalType,
					  },
			drugToEdit: '',
			selectedAnimalKinds: new Array<AnimalKind>(),
			columns: this.generateColumns(),
			columnExte: this.generateColumnsExtensions(),
			archiveModalOpen: false,
		};

		if (this.props.siteId) {
			this.getDrugs(tempFromDate, tempToDate);
		}
	}
	public componentDidMount() {
		this.selectedAnimalKindChanged(this.state.selectedAnimalKindOption);
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			!isEqual(
				prevProps.navigation.query && prevProps.navigation.query['type'],
				this.props.navigation.query && this.props.navigation.query['type'],
			)
		) {
			const animalType = this.props.navigation.query && (this.props.navigation.query['type'] as AnimalKind);
			const selectedAnimalType =
				animalType === AnimalKind.Sow || !animalType
					? {
							label: localized('SowBoarGlit'),
							value: AnimalKind.Sow,
					  }
					: {
							label: localized(animalType),
							value: animalType,
					  };
			this.selectedAnimalKindChanged(selectedAnimalType);
			this.setState({ selectedAnimalKindOption: selectedAnimalType });
		}
	}

	public generateColumnsExtensions() {
		return [
			{
				columnName: 'archive',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'type',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'drugType',
				width: SowListConstants.treatDefinitionDrugWidth,
			},
			{
				columnName: 'lastRegulation',
				width: SowListConstants.adjustmentType,
			},
			{
				columnName: 'drugWaste',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'drugUsage',
				width: SowListConstants.entranceDateWidth,
			},
			{
				columnName: 'drugStock',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'updateCurrentTotal',
				width: SowListConstants.animalNrWidth,
			},
			{
				columnName: 'deleteDrug',
				width: SowListConstants.animalNrWidth,
			},
		];
	}

	private defaultSorting = [{ columnName: 'type', direction: 'asc' }] as Sorting[];

	public render() {
		return (
			<PageContainer>
				<Heading text={localized('DrugStock')} />
				<ViewWeb className="stock-overview">
					<ViewWeb className="headerViewStyle">
						<ViewWeb className="stock-start stock-sizing verticalCenter">
							<ViewWeb className="header-selector-container">
								<SkioldFetch
									fetchData={this.getDrugs}
									toDateChange={this.setToDate}
									toDate={this.state.toDate}
									fromDate={this.state.fromDate}
									fromDateChange={this.setfromDate}
									isControlled={true}
									showSpinner={this.props.showSpinner}
								/>
							</ViewWeb>
						</ViewWeb>

						<ViewWeb className="alignDropdownCenter">
							<SkioldDropdown
								onValueChanged={this.selectedAnimalKindChanged}
								selectedValue={this.state.selectedAnimalKindOption}
								items={getTreatmentAnimalKindsOptions(this.props.profile)}
								containerClassName="animal-kind-selector"
							/>
						</ViewWeb>

						<ViewWeb className="stock-start stock-sizing verticalCenter buttonStyling">
							{this.renderButtonsForDrugStock()}
						</ViewWeb>
					</ViewWeb>

					<ViewWeb className="outer-div">
						<SkioldTableGrid
							tableKey={'drugStockOverviewListTable'}
							columns={this.state.columns}
							ColumnExtensions={this.state.columnExte}
							data={this.generateData(
								this.props.drugs,
								this.props.drugTypes,
								this.state.selectedAnimalKindOption,
							)}
							ignoreSetCount={true}
							sortHeaderId={this.defaultSorting}
						/>
					</ViewWeb>

					<SkioldModal padding="0" isOpen={this.state.createModalOpen} close={this.closeModal}>
						<AddDrug closeModal={this.closeModal} animalKinds={this.state.selectedAnimalKinds} />
					</SkioldModal>
					<SkioldModal padding="0" isOpen={this.state.medicineLogOpen} close={this.closeModal}>
						<MedicineLog closeModal={this.closeModal} />
					</SkioldModal>
					<SkioldModal
						shouldCloseOnOverlayClick={true}
						padding="0"
						isOpen={this.state.editModalOpen}
						close={this.closeModal}
					>
						{this.state.editWasteModalOpen ? (
							<AddToCurrentDrugStock
								drugToEdit={this.state.drugToEdit}
								isWasteEdit={true}
								closeModal={this.closeModal}
							/>
						) : (
							<AddMedicine
								animalKinds={this.state.selectedAnimalKinds}
								close={this.closeModal}
							></AddMedicine>
						)}
					</SkioldModal>
					<SkioldModal padding="0" close={this.closeArchiveModal} isOpen={this.state.archiveModalOpen}>
						<DrugStockArchived
							selectedAnimalKinds={this.state.selectedAnimalKinds}
							closeModal={this.closeArchiveModal}
						/>
					</SkioldModal>
				</ViewWeb>
			</PageContainer>
		);
	}

	public setToDate = (toDate: Date) => {
		this.setState({ toDate });
	};

	public setfromDate = (fromDate: Date) => {
		this.setState({ fromDate });
	};

	private getDrugs = (fromDate: Date, toDate: Date) => {
		if (this.props.siteId) {
			this.props.getDrugs(this.props.siteId, fromDate, toDate);
		}
	};
	private renderButtonsForDrugStock() {
		return (
			<ViewWeb className="buttons-stock">
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity onPress={this.edit}>
						<SkioldImage width="60" height="60" imageData={BuyMedicineIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('AddToDrugStock')}</WhiteText>
				</ViewWeb>

				<ViewWeb className="dotted-header" />
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={() => this.openMedicineLog()}>
						<SkioldImage width="60" height="60" imageData={MedicineLogIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('medicineLog')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="dotted-header" />
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={() => this.openModal()}>
						<SkioldImage width="60" height="60" imageData={AddMedicinIcon} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('createMedication')}</WhiteText>
				</ViewWeb>
				<ViewWeb className="dotted-header" />
				<ViewWeb className="verticalCenter">
					<SkioldTouchableOpacity className={'positionRelative'} onPress={() => this.openArchiveModal()}>
						<SkioldImage width="60" height="60" imageData={MedicinArchive} />
					</SkioldTouchableOpacity>
					<WhiteText>{localized('Archive')}</WhiteText>
				</ViewWeb>
			</ViewWeb>
		);
	}

	private getDrugTypesForAnimalType() {
		let drugs = this.getFilteredData();
		let drugTypes = this.props.drugTypes;
		drugs.forEach(drug => (drugTypes = drugTypes.filter(drugType => drugType.id !== drug.drugTypeId)));
		return drugTypes;
	}

	private getFilteredData() {
		return this.props.drugs.filter(
			a => !a.isArchived && a.animalKinds!.some(r => this.state.selectedAnimalKinds.indexOf(r) >= 0),
		);
	}

	private selectedAnimalKindChanged = (option?: Option) => {
		let kind: AnimalKind[];

		if (
			option!.value === AnimalKind.Boar ||
			option!.value === AnimalKind.Sow ||
			option!.value === AnimalKind.Gilt
		) {
			kind = [AnimalKind.Sow, AnimalKind.Boar, AnimalKind.Gilt];
		} else {
			kind = [AnimalKind[option!.value as keyof typeof AnimalKind]]; //Cast string to enum https://stackoverflow.com/a/42623905/2677975
		}

		this.setState({
			selectedAnimalKinds: kind,
			selectedAnimalKindOption: option,
		});
	};
	private getAnimalKinds(): Option[] {
		return [
			{ label: localized('SowBoarGlit'), value: AnimalKind.Sow.toString() },
			{ label: localized(AnimalKind.YoungFemale), value: AnimalKind.YoungFemale.toString() },
		];
	}

	private generateColumns(): any {
		return [
			{
				name: 'archive',
				title: localized('DoArchive'),
				filterable: false,
				width: SowListConstants.removeIconWidth,
				getCellValue: this.getArchiveIcon,
			},
			{
				name: 'type',
				title: localized('Type'),
				getCellValue: (drug: IDrug) => this.findDrugTypeType(drug),
			},
			{
				name: 'drugType',
				title: localized('DrugTypes'),
				getCellValue: (drug: IDrug) => this.findDrugType(drug),
			},
			{
				name: 'lastRegulation',
				title: localized('lastRegulation'),
				getCellValue: (drug: IDrug) => getDateString(drug.lastAdjusted),
			},
			{
				name: 'drugWaste',
				title: localized('Waste'),
				getCellValue: (drug: IDrug) => (drug.waste ? -drug.waste : 0),
			},
			{
				name: 'drugUsage',
				title: localized('Usage'),
				getCellValue: (drug: IDrug) => (drug.usage ? -drug.usage : 0),
			},
			{
				name: 'drugStock',
				title: localized('MedicineStockStorage'),
				getCellValue: (drug: IDrug) => this.getCurrentStock(drug),
			},
			{
				name: 'updateCurrentTotal',
				title: localized('UpdateMedicineStock'),
				filterable: false,
				width: SowListConstants.drugWidth,
				getCellValue: this.getUpdateCurrentTotalCell,
			},
			{
				name: 'deleteDrug',
				title: ' ',
				filterable: false,
				width: SowListConstants.removeIconWidth,
				getCellValue: this.getRemoveIcon,
			},
		];
	}

	private getUpdateCurrentTotalCell = (drug: IDrug) => {
		const registerWaste = () => this.registerWaste(drug.id!);
		return (
			<ViewWeb className="verticalCenter">
				<SkioldTouchableOpacity onPress={registerWaste}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={AdjustStorageIcon}
					/>
				</SkioldTouchableOpacity>
			</ViewWeb>
		);
	};

	private getRemoveIcon = (event: IDrug) => {
		const removeDrug = () => this.props.removeDrug(event.id!);
		return (
			<>
				<SkioldTouchableOpacity onPress={removeDrug} itemFromParent={event}>
					<SkioldImage
						width={SowListConstants.iconSVGWidth}
						height={SowListConstants.iconSVGWidth}
						imageData={DeleteIcon}
					/>
				</SkioldTouchableOpacity>
			</>
		);
	};

	private getArchiveIcon = (event: IDrug) => {
		const archiveDrug = async () => {
			if (await ShowConfirmAlert(localized('ConfirmDrugArchivation'))) {
				this.props.archiveDrug(event);
			}
		};
		return (
			<>
				<SkioldTouchableOpacity className="align-items-center" onPress={archiveDrug} itemFromParent={event}>
					<SkioldImage
						className="align-items-center"
						width={35}
						height={35}
						imageData={sendToArchive}
					/>
				</SkioldTouchableOpacity>
			</>
		);
	};

	private closeArchiveModal = () => {
		this.setState({ archiveModalOpen: false });
	};

	private openArchiveModal = () => {
		this.setState({ archiveModalOpen: true });
	};

	private edit = () => {
		this.setState({ editModalOpen: true });
	};

	private openMedicineLog() {
		this.setState({ medicineLogOpen: true });
	}

	private registerWaste(drugId: string) {
		this.setState({ editModalOpen: true, editWasteModalOpen: true, drugToEdit: drugId });
	}
	private findDrugType(drug: IDrug) {
		let drugType = this.props.drugTypes.find(a => a.id === drug.drugTypeId);
		if (drugType !== undefined) {
			return drugType.name;
		} else {
			return '';
		}
	}
	private findDrugTypeType(drug: IDrug) {
		let drugType = this.props.drugTypes.find(a => a.id === drug.drugTypeId);
		if (drugType !== undefined) {
			return localized(drugType.type!);
		} else {
			return '';
		}
	}
	private getCurrentStock(drug: IDrug) {
		return drug.total;
	}

	private openModal() {
		this.setState({ createModalOpen: true });
	}

	private closeModal = () => {
		this.setState({
			createModalOpen: false,
			medicineLogOpen: false,
			editWasteModalOpen: false,
			editModalOpen: false,
		});
		if (this.props.siteId) {
			this.props.getDrugs(this.props.siteId, this.state.fromDate, this.state.toDate);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugStockOverview);
