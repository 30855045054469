import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncOperationHandler } from '../../../helpers/redux-helpers';
import { IRecipeDto, RecipeDto } from 'shared/api/api';
import ObjectID from 'bson-objectid';

export const getRecipes = createAsyncThunk('recipe/getRecipes', (siteId: string) => {
	return AsyncOperationHandler(client => client.recipe_GetRecipes(siteId));
});

export const upsertRecipe = createAsyncThunk('recipe/upsertRecipe', (recipe: IRecipeDto) => {
	if (!recipe.id) {
		recipe.id = new ObjectID().toHexString();
	}
	return AsyncOperationHandler(client => client.recipe_UpsertSkioldDigitalRecipes(RecipeDto.fromJS(recipe)));
});


export const deleteRecipe = createAsyncThunk('recipe/deleteRecipe', (recipe: IRecipeDto) => {
	return AsyncOperationHandler(client => client.recipe_DeleteSkioldDigitalRecipes(RecipeDto.fromJS(recipe)));
});

