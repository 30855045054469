import { IDrugWithAdjustment, IDrug, IAdjustment, DrugWithAdjustment } from 'shared/api/api';

export const SAVE_DRUG = 'SAVE_DRUG';
export const DELETE_DRUG = 'DELETE_DRUG';
export const GET_DRUGS = 'GET_DRUGS';
export const REMOVE_DRUG = 'REMOVE_DRUG';
export const GET_DRUGS_FOR_MEDICINE_LOG = 'GET_DRUGS_FOR_MEDICINE_LOG';

export const ARCHIVE_DRUGS = 'ARCHIVE_DRUGS';
export const DE_ARCHIVE_DRUGS = 'DE_ARCHIVE_DRUGS';

export interface DrugState {
	updates: Array<DrugWithAdjustment | DrugUpdate>;
	drugs: IDrug[];
	drugsWithAdjustments: IDrugWithAdjustment[];
    isFetching: boolean;
}

export class DrugUpdate {
	public drug?: IDrug;
	public adjustment?: IAdjustment;
	constructor(data?: IDrug) {
		if (data) {
			for (let property in data) {
				if (data.hasOwnProperty(property)) {
					(this)[property] = (data)[property];
				}
			}
		}
	}

	public init(data?: any) {
		if (data) {
			this.drug = data['drug'];
			this.adjustment = data['adjustment'];
		}
	}
}
