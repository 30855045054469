import React, { FC, useEffect } from 'react';
import { localized } from 'shared/state/i18n/i18n';
import PageContainer from '../../page-container/page-container';
import { Heading } from '../../utils/heading';
import { CreateRecipe } from './create-recipe';
import { RecipeTable } from './recipe-table';
import './recipe-overview.scss';
import { ViewWeb } from '../../utils/web-view';
import { SkioldButton } from '../../skiold-components/skiold-button/skiold-button';
import { SkioldModal } from '../../skiold-components/skiold-modal/skiold-modal';
import FeedingLogIcon from 'shared/assets/src-assets/png/FoderLog_Grey.png';
import { SkioldIconButton } from '../../skiold-components/skiold-icon-button/skiold-icon-button';
import { View } from 'react-native-reanimated/lib/typescript/Animated';
import { useDispatch, useSelector } from 'react-redux';
import { selectSiteId } from 'shared/state/ducks/site/selectors';
import { getRecipes } from 'shared/state/ducks/recipe/operation';
interface PropsFromParent {
	closeModal: () => void;
}

export const RecipeOverview: FC<PropsFromParent> = React.memo(({ closeModal }) => {
	const [createRecipeModalOpen, setCreateRecipeModalOpen] = React.useState<boolean>(false);
	const siteId = useSelector(selectSiteId);
	const dispatch = useDispatch();
	useEffect(() => {
		if (siteId) {
			dispatch(getRecipes(siteId));
		}
	}, [siteId]);
	const closeCreateRecipeModal = () => {
		setCreateRecipeModalOpen(false);
	};

	const openModal = () => {
		setCreateRecipeModalOpen(true);
	};

	return (
		<PageContainer className="recipe-overview page-container">
			<Heading allLettersCapitalized={false} text={localized('Recipes')} />
			<ViewWeb className="recipe-overview-buttons-container">
				<SkioldIconButton icon={FeedingLogIcon} title={localized('CreateRecipe')} onClick={openModal} />
				<SkioldButton theme="grey" title={localized('Close')} onPress={closeModal} />
			</ViewWeb>
			<RecipeTable />
			<SkioldModal overflowNone={true} padding="0" isOpen={createRecipeModalOpen} close={closeCreateRecipeModal}>
				<CreateRecipe closeModal={closeCreateRecipeModal} />
			</SkioldModal>
		</PageContainer>
	);
});
